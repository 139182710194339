import React from 'react'
import { ROLE } from '../../../utils/Constant'
import HomeImage from '../../../images/generate-lead (1).png'
import SecondLogo from '../../../images/Group (5).png'
import ThirdLogo from '../../../images/Vector (25).png'
import FourthLogo from '../../../images/marketing.png'
import FifthLogo from '../../../images/Layer 4.png'
import SixthLogo from '../../../images/access-control.png'
import SeventhLogo from '../../../images/dashboard (1).png'
import EighthLogo from '../../../images/Digital Key.png'
import NinthLogo from '../../../images/Group (6).png'

const SettingsNavOptions = ({
    navItemClick,
    pageViewHandler,
    admin,
    menuOpenState,
    generateReportState,
    subHandleTab,
    losSubHandle, pageViewState, lmsSubTab
}) => (
    <div className='phocket-crm-menu new-menu animate-crm-menu  ' id='CRMMenu'>
        <ul className='mail-list-new'>
         
            <div className='mail-list'>
                <li
                    className={menuOpenState === 'collection' ? 'mail-list-item-active' : 'mail-list-item'}
                    onClick={e => navItemClick('collection')}
                >
                    <img src={FifthLogo} width='20px' style={{ marginTop: '-4px' }} /><span style={{ marginLeft: '10px' }}> Collection </span>{menuOpenState === 'collection' ? <i class="fa fa-caret-up" aria-hidden="true" style={{ float: 'right', color: '#fff' }}></i> : <i class="fa fa-caret-down" aria-hidden="true" style={{ float: 'right', color: '#fff' }}></i>}
                </li>
            </div>
            {menuOpenState == 'collection' ?
                <>
                    <li
                        className={pageViewState === 'recovery' ? 'sub-list-item-active' : 'sub-list-item'}
                        onClick={e => pageViewHandler('recovery')}
                    >
                        Recovery List
                    </li>
                </>
                : ""}
            {admin.rolelist != undefined ? (admin.rolelist.indexOf(ROLE.ROLE_ASSIGN) >= 0 || admin.rolelist.indexOf(ROLE.LM_Assignment_Rule) >= 0 || admin.rolelist.indexOf(ROLE.Manual_Lead_Assignment) >= 0) ?
                <>
                    <div className='mail-list'>
                        <li
                            className={menuOpenState === 'roleMangement' ? 'mail-list-item-active' : 'mail-list-item'}
                            onClick={e => navItemClick('roleMangement')}
                        >
                            <img src={SixthLogo} width='20px' style={{ marginTop: '-4px' }} /><span style={{ marginLeft: '10px' }}> Access Management </span>{menuOpenState === 'roleMangement' ? <i class="fa fa-caret-up" aria-hidden="true" style={{ float: 'right', color: '#fff' }}></i> : <i class="fa fa-caret-down" aria-hidden="true" style={{ float: 'right', color: '#fff' }}></i>}
                        </li>
                    </div>
                    {menuOpenState == 'roleMangement' ?
                        <>
                            {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.ROLE_ASSIGN) >= 0 ?
                                <li
                                    className={pageViewState === 'Roleassign' ? 'sub-list-item-active' : 'sub-list-item'}
                                    onClick={e => pageViewHandler('Roleassign')}
                                >
                                    Admin Roles
                                </li>
                                : "" : ""}
                            {admin.rolelist != undefined ?
                                admin.rolelist.indexOf(ROLE.LM_Assignment_Rule) >= 0 || admin.rolelist.indexOf(ROLE.Manual_Lead_Assignment) >= 0 ?
                                    <li
                                        className={pageViewState === 'Bulkassign' ? 'sub-list-item-active' : 'sub-list-item'}
                                        onClick={e => pageViewHandler('Bulkassign')}
                                    >
                                        Application Assignment
                                    </li>
                                    : "" : ""}
                        </>
                        : ""}
                </>
                : "" : ""}
            {admin.rolelist != undefined ? (
                admin.rolelist.indexOf(ROLE.Generate_Buddy_Loan_Report) >= 0 ||
                admin.rolelist.indexOf(ROLE.Generate_Report_Logs) >= 0 ||
                admin.rolelist.indexOf(ROLE.Dashboard) >= 0 ||
                admin.rolelist.indexOf(ROLE.Check_FlipKart_Balance) >= 0 ||
                admin.rolelist.indexOf(ROLE.Incoming_Payment_Report) >= 0 ||
                admin.rolelist.indexOf(ROLE.DASHBOARD_REPORT_NEW) >= 0
            ) ?
                <>
                    <div className='mail-list'>
                        <li
                            className={menuOpenState === 'dashboardAndReport' ? 'mail-list-item-active' : 'mail-list-item'}
                            onClick={e => navItemClick('dashboardAndReport')}
                        >
                            <img src={SeventhLogo} width='20px' style={{ marginTop: '-4px' }} /><span style={{ marginLeft: '10px' }}> Dashboard & Reports </span>{menuOpenState === 'dashboardAndReport' ? <i class="fa fa-caret-up" aria-hidden="true" style={{ float: 'right', color: '#fff' }}></i> : <i class="fa fa-caret-down" aria-hidden="true" style={{ float: 'right', color: '#fff' }}></i>}
                        </li>
                    </div>
                    {menuOpenState == 'dashboardAndReport' ?
                        <>
                            {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.Dashboard) >= 0 ?
                                <li
                                    className={pageViewState === 'dashboard' ? 'sub-list-item-active' : 'sub-list-item'}
                                    onClick={e => pageViewHandler('dashboard')}
                                >
                                    Dashboard
                                </li>
                                : "" : ""}
                            {admin.rolelist != undefined ?
                                admin.rolelist.indexOf(ROLE.Incoming_Payment_Report) >= 0 ?
                                    <li
                                        className={pageViewState === 'IncomingPaymentReport' ? 'sub-list-item-active' : 'sub-list-item'}
                                        onClick={e => pageViewHandler('IncomingPaymentReport')}
                                    >
                                        Incoming Payment Report
                                    </li>
                                    : "" : ""}
                            {admin.rolelist != undefined ?
                                admin.rolelist.indexOf(ROLE.DASHBOARD_REPORT_NEW) >= 0 ?
                                    generateReportState == true ?
                                        <li
                                            className={pageViewState === 'reportGenerateNew' ? 'sub-list-item-active' : 'sub-list-item'}
                                            onClick={e => pageViewHandler('reportGenerateNew')}
                                        >
                                            Generate Report
                                        </li>
                                        : ""
                                    : "" : ""}
                            {admin.rolelist != undefined ?
                                admin.rolelist.indexOf(ROLE.Generate_Report_Logs) >= 0 ?
                                    <li
                                        className={pageViewState === 'GenReportLogs' ? 'sub-list-item-active' : 'sub-list-item'}
                                        onClick={e => pageViewHandler('GenReportLogs')}
                                    >
                                        Generate Report Logs
                                    </li>
                                    : "" : ""}
                            {admin.rolelist != undefined ?
                                admin.rolelist.indexOf(ROLE.INCOME_COLLECTED) >= 0 ?
                                    <li
                                        className={pageViewState === 'incomeCollected' ? 'sub-list-item-active' : 'sub-list-item'}
                                        onClick={e => pageViewHandler('incomeCollected')}
                                    >
                                        Income Breakup
                                    </li>
                                    : "" : ""}
                            {admin.rolelist != undefined ?
                                admin.rolelist.indexOf(ROLE.Check_FlipKart_Balance) >= 0 ?
                                    <li
                                        className={pageViewState === 'flipkartBalance' ? 'sub-list-item-active' : 'sub-list-item'}
                                        onClick={e => pageViewHandler('flipkartBalance')}
                                    >
                                        Check Flipkart Balance
                                    </li>
                                    : "" : ""}

                        </>
                        : ""}
                </>
                : "" : ""}
            {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.UTILITY) >= 0 && (admin.rolelist.indexOf(ROLE.Loan_Emi_Calculator) >= 0 || admin.rolelist.indexOf(ROLE.Update_Web_Content) >= 0 || admin.rolelist.indexOf(ROLE.Add_Priority_Company) >= 0 || admin.rolelist.indexOf(ROLE.Add_Nbfc) >= 0 || admin.rolelist.indexOf(ROLE.Add_Pincode) >= 0 || admin.rolelist.indexOf(ROLE.Update_User_Bank_Details) >= 0 || admin.rolelist.indexOf(ROLE.Update_Cibil_Info) >= 0 || admin.rolelist.indexOf(ROLE.UPLOAD_STAMP_DUTY) >= 0) ?
                <>
                    <div className='mail-list'>
                        <li
                            className={menuOpenState === 'tools' ? 'mail-list-item-active' : 'mail-list-item'}
                            onClick={e => navItemClick('tools')}
                        >
                            <img src={EighthLogo} width='20px' style={{ marginTop: '-4px' }} /><span style={{ marginLeft: '10px' }}> Tools & Utilities </span>{menuOpenState === 'tools' ? <i class="fa fa-caret-up" aria-hidden="true" style={{ float: 'right', color: '#fff' }}></i> : <i class="fa fa-caret-down" aria-hidden="true" style={{ float: 'right', color: '#fff' }}></i>}
                        </li>
                    </div>
                    {menuOpenState == 'tools' ?
                        <>
                            {admin.rolelist != undefined ?
                                admin.rolelist.indexOf(ROLE.Loan_Emi_Calculator) >= 0 ?
                                    <li
                                        className={pageViewState === 'calcUtility' ? 'sub-list-item-active' : 'sub-list-item'}
                                        onClick={e => pageViewHandler('calcUtility')}
                                    >
                                        Calculator
                                    </li>
                                    : "" : ""}
                            {admin.rolelist != undefined ?
                                admin.rolelist.indexOf(ROLE.Update_Web_Content) >= 0 ?
                                    <li
                                        className={pageViewState === 'webContentUtility' ? 'sub-list-item-active' : 'sub-list-item'}
                                        onClick={e => pageViewHandler('webContentUtility')}
                                    >
                                        Web Content
                                    </li>
                                    : "" : ""}
                            {admin.rolelist != undefined ?
                                admin.rolelist.indexOf(ROLE.Loan_Emi_Calculator) >= 0 ?
                                    <li
                                        className={pageViewState === 'emiCalculator' ? 'sub-list-item-active' : 'sub-list-item'}
                                        onClick={e => pageViewHandler('emiCalculator')}
                                    >
                                        EMI Calculator
                                    </li>
                                    : "" : ""}
                            {admin.rolelist != undefined ?
                                admin.rolelist.indexOf(ROLE.Add_Priority_Company) >= 0 ?
                                    <li
                                        className={pageViewState === 'priorityCompany' ? 'sub-list-item-active' : 'sub-list-item'}
                                        onClick={e => pageViewHandler('priorityCompany')}
                                    >
                                        Add Priority Company
                                    </li>
                                    : "" : ""}
                            {admin.rolelist != undefined ?
                                admin.rolelist.indexOf(ROLE.Add_Pincode) >= 0 ?
                                    <li
                                        className={pageViewState === 'addPincode' ? 'sub-list-item-active' : 'sub-list-item'}
                                        onClick={e => pageViewHandler('addPincode')}
                                    >
                                        Add Pincode
                                    </li>
                                    : "" : ""}
                            {admin.rolelist != undefined ?
                                admin.rolelist.indexOf(ROLE.Update_User_Bank_Details) >= 0 ?
                                    <li
                                        className={pageViewState === 'updateAccInfo' ? 'sub-list-item-active' : 'sub-list-item'}
                                        onClick={e => pageViewHandler('updateAccInfo')}
                                    >
                                        Update Account Info
                                    </li>
                                    : "" : ""}
                            {admin.rolelist != undefined ?
                                admin.rolelist.indexOf(ROLE.Update_Cibil_Info) >= 0 ?
                                    <li
                                        className={pageViewState === 'updateCibilInfo' ? 'sub-list-item-active' : 'sub-list-item'}
                                        onClick={e => pageViewHandler('updateCibilInfo')}
                                    >
                                        Update Cibil Info
                                    </li>
                                    : "" : ""}
                            {admin.rolelist != undefined ?
                                admin.rolelist.indexOf(ROLE.UPLOAD_STAMP_DUTY) >= 0 ?
                                    <li
                                        className={pageViewState === 'uploadStamDuty' ? 'sub-list-item-active' : 'sub-list-item'}
                                        onClick={e => pageViewHandler('uploadStamDuty')}
                                    >
                                        Upload Stamp Duty
                                    </li>
                                    : "" : ""}
                            {admin.rolelist != undefined ?
                                admin.rolelist.indexOf(ROLE.BULK_STATUS_CHANGE) >= 0 ?
                                    <li
                                        className={pageViewState === 'bulkStatusChange' ? 'sub-list-item-active' : 'sub-list-item'}
                                        onClick={e => pageViewHandler('bulkStatusChange')}
                                    >
                                        Bulk Status Update
                                    </li>
                                    : "" : ""}
                        </>
                        : ""}
                </>
                : "" : ""}
            {admin.rolelist.indexOf(ROLE.NBFC_CONFIGURATION) >= 0 ?
                <>
                    <div className='mail-list'>
                        <li
                            className={menuOpenState === 'nbfc' ? 'mail-list-item-active' : 'mail-list-item'}
                            onClick={e => navItemClick('nbfc')}
                        >
                            <img src={NinthLogo} width='20px' style={{ marginTop: '-4px' }} /><span style={{ marginLeft: '10px' }}> NBFC Management </span>{menuOpenState === 'nbfc' ? <i class="fa fa-caret-up" aria-hidden="true" style={{ float: 'right', color: '#fff' }}></i> : <i class="fa fa-caret-down" aria-hidden="true" style={{ float: 'right', color: '#fff' }}></i>}
                        </li>
                    </div>
                    {menuOpenState == 'nbfc' ?
                        <>
                            <li
                                className={pageViewState === 'addNbfcNew' ? 'sub-list-item-active' : 'sub-list-item'}
                                onClick={e => pageViewHandler('addNbfcNew')}
                            >
                                Onboard NBFC
                            </li>
                            <li
                                className={pageViewState === 'allnbfclead' ? 'sub-list-item-active' : 'sub-list-item'}
                                onClick={e => pageViewHandler('allnbfclead')}
                            >
                                Onboarded NBFCs
                            </li>
                            <li
                                className={pageViewState === 'nbfcConfig' ? 'sub-list-item-active' : 'sub-list-item'}
                                onClick={e => pageViewHandler('nbfcConfig')}
                            >
                                NBFC Configuration
                            </li>
                        </>
                        : ""}
                </>
                : ""}
        </ul>
    </div>
)

export default SettingsNavOptions