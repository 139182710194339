import React from 'react'
import { PLACEHOLDER, STRING } from '../../../../utils/Constant'
import DatePicker from 'react-datepicker';
import TransactionPopup from '../../Popup/transactionpopup';

const TransactionDetails = ({ addPoup, viewPage, pageHandler, billDeskData, editTransHandler, saveTransHandler, trnsDateState, details,
    openPopup, DeleteTransaction, transDetail,openAddPopup,close }) => (
    <div className="container-fluid details-new">

        {/* <div className='row '>
           <button  onClick={()=>pageHandler('txn')} className={viewPage==='txn'?'btn btn-primary':'btn btn-default'}>Transaction</button>&nbsp;&nbsp;
           <button onClick={()=>pageHandler('billdesk')} className={viewPage==='billdesk'?'btn btn-primary':'btn btn-default'}>Billdesk Transaction</button>

        </div> */}
        {addPoup ?
            <TransactionPopup close={close} editTransHandler={editTransHandler} saveTransHandler={saveTransHandler} trnsDateState={trnsDateState} transDetail={transDetail}  />

            : ""}
        <div className='row'>
            <div className='col-sm-6 col-xs-12'>

                <div className="btn-group btn-grp-own" role="group" aria-label="onboard-btn" style={{ marginTop: '10px', padding: '10px' }}>
                    <button className="btn btn-outline-primary" style={{ background: viewPage === 'txn' ? '#1D3557' : '#F1F7FF', color: viewPage === 'txn' ? '#fff' : '#000' }} onClick={() => pageHandler('txn')}> Razorpay & Others </button>
                    <button className="btn btn-outline-primary" style={{ background: viewPage === 'billdesk' ? '#1D3557' : '#F1F7FF', color: viewPage === 'billdesk' ? '#fff' : '#000' }} onClick={() => pageHandler('billdesk')} > Billdesk </button>

                </div>
            </div>
            <div className='col-sm-6 col-xs-12 text-right'>
                <button className='btn btn-primary' style={{marginTop:'20px'}} onClick={()=>openAddPopup()}><i className='fa fa-plus-circle'></i>&nbsp;&nbsp;Add Transaction</button>
            </div>
        </div>
        {viewPage === 'txn' ?
            <div className="row form-group overflow-auto phocket-table-new " style={{ marginBottom: '20px' }}>
                {/* <h3 className="text-center">Transaction History</h3> */}
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>DATE</th>
                            <th>MODE</th>
                            <th>AMOUNT</th>
                            <th>UPDATE</th>
                            <th>DELETE</th>
                        </tr>
                    </thead>

                    <tbody>
                        {details != null && details != '' && details.length > 0 ? details.map((detail, i) => {
                            return (
                                <tr key={i}>
                                    <td>{detail.transactionId}</td>
                                    <td>{detail.prepayDate}</td>
                                    <td>{detail.paymentMode}</td>
                                    <td>{detail.prepayAmount}</td>
                                    <td><span className="fa fa-edit" style={{cursor:'pointer'}} onClick={(e) => openPopup(e, detail.transactionId)} /></td>
                                    <td><span className="fa fa-trash" style={{cursor:'pointer'}} onClick={(e) => DeleteTransaction(detail)} /></td>
                                </tr>
                            )
                        })
                            : null}
                    </tbody>
                </table>
            </div>
            : ""}
        {viewPage === 'billdesk' ?
            <div className="row form-group overflow-auto phocket-table-new" style={{ marginBottom: '20px' }}>
                {/* <h3 className="text-center">Billdesk Transaction History</h3> */}
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>DATE</th>
                            <th>AMOUNT</th>
                            <th>Transaction Error Description</th>
                        </tr>
                    </thead>

                    <tbody>
                        {billDeskData != null && billDeskData != '' && billDeskData.length > 0 ? billDeskData.map((detail, i) => {
                            return (
                                <tr key={i}>
                                    <td>{detail.bdOrderId}</td>
                                    <td>{detail.addedOn}</td>
                                    <td>{detail.amount}</td>
                                    <td>{detail.transactionErrDesc}</td>

                                </tr>
                            )
                        })
                            : null}
                    </tbody>
                </table>
            </div>
            : ""}
    </div>
)

export default TransactionDetails;