import React from "react";
import ApproveData from "../Approve.popup";
import TentativeApproval from "../tentativeApproval";

class ApprovalTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabType: ''
        }
    }
    tabHandler(e, type) {
        this.setState({ tabType: type })

    }
    closePopup() {

    }
    render() {
        const { userDetails, admin, allDetail } = this.props
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-6 col-xs-6 text-right">
                        <input type="radio" checked={this.state.tabType==='approve'}  onChange={(e) => this.tabHandler(e, 'approve')} />&nbsp;&nbsp;Approval
                    </div>
                    <div className="col-sm-6 col-xs-6 text-left">
                        <input type="radio" checked={this.state.tabType==='tentatve'}  onChange={(e) => this.tabHandler(e, 'tentatve')} />&nbsp;&nbsp;Tentative Approval
                    </div>

                </div>
                {this.state.tabType === 'approve' ?
                    <ApproveData  userDetails={userDetails} admin={admin} allDetail={allDetail} closePopup={this.closePopup.bind(this)} />
                    : ""}
                {this.state.tabType === 'tentatve' ?
                    <TentativeApproval userDetails={userDetails} admin={admin} allDetail={allDetail} closePopup={this.closePopup.bind(this)} />
                    : ""}


            </div>
        )
    }

}
export default ApprovalTab