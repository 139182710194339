import React from 'react'

const linkPopu = ({ handleLink, confirmationText }) => (
    <div className="background-blur">
        <div className="popup-main small-popup container-fluid  dbl-border" style={{top:'22%'}}>
            <div className="row from-group" style={{ marginTop: '20px' }}>
                <div className="col-sm-3 col-xs-12">
                </div>
                <div className="col-sm-6 col-xs-12">
                    <input className="form-control" value={confirmationText} readOnly id='linkt' />
                </div>
                <div className="col-sm-3 col-xs-12">
                </div>
            </div>
            <div className="row from-group text-center">
                <div className="col-xs-3 col-sm-3">
                </div>
                <div className="col-xs-3 col-sm-3">
                    <button className="profile-save-btn display-inline" onClick={(e) => handleLink('copy')}>Copy Link &nbsp;<i className='fa fa-clipboard'></i></button>
                </div>
                <div className="col-xs-3 col-sm-3">
                    <button className="profile-save-btn display-inline" onClick={(e) => handleLink('close')}>Close</button>
                </div>
                <div className="col-xs-3 col-sm-3">
                </div>
            </div>
        </div>
    </div>
)

export default linkPopu;