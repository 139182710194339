import React from 'react'
import { getRepeatAutomationByUserId } from '../../AdminActionCreator.component'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
class Repeat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            automationData: "",
            loaderState: false
        }
    }
    componentDidMount() {
        this.getDetails()
    }
    getDetails() {
        const { allDetail } = this.props
        this.setState({ loaderState: true })
        getRepeatAutomationByUserId(allDetail.userId, allDetail.loanId, callback => {
            this.setState({ automationData: callback, loaderState: false })
        })
    }
    render() {
        return (
            <div className='container-fluid  ' style={{ marginBottom: '40px', background: '#fff', padding: '20px', borderRadius: "10px",minHeight:'490px' }}>

                {/* <Loader />
                    : ""} */}
                <h4 className='heading-analysis'><b>Repeat Automation Decision</b> </h4>
                <hr />
                <div className='row'>
                    <div className='phocket-table-new' style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'center' }}>Decision</th>
                                    <th style={{ textAlign: 'center' }}>Logic</th>
                                    <th style={{ textAlign: 'center' }}>Method</th>
                                    <th style={{ textAlign: 'center' }}>Approved Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ textAlign: 'center' }}>{this.state.automationData ? this.state.automationData.decision : this.state.loaderState ? <SkeletonTheme baseColor="transparent" highlightColor="#85a6e1">
                                        <Skeleton height={40} /></SkeletonTheme> : ""}</td>
                                    <td style={{ textAlign: 'center' }}>{this.state.automationData ? this.state.automationData.logic : this.state.loaderState ? <SkeletonTheme baseColor="transparent" highlightColor="#85a6e1">
                                        <Skeleton height={40} /></SkeletonTheme> : ""}</td>
                                    <td style={{ textAlign: 'center' }}>{this.state.automationData ? this.state.automationData.method : this.state.loaderState ? <SkeletonTheme baseColor="transparent" highlightColor="#85a6e1">
                                        <Skeleton height={40} /></SkeletonTheme> : ""}</td>
                                    <td style={{ textAlign: 'center' }}>{this.state.automationData ? this.state.automationData.amountApproved : this.state.loaderState ? <SkeletonTheme baseColor="transparent" highlightColor="#85a6e1">
                                        <Skeleton height={40} /></SkeletonTheme> : ""}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}
export default Repeat