import React from 'react'
import Nodata from '../../../../images/nodata.png'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const Table = ({ similarProfileLoader, paginationHandler, fraudData, allUserDetail, phocketTablePagination, tableMinPagination, tableMaxPagination }) => (
    <>
        {similarProfileLoader === false ?
            <div className=''>
                {fraudData && fraudData.length > 0 ?
                    <div className='credit-table'>
                        <table className="text-center">
                            <thead>
                                <tr>
                                    <th>Application Id</th>
                                    <th>Name</th>
                                    <th>Email ID</th>
                                    <th>Loan Status</th>
                                    <th>Reject Reason</th>
                                    <th>Match By</th>
                                    <th>Priority</th>
                                    <th>Address</th>
                                    {/* <th>Info</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {fraudData ? fraudData.slice(tableMinPagination, tableMaxPagination).map((data, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{data.applicationId}</td>
                                            <td style={{ whiteSpace: 'break-spaces' }}>{data.userName}</td>
                                            <td>{data.emailId}</td>
                                            <td>{data.loanStatus}</td>
                                            <td>{data.rejectReason}</td>
                                            <td>{data.matchBy.toString()}</td>
                                            <td>{data.pri}</td>
                                            <td>{data.address}</td>
                                        </tr>
                                    )
                                }) : ""}
                            </tbody>
                        </table>
                    </div>
                    : ""}
                {fraudData ?
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-5 col-xs-12 no-padding"><button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block   " style={{ background: '#BADBF9', borderRadius: '0' }} onClick={e => phocketTablePagination(fraudData, 'back')}></button></div>
                            <div className="col-sm-2 col-xs-12  phoc text-center" >
                                {(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of ' + fraudData.length}
                            </div>
                            <div className="col-sm-5 col-xs-12 no-padding" ><button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block " o style={{ background: '#BADBF9', borderRadius: '0' }} onClick={e => phocketTablePagination(fraudData, 'forward')}></button></div>
                        </div>

                    </div>
                    : <div className='col-sm-12 col-xs-12 text-center'><img src={Nodata} style={{ width: '30%', marginTop: '20px' }} /></div>}
            </div>
            : ""}
        {similarProfileLoader ?
            <div className='credit-table-finbit' >
                <table className='text-center  ' style={{ marginTop: '0px' }}>
                    <thead>
                        <tr>
                            <th>Application Id</th>
                            <th>Name</th>
                            <th>Email ID</th>
                            <th>Loan Status</th>
                            <th>Reject Reason</th>
                            <th>Match By</th>
                            <th>Priority</th>
                            <th>Address</th>
                        </tr>
                    </thead>
                    <tbody>
                        {[...Array(6)].map((_, i) => (
                            <tr key={i}>
                                {[...Array(8)].map((_, j) => (
                                    <td key={j}>
                                        <Skeleton height={40} />
                                    </td>
                                ))}
                            </tr>
                        ))}

                    </tbody>
                </table>
            </div>
            : ""}
    </>
)
export default Table