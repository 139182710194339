import React, { useState } from "react";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { loanStatusWiseCountApi, getOveralldashCounts, leadsCountDashBaordApi, getAllFormStatusApi } from "../maketingComponents/actioncreator";
import { monthStartDate, todayDate } from "../maketingComponents/utils/utils";
import { useEffect } from "react";
import {
    CartesianGrid,
    Line,
    LineChart,
    Tooltip,
    XAxis,
    Label,
    YAxis,
    Legend,
    ResponsiveContainer
} from "recharts";
import ReactApexChart from "react-apexcharts";
const steps = [
    'Basic',
    'Net Approval',
    'More Info',
    'Bank',
    'Document',
    'Loan',
    'Applied'
];
const theme = {
    tickText: "#7c7c7c",
    gridStroke: "rgba(4, 4, 4, 0.4)",
    purpleStroke: "#9747FF",
    yellowStroke: "#FFD54F",
    orangeStroke: "#FFAE89",
    legendColor: "#7c7c7c",
};

const DashboardNew = () => {
    const [nameOpportunity, setNameopportunity] = useState([])
    const [dataOpportunity, setDataopportunity] = useState([])

    const [activeStep, setActiveStep] = useState('');
    const [payload, setPayload] = useState({
        fromDate: monthStartDate(),
        toDate: todayDate(),
        minDate: monthStartDate(),
    });
    const [dashBoardCounts, setDashCounts] = useState({});
    const [dashboardLeadsData, setleadsDashboardData] = useState({});
    const [countData, setCountData] = useState([]);
    const [apexChart, setapexChart] = useState(false);
    const [leadGraph, setleadGraph] = useState(false);

    const getCounts = () => {
        getOveralldashCounts(payload, (callBack) => {
            setDashCounts(callBack);
        });
    };


    const steps = countData.length > 0 ? countData.map((item, index) => ({
        // label: `${item.form_status} (${item.count})`,
        label: `${item.form_status}`,
        count: `${item.count}`,

        index: index + 1,
    })) : "";

    const handleStepChange = (step) => {
        setActiveStep(step);
    };
    const getformcount = () => {
        getAllFormStatusApi(
            payload,
            (callBack) => {
                setCountData(callBack);
            },
            (error) => {
                console.log(error);
            }
        );
    };
    useEffect(() => {
        getCounts()
        leadsCount()
        getdataapi()
        getformcount()
    }, [])
    const leadsCount = () => {
        leadsCountDashBaordApi(payload, (callBack) => {
            makegraphDataAadhar(callBack);
        });
    };
    const makegraphDataAadhar = (To) => {
        let data = [];
        Object.entries(To).map((hh, ll) => {
            data.push({
                Date: hh[0],
                Disburse: hh[1].disbursedCount,
                Rejected: hh[1].rejectedCount,
                Total: hh[1].totalCount,
            });
        });

        setleadsDashboardData(data);
    };

    const CustomStepIcon = ({ active, completed, index, data }) => {

        return (
            <div
                style={{
                    width: '60px',
                    height: '60px',
                    borderRadius: '50%',
                    backgroundColor: '#267DFF',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '16px',
                    marginTop: '-16px',
                    // zIndex:'1',
                }}
            >
                {data.count}
                {/* {console.log(active, completed,)} */}
            </div>
        );
    };
    const getdataapi = () => {
        loanStatusWiseCountApi(
            payload,
            (callBack) => {

                makegraphDataOpportunity(callBack)


            },
            (error) => {
                console.log(error);
            }
        );
    };

    const makegraphDataOpportunity = (To) => {
        let name = [];
        let data = [];

        for (const item of To) {
            const { count, status, } = item;

            name.push(status);

            data.push(count);
        }

        setNameopportunity(name);
        setDataopportunity(data);


    }

    const sortedData = dataOpportunity;
    sortedData.sort((a, b) => b - a);
    const mappedObject = dataOpportunity.reduce((result, key, index) => {
        result[key] = nameOpportunity[index];
        return result;
    }, {});
    var options = {
        series: [{
            data: Object.values(sortedData),
        }],
        chart: {
            type: 'bar',
            height: 350,
        },

        plotOptions: {
            bar: {
                // minBarWidth: 50,
                borderRadius: 0,
                horizontal: true,
                barHeight: '90%',
                isFunnel: true,
                borderRadius: 1,
                orientation: 'vertical',
                radius: 2,
                enableShades: true,
                shadeIntensity: 0.9,
                reverseNegativeShade: true,
                distributed: true,
                useFillColorAsStroke: false,


                colorScale: {
                    ranges: [{
                        from: 0,
                        to: 0,
                        color: undefined,
                        foreColor: undefined,
                        name: undefined,
                    }],
                    inverse: false,
                    min: undefined,
                    max: undefined
                },


            },
        },
        colors: ['#4B49AC', '#7978E9', '#7DA0FA', '#98BDFF', '#98BDFFCC'],
        fill: {
            colors: ['#4B49AC', '#7978E9', '#7DA0FA', '#98BDFF', '#98BDFFCC'],
            opacity: 0.3,
            type: 'gradient',
            gradient: {
                shade: 'light',
                type: "vertical",
                shadeIntensity: 1.8,
                gradientToColors: false,
                inverseColors: false,
                opacityFrom: 0.9,
                opacityTo: 1,
                // stops: series.data,
                colorStops: []
            },
        }, dataLabels: {
            enabled: true,
            formatter: function (val, opt) {
                const opportunityName = nameOpportunity[opt.dataPointIndex];
                return val;
            },
            dropShadow: {
                enabled: true,
            },
        },
        xaxis: {
            categories: nameOpportunity,
        },
        legend: {
            position: 'right',
            show: true,
            markers: {
                fillColors: ['#4B49AC', '#7978E9', '#7DA0FA', '#98BDFF', '#98BDFFCC'],
            },
        },


    };
    const convertedObject = countData.length > 0 ? countData.reduce((result, item) => {
        result[item.form_status] = item.count;
        return result;
    }, {}) : "";

    const closePopup=()=>{
        setapexChart(false)
        setleadGraph(false)
    }
    const popupOpen=(type)=>{
        if(type==='apexChart'){
            setapexChart(true)
            setleadGraph(false)

        }
        if(type==='lead'){
            setapexChart(false)
            setleadGraph(true)

        }

    }
    return (
        <div className="container-fluid">
            {apexChart ?
                <div className="background-blur">
                    <div className='text-right' style={{
                    }}>
                        <i onClick={() => closePopup()} style={{
                            fontSize: '14px', cursor: 'pointer', marginRight: '4.9%',
                            marginTop: '79px',
                            position: 'relative',
                            zIndex: 99,
                            height: '25px',
                            width: '25px',
                            background: 'red',
                            color: '#fff', padding: '4px 7px', borderTopRightRadius: '5px', borderBottomRightRadius: '5px'
                        }} class="fa fa-times" aria-hidden="true"></i>
                    </div>
                    <div className="popup-main small-popup container  " style={{ padding: '20px', borderRadius: '10px', width: '100%', maxHeight: '100%', maxWidth: '90%', overflow: 'scroll', paddingBottom: '100px' }}>
                        <div className=' ' style={{ marginBottom: '20px' }}>

                            <ReactApexChart options={options} series={options.series} type="bar" height={500} width={'100%'} />
                        </div>
                    </div>
                </div>
                : ""}
                 {leadGraph ?
                <div className="background-blur">
                    <div className='text-right' style={{
                    }}>
                        <i onClick={() => closePopup()} style={{
                            fontSize: '14px', cursor: 'pointer', marginRight: '4.9%',
                            marginTop: '79px',
                            position: 'relative',
                            zIndex: 99,
                            height: '25px',
                            width: '25px',
                            background: 'red',
                            color: '#fff', padding: '4px 7px', borderTopRightRadius: '5px', borderBottomRightRadius: '5px'
                        }} class="fa fa-times" aria-hidden="true"></i>
                    </div>
                    <div className="popup-main small-popup container  " style={{ padding: '20px', borderRadius: '10px', width: '100%', maxHeight: '100%', maxWidth: '90%', overflow: 'scroll', paddingBottom: '100px' }}>
                        <div className=' ' style={{ marginBottom: '20px' }}>

                        <ResponsiveContainer width={"100%"} height={500}>
                                <LineChart
                                    data={dashboardLeadsData}
                                    margin={{
                                        top: 40,
                                        right: 40,
                                        left: 20,
                                        bottom: 50,
                                    }}
                                >
                                    <CartesianGrid
                                        strokeDasharray={"10 10"}
                                        vertical={false}
                                        stroke={theme.gridStroke}
                                    />
                                    <XAxis
                                        dataKey="Date"

                                        interval={0}
                                        textAnchor="end"
                                        angle={-45}
                                    >

                                    </XAxis>
                                    <YAxis
                                        domain={[0, "dataMax + 0"]}
                                        label={{
                                            value: "Count",
                                            angle: -90,
                                            position: "insideLeft",
                                        }}
                                    />
                                    <Tooltip
                                        wrapperStyle={{ backgroundColor: "#000", fontSize: 12 }}
                                    />

                                    <Line
                                        type="linear"
                                        dataKey="Disburse"
                                        strokeWidth="4"
                                        stroke={theme.purpleStroke}
                                        activeDot={{ r: 5 }}
                                        dot={{
                                            stroke: theme.purpleStroke,
                                            strokeWidth: 4,
                                            fill: theme.purpleStroke,
                                        }}
                                    />
                                    <Line
                                        type="linear"
                                        dataKey="Rejected"
                                        stroke={theme.yellowStroke}
                                        fill="#FFAE89"
                                        strokeWidth="4"
                                        dot={{
                                            stroke: theme.yellowStroke,
                                            strokeWidth: 4,
                                            fill: theme.yellowStroke,
                                        }}
                                        activeDot={{ r: 5 }}
                                    />
                                    <Line
                                        type="linear"
                                        dataKey="Total"
                                        stroke={theme.orangeStroke}
                                        fill="#FFAE89"
                                        strokeWidth="4"
                                        dot={{
                                            stroke: theme.orangeStroke,
                                            strokeWidth: 4,
                                            fill: theme.orangeStroke,
                                        }}
                                        activeDot={{ r: 5 }}
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                                                    </div>
                    </div>
                </div>
                : ""}
            <div className="row">
                <div className="col-sm-2 col-xs-12">
                    <div className="text-left">
                        <h4 className="portfolio-btn" style={{ marginTop: '50px' }}> <b>Portfolio Summary</b></h4>
                    </div>
                </div>
                <div className="col-sm-10 col-xs-12 mui-ste" >
                    <Box sx={{ width: '100%', marginTop: '50px' }}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.length > 0 ? steps.map((step, index) => (
                                <Step key={step.label} onClick={() => handleStepChange(index)}>
                                    <StepLabel StepIconComponent={(props) => <CustomStepIcon {...props} index={step.index} data={step} />} />
                                    <div className="text-center" style={{ marginTop: '10px' }}>{step.label}</div>
                                </Step>
                            )) : ""}
                        </Stepper>
                    </Box>
                    {/* <div className="row">
                        {console.log(steps)}
                        <div className="col-sm-3 col-xs-12" style={{ marginTop: '10px' }}>
                            <div className="new-card-market shadow" style={{ borderRadius: '5px',background:'#e9e9e9' }}>Basic
                                <div><b>{convertedObject.Basic}</b></div>
                            </div>
                        </div>
                        <div className="col-sm-3 col-xs-12" style={{ marginTop: '10px' }}>
                            <div className="new-card-market shadow" style={{ borderRadius: '5px',background:'#e9e9e9' }}>Not Approved
                            <div><b>{convertedObject.NotApproved}</b></div>
                            </div>
                        </div>
                        <div className="col-sm-3 col-xs-12" style={{ marginTop: '10px' }}>
                            <div className="new-card-market shadow" style={{ borderRadius: '5px',background:'#e9e9e9' }}>Adress + Occupation
                            <div><b>{convertedObject['More Info']}</b></div>
                            </div>
                        </div>
                        <div className="col-sm-3 col-xs-12" style={{ marginTop: '10px' }}>
                            <div className="new-card-market shadow" style={{ borderRadius: '5px',background:'#e9e9e9' }}>Bank
                            <div><b>{convertedObject.Bank}</b></div>
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-sm-3 col-xs-12" style={{ marginTop: '10px' }}>
                            <div className="new-card-market shadow" style={{ borderRadius: '5px',background:'#e9e9e9' }}>Document
                            <div><b>{convertedObject.Documents}</b></div>
                            </div>
                        </div>
                        <div className="col-sm-3 col-xs-12" style={{ marginTop: '10px' }}>
                            <div className="new-card-market shadow" style={{ borderRadius: '5px',background:'#e9e9e9' }}>Loan
                            <div><b>{convertedObject.Loan}</b></div>

                            </div>
                        </div>
                        <div className="col-sm-3 col-xs-12" style={{ marginTop: '10px' }}>
                            <div className="new-card-market shadow" style={{ borderRadius: '5px',background:'#e9e9e9' }}>Thanks
                            <div><b>{convertedObject.Thanks}</b></div>

                            </div>
                        </div>

                    </div> */}

                </div>
            </div>
            {/* <hr /> */}
            <div className="container">
                <div className="row" style={{ marginBottom: "70px" ,marginTop:'50px'}}>
                    <div className="col-sm-3 col-xs-12 ">
                        <div className="new-card-market">
                            <h4 style={{ color: "#999999" }}>Total Pushed Leads</h4>
                            <h3 style={{ marginTop: "5px" }}>
                                <strong>{dashBoardCounts.totalPushedCount}</strong>
                            </h3>
                        </div>
                    </div>
                    <div className="col-sm-3 col-xs-12">
                        <div className="new-card-market">
                            <h4 style={{ color: "#999999" }}>Total Applied Leads</h4>
                            <h3 style={{ marginTop: "5px" }}>
                                <strong>{dashBoardCounts.totalAppliedCount}</strong>
                            </h3>
                        </div>
                    </div>
                    <div className="col-sm-3 col-xs-12">
                        <div className="new-card-market">
                            <h4 style={{ color: "#999999" }}>Total Rejected Leads</h4>
                            <h3 style={{ marginTop: "5px" }}>
                                <strong>{dashBoardCounts.rejectedCount}</strong>
                            </h3>
                        </div>
                    </div>
                    <div className="col-sm-3 col-xs-12">
                        <div className="new-card-market ">
                            <h4 style={{ color: "#999999" }}>Total Disbursed Leads </h4>
                            <h3 style={{ marginTop: "5px" }}>
                                <strong>{dashBoardCounts.disbursalCount}</strong>
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ marginBottom: '10px' }}>
                    <div className="col-sm-6 col-xs-12">
                        <div className="shadow" style={{ padding: '10px' }}>
                            <h5 style={{ color: '#1D3557' }}><b>Applied Funnel</b>&nbsp; <span className="fa fa-expand " onClick={() => popupOpen('apexChart')} style={{cursor:'pointer',float:'right'}}></span></h5>
                            <ReactApexChart options={options} series={options.series} type="bar" height={window.innerWidth>=1900?488:300} width={'100%'} />

                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <div className="shadow" style={{ padding: '10px' }}>
                            <h5 style={{ color: '#1D3557' }}><b>Leads Count Graph &nbsp; <span className="fa fa-expand " onClick={() => popupOpen('lead')} style={{cursor:'pointer',float:'right'}}></span></b></h5>

                            <ResponsiveContainer width={"100%"} height={window.innerWidth>=1900?500:315}>
                                <LineChart
                                    data={dashboardLeadsData}
                                    margin={{
                                        top: 40,
                                        right: 40,
                                        left: 20,
                                        bottom: 50,
                                    }}
                                >
                                    <CartesianGrid
                                        strokeDasharray={"10 10"}
                                        vertical={false}
                                        stroke={theme.gridStroke}
                                    />
                                    <XAxis
                                        dataKey="Date"

                                        interval={0}
                                        textAnchor="end"
                                        angle={-45}
                                    >

                                    </XAxis>
                                    <YAxis
                                        domain={[0, "dataMax + 0"]}
                                        label={{
                                            value: "Count",
                                            angle: -90,
                                            position: "insideLeft",
                                        }}
                                    />
                                    <Tooltip
                                        wrapperStyle={{ backgroundColor: "#000", fontSize: 12 }}
                                    />

                                    <Line
                                        type="linear"
                                        dataKey="Disburse"
                                        strokeWidth="4"
                                        stroke={theme.purpleStroke}
                                        activeDot={{ r: 5 }}
                                        dot={{
                                            stroke: theme.purpleStroke,
                                            strokeWidth: 4,
                                            fill: theme.purpleStroke,
                                        }}
                                    />
                                    <Line
                                        type="linear"
                                        dataKey="Rejected"
                                        stroke={theme.yellowStroke}
                                        fill="#FFAE89"
                                        strokeWidth="4"
                                        dot={{
                                            stroke: theme.yellowStroke,
                                            strokeWidth: 4,
                                            fill: theme.yellowStroke,
                                        }}
                                        activeDot={{ r: 5 }}
                                    />
                                    <Line
                                        type="linear"
                                        dataKey="Total"
                                        stroke={theme.orangeStroke}
                                        fill="#FFAE89"
                                        strokeWidth="4"
                                        dot={{
                                            stroke: theme.orangeStroke,
                                            strokeWidth: 4,
                                            fill: theme.orangeStroke,
                                        }}
                                        activeDot={{ r: 5 }}
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default DashboardNew