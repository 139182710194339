import React from 'react'
import successicon from './images/successicon.png'
import targeticon from './images/targeticon.png'
import moment from 'moment';
import Failure from '../../../../images/rewampimages/unverified.png'

export default function ReconcileTabs({ handlePagechange, Pagetabvalue, countData, typeData }) {

    let data = 'Approve';
    return (
        <>
            <div className="ReconcileTabs">
                <div className="row headrow" style={{ marginTop: '20px' }}>
                    <button className='backbtn' onClick={() => handlePagechange('MainPage')}>
                        <i class="fa fa-chevron-left" aria-hidden="true"></i>
                    </button>
                    <h3 className="mb-0"><b>Reconcile</b></h3>
                </div>

                <div className="row tabs">
                    <div className={`box ${Pagetabvalue === 'match' ? 'selectedtab' : ''}`} onClick={() => handlePagechange('match')}>
                        <h3>Match ({countData && countData.data ? countData.data.match : ""})</h3>
                    </div>
                    <div className={`box ${Pagetabvalue === 'notMatch' ? 'selectedtab' : ''}`} onClick={() => handlePagechange('notMatch')}>
                        <h3>Not Match ({countData && countData.data ? countData.data.notMatch : ""})</h3>
                    </div>
                    <div className={`box ${Pagetabvalue === 'notInDB' ? 'selectedtab' : ''}`} onClick={() => handlePagechange('notInDB')}>
                        <h3>Not found in Phocket ({countData && countData.data ? countData.data.notInDB : ""})</h3>
                    </div>
                    <div className={`box ${Pagetabvalue === 'notInReport' ? 'selectedtab' : ''}`} onClick={() => handlePagechange('notInReport')}>
                        <h3>Not found in Razorpay ({countData && countData.data ? countData.data.notInRZPReport : ""})</h3>
                    </div>
                    <div className={`box ${Pagetabvalue === 'notInBoth' ? 'selectedtab' : ''}`} onClick={() => handlePagechange('notInBoth')}>
                        <h3>Not found in Both  ({countData && countData.data ? countData.data.notInBoth : ""})</h3>
                    </div>
                </div>
                {Pagetabvalue === 'match' || Pagetabvalue === 'notMatch' ?
                    <div className="ARTable">
                        <div className="row">


                            <div className="col-lg-6 col-md-12" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.25)' }}>
                                <div className="tablecol">
                                    <div className="heading" style={{ marginTop: '20px' }}>
                                        <h5>Our Database Info</h5>
                                    </div>
                                    <div className='credit-table tablefirst' style={{ fontSize: '12px' }} >
                                        <table className='table1'>
                                            <thead style={{ background: '#b9d8f9', color: '#000' }}>
                                                <tr className="thead-class">
                                                    {/* <th className="">
                                                        <input
                                                            type="checkbox"

                                                        />
                                                    </th> */}
                                                    <th className="">
                                                        Payment Id
                                                    </th>
                                                    <th className="">
                                                        Amount
                                                    </th>
                                                    <th className="">
                                                        Application Id
                                                    </th>
                                                    <th className="">
                                                        Phone Number
                                                    </th>
                                                    <th className="">
                                                        Paid Date
                                                    </th>
                                                    <th className="">
                                                        Emi Status
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {typeData && typeData.data && typeData.data.dbData && Object.entries(typeData.data.dbData).map((data, i) => {
                                                    return (
                                                        <tr style={{ borderBottom: "none", fontWeight: '400' }}>
                                                            {/* <td scope="col">
                                                                <input
                                                                    type="checkbox"
                                                                />
                                                            </td> */}

                                                            <td scope="col" >
                                                                <div style={{  overflow: 'auto' }}>{data[0]}</div>
                                                            </td>
                                                            <td scope="col">
                                                                ₹ {data[1].amount}
                                                            </td>

                                                            <td scope="col">
                                                                {data[1].appId}
                                                            </td>
                                                            <td scope="col">
                                                                {data[1].phoneNo}
                                                            </td>
                                                            <td scope="col">
                                                                {data[1].paidDate ? <>{moment(data[1].paidDate).format('DD-MM-YYYY')}<br />{moment(data[1].paidDate).format('HH:MM:SS')}</> : ""}
                                                            </td>
                                                            {
                                                                data[1].status === 'Approve' ?
                                                                    <td scope="col" className='status' style={{ color: 'rgba(0, 200, 81, 1)' }}>
                                                                        Approve
                                                                    </td>
                                                                    :
                                                                    data[1].status === 'Received' ?
                                                                        <td scope="col" className='status' style={{ color: 'rgba(0, 200, 81, 1)' }}>
                                                                            Received
                                                                        </td>
                                                                        :
                                                                        data[1].status === 'Reject' ?
                                                                            <td scope="col" className='status' style={{ color: 'rgba(177, 47, 48, 1)' }}>
                                                                                Reject
                                                                            </td>
                                                                            :
                                                                            data[1].status === 'Expire' ?
                                                                                <td scope="col" className='status' style={{ color: 'rgba(255, 68, 68, 1)' }}>
                                                                                    Expire
                                                                                </td>
                                                                                :
                                                                                data[1].status === 'Overdue' ?
                                                                                    <td scope="col" className='status' style={{ color: 'rgba(255, 119, 43, 1)' }}>
                                                                                        Overdue
                                                                                    </td>
                                                                                    :
                                                                                    data[1].status === 'Paid' ?
                                                                                        <td scope="col" className='status' style={{ color: 'rgba(38, 125, 255, 1)' }}>
                                                                                            Paid
                                                                                        </td>
                                                                                        : ''}
                                                        </tr>

                                                    )
                                                })}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="tablecol">
                                    <div className="heading secondary">
                                        <h5>Razorpay Report</h5>

                                    </div>
                                    <div className='credit-table tablesecond' style={{ fontSize: '12px' }} >
                                        <table className=' table2'>
                                            <thead style={{ background: '#b9d8f9', color: '#000' }}>
                                                <tr className="thead-class">

                                                    <th className="">
                                                        Payment Id
                                                    </th>
                                                    <th className="">
                                                        Amount
                                                    </th>

                                                    <th className="">
                                                        Phone Number
                                                    </th>
                                                    <th className="">
                                                        Paid Date
                                                    </th>
                                                    <th className="">
                                                        Status
                                                    </th>
                                                   

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {typeData && typeData.data && typeData.data.rzpReport
                                                    && Object.entries(typeData.data.rzpReport
                                                    ).map((data, i) => {
                                                        return (
                                                            <tr style={{ borderBottom: "none", fontWeight: '400' }}>

                                                                <td scope="col">
                                                                    {data[0]}
                                                                </td>
                                                                <td scope="col">
                                                                    ₹ {data[1].amount}
                                                                </td>

                                                                <td scope="col">
                                                                    {data[1].mobileNumber}
                                                                </td>
                                                                <td scope="col">
                                                                    {data[1].paidDate ? <>{moment(data[1].paidDate).format('DD-MM-YYYY')}<br />{moment(data[1].paidDate).format('HH:MM:SS')}</> : ""}
                                                                </td>
                                                                <td scope="col">

                                                                    {data[1].status === 'captured' ? <h5 className='text-success fw-bold'><img src={successicon} width={'10% '} alt="" /> &nbsp; Captured</h5> : ""}
                                                                    {data[1].status === 'failed' ? <h5 className='fw-bold' style={{ color: 'red' }}><img src={Failure} width={'10% '} alt="" /> &nbsp; Failed</h5> : ""}

                                                                </td>
                                                            </tr>
                                                        )
                                                    })}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ""}
                {Pagetabvalue === 'notInReport' ?
                    <div className="ARTable">
                        <div className="row">


                            <div className="col-lg-12 col-md-12" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.25)' }}>
                                <div className="tablecol">
                                    <div className="heading" style={{ marginTop: '20px' }}>
                                        <h5>Our Database Info</h5>
                                    </div>
                                    <div className='credit-table tablefirst' style={{ fontSize: '12px' }} >
                                        <table className='table1'>
                                            <thead style={{ background: '#b9d8f9', color: '#000' }}>
                                                <tr className="thead-class">
                                                    {/* <th className="">
                                                        <input
                                                            type="checkbox"

                                                        />
                                                    </th> */}
                                                    <th className="">
                                                        Payment Id
                                                    </th>
                                                    <th className="">
                                                        Amount
                                                    </th>
                                                    <th className="">
                                                        Application Id
                                                    </th>
                                                    <th className="">
                                                        Phone Number
                                                    </th>
                                                    <th className="">
                                                        Paid Date
                                                    </th>
                                                    <th className="">
                                                        Emi Status
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {typeData && typeData.data && typeData.data && Object.entries(typeData.data).map((data, i) => {
                                                    return (
                                                        <tr style={{ borderBottom: "none", fontWeight: '400' }}>
                                                            {/* <td scope="col">
                                                                <input
                                                                    type="checkbox"
                                                                />
                                                            </td> */}

                                                            <td scope="col" >
                                                                <div style={{  overflow: 'auto' }}>{data[0]}</div>
                                                            </td>
                                                            <td scope="col">
                                                                ₹ {data[1].amount}
                                                            </td>

                                                            <td scope="col">
                                                                {data[1].appId}
                                                            </td>
                                                            <td scope="col">
                                                                {data[1].phoneNo}
                                                            </td>
                                                            <td scope="col">
                                                                {data[1].paidDate ? <>{moment(data[1].paidDate).format('DD-MM-YYYY')}<br />{moment(data[1].paidDate).format('HH:MM:SS')}</> : ""}
                                                            </td>
                                                            {
                                                                data[1].status === 'Approve' ?
                                                                    <td scope="col" className='status' style={{ color: 'rgba(0, 200, 81, 1)' }}>
                                                                        Approve
                                                                    </td>
                                                                    :
                                                                    data[1].status === 'Received' ?
                                                                        <td scope="col" className='status' style={{ color: 'rgba(0, 200, 81, 1)' }}>
                                                                            Received
                                                                        </td>
                                                                        :
                                                                        data[1].status === 'Reject' ?
                                                                            <td scope="col" className='status' style={{ color: 'rgba(177, 47, 48, 1)' }}>
                                                                                Reject
                                                                            </td>
                                                                            :
                                                                            data[1].status === 'Expire' ?
                                                                                <td scope="col" className='status' style={{ color: 'rgba(255, 68, 68, 1)' }}>
                                                                                    Expire
                                                                                </td>
                                                                                :
                                                                                data[1].status === 'Overdue' ?
                                                                                    <td scope="col" className='status' style={{ color: 'rgba(255, 119, 43, 1)' }}>
                                                                                        Overdue
                                                                                    </td>
                                                                                    :
                                                                                    data[1].status === 'Paid' ?
                                                                                        <td scope="col" className='status' style={{ color: 'rgba(38, 125, 255, 1)' }}>
                                                                                            Paid
                                                                                        </td>
                                                                                        :
                                                                                        data[1].status === 'Payment Overdue' ?
                                                                                            <td scope="col" className='status' style={{ color: 'red' }}>
                                                                                                Payment Overdue
                                                                                            </td>
                                                                                            : ''}
                                                        </tr>

                                                    )
                                                })}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ""}
                      {Pagetabvalue === 'notInDB' ?
                    <div className="ARTable">
                        <div className="row">


                            <div className="col-lg-12 col-md-12" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.25)' }}>
                                <div className="tablecol">
                                    <div className="heading" style={{ marginTop: '20px' }}>
                                        <h5>Our Database Info</h5>
                                    </div>
                                    <div className='credit-table tablefirst' style={{ fontSize: '12px' }} >
                                        <table className=' table2'>
                                            <thead style={{ background: '#b9d8f9', color: '#000' }}>
                                                <tr className="thead-class">

                                                    <th className="">
                                                        Payment Id
                                                    </th>
                                                    <th className="">
                                                        Amount
                                                    </th>

                                                    <th className="">
                                                        Phone Number
                                                    </th>
                                                    <th className="">
                                                        Paid Date
                                                    </th>
                                                    <th className="">
                                                        Status
                                                    </th>
                                                  

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {typeData && typeData.data && typeData.data
                                                    && Object.entries(typeData.data
                                                    ).map((data, i) => {
                                                        return (
                                                            <tr style={{ borderBottom: "none", fontWeight: '400' }}>

                                                                <td scope="col">
                                                                    {data[0]}
                                                                </td>
                                                                <td scope="col">
                                                                    ₹ {data[1].amount}
                                                                </td>

                                                                <td scope="col">
                                                                    {data[1].mobileNumber}
                                                                </td>
                                                                <td scope="col">
                                                                    {data[1].paidDate ? <>{moment(data[1].paidDate).format('DD-MM-YYYY')}<br />{moment(data[1].paidDate).format('HH:MM:SS')}</> : ""}
                                                                </td>
                                                                <td scope="col">

                                                                    {data[1].status === 'captured' ? <h5 className='text-success fw-bold'><img src={successicon} width={'10% '} alt="" /> &nbsp; Captured</h5> : ""}
                                                                    {data[1].status === 'failed' ? <h5 className='fw-bold' style={{ color: 'red' }}><img src={Failure} width={'10% '} alt="" /> &nbsp; Failed</h5> : ""}

                                                                </td>
                                                            </tr>
                                                        )
                                                    })}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ""}
                       {Pagetabvalue === 'notInBoth' ?
                    <div className="ARTable">
                        <div className="row">


                            <div className="col-lg-12 col-md-12" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.25)' }}>
                                <div className="tablecol">
                                    <div className="heading" style={{ marginTop: '20px' }}>
                                        <h5>Our Database Info</h5>
                                    </div>
                                    <div className='credit-table tablefirst' style={{ fontSize: '12px' }} >
                                        <table className=' table2'>
                                            <thead style={{ background: '#b9d8f9', color: '#000' }}>
                                                <tr className="thead-class">

                                                  
                                                    <th className="">
                                                        Amount
                                                    </th>

                                                    <th className="">
                                                        Phone Number
                                                    </th>
                                                    <th className="">
                                                        Paid Date
                                                    </th>
                                                    <th className="">
                                                        Status
                                                    </th>
                                                  

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {typeData && typeData.data && typeData.data
                                                    && Object.entries(typeData.data
                                                    ).map((data, i) => {
                                                        return (
                                                            <tr style={{ borderBottom: "none", fontWeight: '400' }}>

                                                              
                                                                <td scope="col">
                                                                    ₹ {data[1].amount}
                                                                </td>

                                                                <td scope="col">
                                                                    {data[1].phoneNo}
                                                                </td>
                                                                <td scope="col">
                                                                    {data[1].paidDate ? <>{moment(data[1].paidDate).format('DD-MM-YYYY')}<br />{moment(data[1].paidDate).format('HH:MM:SS')}</> : ""}
                                                                </td>
                                                                <td scope="col">
{data[1].status}
                                                                    {/* {data[1].status === 'captured' ? <h5 className='text-success fw-bold'><img src={successicon} width={'10% '} alt="" /> &nbsp; Captured</h5> : ""}
                                                                    {data[1].status === 'failed' ? <h5 className='fw-bold' style={{ color: 'red' }}><img src={Failure} width={'10% '} alt="" /> &nbsp; Failed</h5> : ""} */}

                                                                </td>
                                                            </tr>
                                                        )
                                                    })}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ""}
            </div>
        </>
    )
}
