import React from "react";
import FinBit from "./finbitAnalysis";
import Digitap from './digitap'
const FinancialAnalysis = ({ statementSource, allDetail, admin, finbitJson, finbitJsonData }) => {
    return (
        <>
            {statementSource.digitap === true ?
                <Digitap allDetail={allDetail} admin={admin} finbitJson={finbitJson} finbitJsonData={finbitJsonData} />
                :
                <FinBit allDetail={allDetail} admin={admin} finbitJson={finbitJson} finbitJsonData={finbitJsonData} />
            }
        </>
    )
}
export default FinancialAnalysis