import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, BrowserRouter } from 'react-router-dom';
import * as Routers from './RouterNew';
import RoutesUrl from './Routes';
import configureStore from '../store/configureStore';
import initialState from '../store/InitialState.config';
import AppLayout from './App.Layout'
import Alert from '../components/containers/Forms/alert'
import history from '../utils/History';
import Loader from '../components/containers/Admin/Login.container'


const AppRouter = () => (
  <Provider store={configureStore({ initialState })}>

    <Router history={history}>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path={RoutesUrl.ADMINLOGIN} element={<Routers.LoginContainer />} />

          <Route element={<AppLayout />}>
            {/* <Route path={RoutesUrl.ADMINLOGIN} element={<Routers.LoginContainer />} /> */}


            <Route path={RoutesUrl.CUSRTOMER_LOGIN} element={<Routers.TEAGARDEN_LOGIN />} />
            <Route path={RoutesUrl.CUSRTOMER_FORM} element={<Routers.TEAGARDEN_FORM />} />
            <Route path={RoutesUrl.TEA_AMBASSADOR} element={<Routers.TEA_AMBASSADOR_PAGE />} />
            <Route path={RoutesUrl.TEA_AMBASSADOR_DASHBOARD} element={<Routers.TEA_AMBASSADOR_DASHBOARD_PAGE />} />
            <Route path={RoutesUrl.AMBASSADOR_CUSTOMER_FLOW + '?Id='} element={<Routers.TEAGARDEN_FORM />} />
            <Route path={RoutesUrl.AMBASSADORLOGIN} element={<Routers.AmbassadorLogin />} />
            <Route path={RoutesUrl.E_SIGN_PAGE} element={<Routers.ESIGNPAGE />} />
            <Route path={RoutesUrl.MERCHANT_LOGIN} element={<Routers.MerchantLogin />} />
            <Route path={RoutesUrl.LOGIN} element={<Routers.Login />} />
            <Route path={RoutesUrl.HOME + "/" + RoutesUrl.FORM} element={<Routers.Form />} />
            <Route path={RoutesUrl.HOME + "/" + RoutesUrl.DASHBOARD} element={<Routers.Dashboard />} />
          </Route>

          <Route path={RoutesUrl.ENACH_SIGNDESK} element={<Routers.ENACH_SIGNDESK />} />
          <Route path={RoutesUrl.ENACH_SIGNDESK_MOBILE} element={<Routers.ENACH_SIGNDESK_MOBILE />} />


          {/* TeaGardenEnd */}
          <Route path={RoutesUrl.LOGIN} element={<Routers.Login />} />
          <Route path={RoutesUrl.PROMOTIONALDATA} element={<Routers.PROMOTIONALDATA />} />
          <Route path={RoutesUrl.CALCULATOR} element={<Routers.EmiCalc />} />
          <Route path={RoutesUrl.FEEDBACK} element={<Routers.Feedback />} />
          <Route path={RoutesUrl.RESPONSEFORVERIFICATION} element={<Routers.VerificationPage />} />
          <Route path={RoutesUrl.ANALYSIS} element={<Routers.analysis />} />
          <Route path={RoutesUrl.AMBASSADORLOGIN} element={<Routers.AmbassadorLogin />} />
          <Route path={RoutesUrl.MOBILE_FAQ} element={<Routers.mobielFaq />} />
          <Route path={RoutesUrl.COUPONSNEW} element={<Routers.couponNew />} />
          <Route path={RoutesUrl.MERCHANT_REGISTRATION} element={<Routers.MerRegistration />} />
          <Route path={RoutesUrl.MERCHANT_LOGIN} element={<Routers.MerchantLogin />} />
          <Route path={RoutesUrl.UPLOAD_DOCUMENT} element={<Routers.UPLOAD_DOCUMENT />} />
          <Route path={RoutesUrl.MERCHANT_DASHBOARD} element={<Routers.MerchantDashboard />} />
          <Route path={RoutesUrl.CAMPAIGNTRACK} element={<Routers.CAMPAIGNTRACK />} />
          <Route path={RoutesUrl.THIRDPARTYLOGIN} element={<Routers.THIRDPARTYLOGIN />} />
          <Route path={RoutesUrl.THIRDPARTYREGISTRATIONS} element={<Routers.THIRDPARTYREGISTRATIONS />} />
          <Route path={RoutesUrl.APPLICANTREGISTRATION} element={<Routers.APPLICANTREGISTRATION />} />
          <Route path={RoutesUrl.WEBBLOG} element={<Routers.WEBBLOG />} />
          <Route path={RoutesUrl.TECHPROCESS} element={<Routers.TECHPROCESS />} />
          <Route path={RoutesUrl.E_NACH_PAGE} element={<Routers.ENACHPAGE />} />
          <Route path={RoutesUrl.E_SIGN_NACH_PAGE} element={<Routers.ENACHPAGE />} />
          <Route path={RoutesUrl.E_SIGN_PAGE} element={<Routers.ESIGNPAGE />} />
          <Route path={RoutesUrl.HOME + "/" + RoutesUrl.DASHBOARD} element={<Routers.Dashboard />} />
          <Route path={RoutesUrl.HOME + "/" + RoutesUrl.FORM} element={<Routers.Form />} />
          <Route path={RoutesUrl.AMBASSADOR + "/" + RoutesUrl.AMBDASHBOARD} element={<Routers.AmbDashboard />} />
          <Route path={RoutesUrl.AMBASSADOR + "/" + RoutesUrl.AMB_FORM} element={<Routers.AmbForm />} />
          {/* <Route path={RoutesUrl.NBFCDASHBOARD} element={<Routers.NBFCDASHBOARD/>} />
        <Route path={RoutesUrl.NBFCLOGIN} element={<Routers.NBFCLOGIN/>} /> */}
          <Route path={RoutesUrl.UNSUBSCRIBE_PAGE} element={<Routers.UNSUBSCRIBE_PAGE />} />
          <Route path='/demo' element={<Routers.DEMO />} />
          <Route path={RoutesUrl.CONSENT} element={<Routers.consent />} />
          <Route path={RoutesUrl.Reference} element={<Routers.Reference />} />
          <Route path={RoutesUrl.BADABAZAR_LOGIN} element={<Routers.BadaBazaar />} />
          <Route path={RoutesUrl.PORTAL_DASHBOARD} element={<Routers.PoratlDashboard />} />
          <Route path='/alert' element={<Alert />} />
          <Route path={RoutesUrl.HOME + "/" + RoutesUrl.FORM + '?uniqueId='} element={<Routers.Form />} />
          <Route path={RoutesUrl.HOME + "/" + RoutesUrl.DASHBOARD + '?uniqueId='} element={<Routers.Dashboard />} />
          <Route path={RoutesUrl.E_NACH_PAGE_MOBILE} element={<Routers.EnachMobile />} />
          <Route path={RoutesUrl.E_SIGN_PAGE_MOBILE} element={<Routers.EsignMobile />} />
          {/* <Route path={RoutesUrl.PAYMENT_RAZORPAY_URL} element={<Routers.RazorpayPayment />} /> */}
          <Route path={RoutesUrl.E_NACH_PAGE_CARD} element={<Routers.EnachCardPage />} />
          <Route path={RoutesUrl.E_SIGN_PAGE_CARD} element={<Routers.EsignCardPage />} />
          <Route path={RoutesUrl.SAVE_BANK_DETAILS_URL} element={<Routers.SAVE_BANK_DETAILS_URL />} />
          <Route path={RoutesUrl.ENACH_BILL_DESK} element={<Routers.ENACH_BILL_DESK />} />
          <Route path={RoutesUrl.BILL_PAYMENT} element={<Routers.BILL_PAYMENT />} />
          <Route path={RoutesUrl.BUISNESS_URL} element={<Routers.BUISNESS_URL />} />
          <Route path={RoutesUrl.DEMO} element={<Routers.DEMO />} />
          <Route path={RoutesUrl.MOBILE_EMANDATE_BILLDESK} element={<Routers.MOBILE_EMANDATE_BILLDESK />} />
          <Route path={RoutesUrl.MOBILE_PAY_BILLDESK} element={<Routers.MOBILE_PAY_BILLDESK />} />
          <Route path={RoutesUrl.FIDYPAY_MANDATE} element={<Routers.FIDYPAY_MANDATE />} />
          <Route path={RoutesUrl.MARKETING_FORM} element={<Routers.MARKETING_FORM />} />
          <Route path={RoutesUrl.SEIGNDESK_REDIRECT_URL} element={<Routers.SEIGNDESK_REDIRECT_URL />} />
          <Route path={RoutesUrl.KYC_FORM} element={<Routers.KYC_FORM />} />
          <Route path={RoutesUrl.KYC_MOBILE} element={<Routers.KYC_MOBILE />} />
          <Route path={RoutesUrl.FETCHMAIL} element={<Routers.FETCHMAIL />} />
          <Route path={RoutesUrl.MAIL_DATA} element={<Routers.MAIL_DATA />} />

        </Routes>
      </Suspense>
    </Router>
  </Provider>
)


export default AppRouter;
