import React, { useState } from 'react'
import LosLogo from '../../../../images/rewampimages/LOS.svg'
import LmsLogo from '../../../../images/rewampimages/LMS.svg'
import FinanceLogo from '../../../../images/rewampimages/finance.svg'
import MarketingLogo from '../../../../images/rewampimages/marketing.svg'
import CollectionLogo from '../../../../images/rewampimages/Collection.svg'
import AccessmanagementLogo from '../../../../images/rewampimages/accessmanagement.svg'
import DashboardLogo from '../../../../images/rewampimages/dashboardreport.svg'
import ToolsLogo from '../../../../images/rewampimages/tools.svg'
import NbfcLogo from '../../../../images/rewampimages/nbfc.svg'
import { ROLE } from '../../../../utils/Constant'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// const Landingpage = ({ pageViewHandler, admin }) => {
//     const [lmsSubTab, setlmsTab] = useState(false)
//     const subHandleTab = (first, second) => {

//         if (second === 'lms' && first === 'bulkOps') {
//             setlmsTab(!lmsSubTab)
//         }
//     }
class Landingpage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lmsSubTab: false,
            generateReportState: true,
            nbfcTabs: false
        }
    }
    componentDidMount() {
        const { admin } = this.props
        if (admin.emailId !== undefined && admin.role != "superuser" && admin.emailId !== 'vishnu.pandey@phocket.in' && admin.emailId !== 'sunil.kumar@phocket.in' && admin.emailId !== 'ravinder.sharma@phocket.in' && admin.emailId !== 'mohit.bansal@phocket.in' && admin.emailId !== 'priyanka.kumari@phocket.in' && admin.emailId !== 'piyush.jain@phocket.in' && admin.emailId !== 'rakesh.rawat@phocket.in') {
            var today = new Date().getHours();
            if (today > 19) {
                console.log(today, 'Hour')
            }
            if (today < 9) {
                console.log(today, 'Hours')
            }
            if (today > 9 && today < 19) {
                console.log(today)
                this.setState({ generateReportState: false })
            }
        }
    }





    subHandleTab(first, second) {

        if (second === 'lms' && first === 'bulkOps') {
            // setlmsTab(!lmsSubTab)
            this.setState({ lmsSubTab: !this.state.lmsSubTab })
        }
    }
    nbfcTabsHandle() {
        this.setState({ nbfcTabs: !this.state.nbfcTabs })


    }
    render() {
        const { admin, pageViewHandler } = this.props
        return (
            <>
                {admin && admin.rolelist !== undefined ?
                    <div className='container-fluid blue-scroll'>
                        <div className='row'>
                            {admin.rolelist != undefined && (admin.rolelist.indexOf(ROLE.LOAN_APPROVAL_LEADS) >= 0 || admin.rolelist.indexOf(ROLE.APPLICATION_STATUS) >= 0 || admin.rolelist.indexOf(ROLE.LEAD_MANAGEMENT) >= 0 || admin.rolelist.indexOf(ROLE.Gift_Card_Leads) >= 0) ?
                                <div className='col-sm-3 col-xs-12'>
                                    <div className='landing-cards' onClick={() => this.props.mainMenuHandler('LOS')}>
                                        <div className='landing-head'><img style={{ width: '24px' }} src={LosLogo} />&nbsp;LOS</div>
                                        <div className='landing-inner'>
                                            {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                admin.rolelist.indexOf(ROLE.LOAN_APPROVAL_LEADS) >= 0 ?
                                                    <div style={{ marginTop: '15px' }}
                                                        id='leadMainPage'
                                                        onClick={() => pageViewHandler('leadMainPage')}
                                                    >
                                                        <i className='fa fa-chevron-circle-right'></i> &nbsp; All Leads
                                                    </div>
                                                    : "" : ""}
                                            {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                admin.rolelist.indexOf(ROLE.APPLICATION_STATUS) >= 0 ?
                                                    <div style={{ marginTop: '15px' }}
                                                        id='AppStatus'
                                                        onClick={() => pageViewHandler('AppStatus')}
                                                    >
                                                        <i className='fa fa-chevron-circle-right'></i> &nbsp; All Application
                                                    </div>
                                                    : "" : ""}
                                            {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                admin.rolelist.indexOf(ROLE.LEAD_MANAGEMENT) >= 0 ?
                                                    <div style={{ marginTop: '15px' }}
                                                        id='leadManagement'
                                                        onClick={() => pageViewHandler('leadManagement')}
                                                    >
                                                        <i className='fa fa-chevron-circle-right'></i> &nbsp;  Lead Management
                                                    </div>
                                                    : "" : ""}
                                            {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                admin.rolelist.indexOf(ROLE.Gift_Card_Leads) >= 0 ?
                                                    <div
                                                        style={{ marginTop: '15px' }}
                                                        id='giftCardLeads'
                                                        onClick={() => pageViewHandler('giftCardLeads')}
                                                    >
                                                        <i className='fa fa-chevron-circle-right'></i> &nbsp; Gift Card Leads
                                                    </div>
                                                    : "" : ""}
                                            {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                admin.rolelist.indexOf(ROLE.LOAN_APPROVAL_LEADS) >= 0 ?
                                                    <div
                                                        style={{ marginTop: '15px' }}
                                                        id='kycPendingLeads'
                                                        onClick={() => pageViewHandler('kycPendingLeads')}
                                                    >
                                                        <i className='fa fa-chevron-circle-right'></i> &nbsp; Kyc Pending Leads
                                                    </div>
                                                    : "" : ""}
                                            {/* {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                            admin.rolelist.indexOf(ROLE.Vreedhi_Leads) >= 0 ?
                                                <div style={{ marginTop: '15px' }}
                                                    id='vreedhiLeads'
                                                    onClick={() => pageViewHandler('vreedhiLeads')}
                                                >
                                                    <i className='fa fa-chevron-circle-right'></i> &nbsp; Vreedhi Leads
                                                </div>
                                                : "" : ""}
                                        {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                            admin.rolelist.indexOf(ROLE.Bada_Business_Leads) >= 0 ?
                                                <div style={{ marginTop: '15px' }}
                                                    id='BadaBuisnessLeads'
                                                    onClick={() => pageViewHandler('BadaBuisnessLeads')}
                                                >
                                                    <i className='fa fa-chevron-circle-right'></i> &nbsp; Bada Buisness Leads
                                                </div>
                                                : "" : ""}
                                        {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                            admin.rolelist.indexOf(ROLE.Tea_Garden) >= 0 ?
                                                <div style={{ marginTop: '15px' }}
                                                    id='garden'
                                                    onClick={() => pageViewHandler('teaGardenLeads')}
                                                >
                                                    <i className='fa fa-chevron-circle-right'></i> &nbsp; Tea Garden Leads
                                                </div>
                                                : "" : ""} */}
                                        </div>
                                    </div>
                                </div>
                                : ""}
                            {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                (admin.rolelist.indexOf(ROLE.Disbursed_Leads) >= 0 || admin.rolelist.indexOf(ROLE.Incoming_Leads) >= 0 || admin.rolelist.indexOf(ROLE.Nach_Registration) >= 0 || admin.rolelist.indexOf(ROLE.Bulk_Nach_Bounce) >= 0 || admin.rolelist.indexOf(ROLE.Initiate_Bulk_Payment) >= 0 || admin.rolelist.indexOf(ROLE.Bulk_Paid) >= 0 || admin.rolelist.indexOf(ROLE.Bulk_Nach_Status_Update) >= 0) ?
                                    <div className='col-sm-3 col-xs-12'>
                                        <div className='landing-cards' onClick={() => this.props.mainMenuHandler('LMS')}>
                                            <div className='landing-head'><img style={{ width: '24px' }} src={LmsLogo} />&nbsp;LMS</div>
                                            <div className='landing-inner'>
                                                {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                    admin.rolelist.indexOf(ROLE.Disbursed_Leads) >= 0 ?
                                                        <div
                                                            style={{ marginTop: '15px' }}
                                                            onClick={() => pageViewHandler('recoveryDisburse')}
                                                        >
                                                            <i className='fa fa-chevron-circle-right'></i> &nbsp; Disbursed Leads
                                                        </div>
                                                        : "" : ""}
                                                {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                    admin.rolelist.indexOf(ROLE.Incoming_Leads) >= 0 ?
                                                        <div
                                                            style={{ marginTop: '15px' }}
                                                            onClick={() => pageViewHandler('recoveryIncoming')}
                                                        >
                                                            <i className='fa fa-chevron-circle-right'></i> &nbsp;  Incoming List
                                                        </div>
                                                        : "" : ""}
                                                {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                    admin.rolelist.indexOf(ROLE.Nach_Registration) >= 0 ?
                                                        <div
                                                            style={{ marginTop: '15px' }}
                                                            onClick={() => pageViewHandler('nachRegistrationReport')}
                                                        >
                                                            <i className='fa fa-chevron-circle-right'></i> &nbsp;  NACH Registration
                                                        </div>
                                                        : "" : ""}
                                                {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                    (admin.rolelist.indexOf(ROLE.Bulk_Nach_Bounce) >= 0 || admin.rolelist.indexOf(ROLE.Initiate_Bulk_Payment) >= 0 || admin.rolelist.indexOf(ROLE.Bulk_Paid) >= 0 || admin.rolelist.indexOf(ROLE.Bulk_Nach_Status_Update) >= 0 || admin.rolelist.indexOf(ROLE.Approved_To_Disbursed_Disbursed_By_ICICI) >= 0) ?
                                                        <>
                                                            <div className='mail-list'>
                                                                <div
                                                                    style={{ marginTop: '15px' }}
                                                                    // onClick={()=> navItemClick('bulkOps')}
                                                                    onClick={() => this.subHandleTab('bulkOps', 'lms')}>
                                                                    <i className='fa fa-chevron-circle-right'></i> &nbsp; Bulk Operations {this.state.lmsSubTab ? <i className="fa fa-caret-up" aria-hidden="true" style={{ float: 'right', color: '#000' }}></i> : <i className="fa fa-caret-down" aria-hidden="true" style={{ float: 'right', color: '#000' }}></i>}
                                                                </div>
                                                            </div>
                                                            {this.state.lmsSubTab ?
                                                                <>
                                                                    {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                                        admin.rolelist.indexOf(ROLE.Bulk_Nach_Bounce) >= 0 ?
                                                                            <li style={{ marginTop: '10px' }}
                                                                                onClick={() => pageViewHandler('bulkNachBounceByCsv')}
                                                                            >
                                                                                Bulk Nach bounce By CSV
                                                                            </li>
                                                                            : "" : ""}
                                                                    {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                                        admin.rolelist.indexOf(ROLE.Initiate_Bulk_Payment) >= 0 ?
                                                                            <li style={{ marginTop: '10px' }}
                                                                                onClick={() => pageViewHandler('bulkPayByRazorPay')}
                                                                            >
                                                                                Initiate Bulk Payment By CSV
                                                                            </li>
                                                                            : "" : ""}
                                                                    {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                                        admin.rolelist.indexOf(ROLE.Bulk_Paid) >= 0 ?
                                                                            <li style={{ marginTop: '10px' }}
                                                                                onClick={() => pageViewHandler('covertToPaid')}
                                                                            >
                                                                                Convert to Paid
                                                                            </li>
                                                                            : "" : ""}
                                                                    {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                                        admin.rolelist.indexOf(ROLE.Bulk_Nach_Status_Update) >= 0 ?
                                                                            <li style={{ marginTop: '10px' }}
                                                                                onClick={() => pageViewHandler('addNachStatus')}
                                                                            >
                                                                                Update Nach Status By Csv
                                                                            </li>
                                                                            : "" : ""}
                                                                    {admin.rolelist != undefined ?
                                                                        admin.rolelist.indexOf(ROLE.Approved_To_Disbursed_Disbursed_By_ICICI) >= 0 ?
                                                                            <li style={{ marginTop: '10px' }}
                                                                                onClick={() => pageViewHandler('icici')}
                                                                            >

                                                                                Disburse By ICICI
                                                                            </li>
                                                                            : "" : ""}
                                                                    {admin.rolelist != undefined ?
                                                                        admin.rolelist.indexOf(ROLE.Approved_To_Disbursed_Disbursed_By_ICICI) >= 0 ?
                                                                            <li style={{ marginTop: '10px' }}
                                                                                onClick={e => pageViewHandler('approveToDisburse')}
                                                                            >
                                                                                Approved to Disburse By CSV
                                                                            </li>
                                                                            : "" : ""}
                                                                    {admin.rolelist != undefined ?
                                                                        admin.rolelist.indexOf(ROLE.DEACTIVATE_NACH) >= 0 ?
                                                                            <li style={{ marginTop: '10px' }}
                                                                                onClick={e => pageViewHandler('bulkDeactivateDrp')}
                                                                            >
                                                                                Deactivate Bulk Nach(DRP)
                                                                            </li>
                                                                            : "" : ""}
                                                                    {/* {admin.rolelist != undefined ?
                                                                        admin.rolelist.indexOf(ROLE.DEACTIVATE_NACH) >= 0 ?
                                                                            <li style={{ marginTop: '10px' }}
                                                                                onClick={e => pageViewHandler('bulkOverDueSms')}
                                                                            >
                                                                                Bulk OverDue Sms
                                                                            </li>
                                                                            : "" : ""} */}


                                                                </>
                                                                : ""}
                                                        </> : "" : ""}

                                            </div>
                                        </div>
                                    </div>
                                    : "" : ""}
                            {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                (admin.rolelist.indexOf(ROLE.Asset_Quality) >= 0 || admin.rolelist.indexOf(ROLE.INCOME_COLLECTED) >= 0 || admin.rolelist.indexOf(ROLE.API_TRACKER) >= 0 || admin.rolelist.indexOf(ROLE.RAZORPAY_REPORT) >= 0) ?
                                    <div className='col-sm-3 col-xs-12'>
                                        <div className='landing-cards' onClick={() => this.props.mainMenuHandler('FINANCE')}>
                                            <div className='landing-head'><img style={{ width: '24px' }} src={FinanceLogo} />&nbsp;Finance</div>
                                            <div className='landing-inner'>
                                                {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                    admin.rolelist.indexOf(ROLE.Asset_Quality) >= 0 ?
                                                        <div
                                                            style={{ marginTop: '15px' }}
                                                            onClick={() => pageViewHandler('assetQuality')}
                                                        >
                                                            <i className='fa fa-chevron-circle-right'></i> &nbsp; Assets Quality
                                                        </div>
                                                        : "" : ""}
                                                {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                    admin.rolelist.indexOf(ROLE.RAZORPAY_REPORT) >= 0 ?
                                                        <div
                                                            style={{ marginTop: '15px' }}
                                                            onClick={() => pageViewHandler('razorpay')}
                                                        >
                                                            <i className='fa fa-chevron-circle-right'></i> &nbsp; Razorpay Report
                                                        </div>
                                                        : "" : ""}

                                                {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                    admin.rolelist.indexOf(ROLE.INCOME_COLLECTED) >= 0 ?
                                                        <div
                                                            style={{ marginTop: '15px' }}
                                                            onClick={() => pageViewHandler('incomeCollected')}
                                                        >
                                                            <i className='fa fa-chevron-circle-right'></i> &nbsp;    Income Breakup
                                                        </div>
                                                        : "" : ""}
                                                {admin.emailId == "SuperUser" || admin.emailId == "priyanka.jain@phocket.in" || admin.emailId == "tapan.kumar@phocket.in" ?
                                                    <div
                                                        style={{ marginTop: '15px' }}
                                                        onClick={() => pageViewHandler('razorpayTransfer')}
                                                    >
                                                        <i className='fa fa-chevron-circle-right'></i> &nbsp;   Razorpay Transfer
                                                    </div>
                                                    : ""}
                                                {admin.rolelist.indexOf(ROLE.API_TRACKER) >= 0 ?
                                                    <div style={{ marginTop: '15px' }}
                                                        onClick={() => pageViewHandler('apiconfig')}
                                                    >
                                                        <i className='fa fa-chevron-circle-right'></i> &nbsp;API Tracker
                                                    </div>
                                                    : ""
                                                }



                                                {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                    admin.rolelist.indexOf(ROLE.RAZORPAY_REPORT) >= 0 ?
                                                        <div style={{ marginTop: '15px' }}
                                                            onClick={() => pageViewHandler('accountReconciliation')}
                                                        >
                                                            <i className='fa fa-chevron-circle-right'></i> &nbsp;Account Reconciliation
                                                        </div>
                                                        : "" : ""}
                                            </div>
                                        </div>
                                    </div>
                                    : "" : ""}
                            {admin.rolelist.indexOf(ROLE.MARKETING_MAIN) >= 0 ?
                                <div className='col-sm-3 col-xs-12'>
                                    <div className='landing-cards' onClick={() => this.props.mainMenuHandler('BUSINESS')}>
                                        <div className='landing-head'><img style={{ width: '24px' }} src={MarketingLogo} />&nbsp;Business </div>
                                        <div className='landing-inner'>
                                            <div style={{ marginTop: '15px' }}
                                                onClick={() => pageViewHandler('maketingDashboard')}
                                            >
                                                <i className='fa fa-chevron-circle-right'></i> &nbsp; Dashboard
                                            </div>

                                            <div style={{ marginTop: '15px' }}
                                                onClick={() => pageViewHandler('maketingList')}
                                            >
                                                <i className='fa fa-chevron-circle-right'></i> &nbsp; Aggregators
                                            </div>
                                            {/*<div style={{ marginTop: '15px' }}
                        className={pageViewState === 'budgetPlan' ? 'sub-list-item-active' : 'sub-list-item'}
                        onClick={()=> pageViewHandler('budgetPlan')}
                    >
                        Budget Plan
                    </li> */}
                                            <div style={{ marginTop: '15px' }}
                                                onClick={() => pageViewHandler('runCampaign')}
                                            >
                                                <i className='fa fa-chevron-circle-right'></i> &nbsp; Run Campaign
                                            </div>
                                            <div style={{ marginTop: '15px' }}
                                                onClick={() => pageViewHandler('deviation')}
                                            >
                                                <i className='fa fa-chevron-circle-right'></i> &nbsp; Deviation Report
                                            </div>
                                            <div style={{ marginTop: '15px' }}
                                                onClick={() => pageViewHandler('buisnessAggregator')}
                                            >
                                                <i className='fa fa-chevron-circle-right'></i> &nbsp; Business Aggregator
                                            </div>
                                            {admin.rolelist.indexOf(ROLE.Generate_Buddy_Loan_Report) >= 0 ?

                                                <div style={{ marginTop: '15px' }}
                                                    onClick={() => pageViewHandler('branchData')}
                                                >
                                                    <i className='fa fa-chevron-circle-right'></i> &nbsp; Generate Branch Report
                                                </div>
                                                : ""}
                                            {admin.rolelist.indexOf(ROLE.Generate_Buddy_Loan_Report) >= 0 ?
                                                <div style={{ marginTop: '15px' }}
                                                    onClick={() => pageViewHandler('buddyLoanReport')}
                                                >
                                                    <i className='fa fa-chevron-circle-right'></i> &nbsp; Generate Buddyloan Report
                                                </div>
                                                : ""}
                                            {admin.rolelist != undefined ?
                                                admin.rolelist.indexOf(ROLE.MARKETING_TOOL) >= 0 ?
                                                    <div style={{ marginTop: '15px' }}
                                                        onClick={() => pageViewHandler('BIPage')}
                                                    >
                                                        <i className='fa fa-chevron-circle-right'></i> &nbsp;   Bi
                                                    </div>
                                                    : "" : ""}


                                        </div>
                                    </div>
                                </div>
                                : ""}

                            {admin.rolelist != undefined && admin.rolelist.length > 0 ? (admin.rolelist.indexOf(ROLE.ROLE_ASSIGN) >= 0 || admin.rolelist.indexOf(ROLE.LM_Assignment_Rule) >= 0 || admin.rolelist.indexOf(ROLE.Manual_Lead_Assignment) >= 0) ?

                                <div className='col-sm-3 col-xs-12'>
                                    <div className='landing-cards'>
                                        <div className='landing-head'><img style={{ width: '24px' }} src={AccessmanagementLogo} />&nbsp;Access Management</div>
                                        <div className='landing-inner'>

                                            {admin.rolelist != undefined && admin.rolelist.length > 0 ? admin.rolelist.indexOf(ROLE.ROLE_ASSIGN) >= 0 ?
                                                <div style={{ marginTop: '15px', cursor: 'pointer' }}

                                                    onClick={() => pageViewHandler('Roleassign')}
                                                >
                                                    <i className='fa fa-chevron-circle-right'></i> &nbsp;  Admin Roles
                                                </div>
                                                : "" : ""}
                                            {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                admin.rolelist.indexOf(ROLE.LM_Assignment_Rule) >= 0 || admin.rolelist.indexOf(ROLE.Manual_Lead_Assignment) >= 0 ?
                                                    <div style={{ marginTop: '15px', cursor: 'pointer' }}

                                                        onClick={() => pageViewHandler('Bulkassign')}
                                                    >
                                                        <i className='fa fa-chevron-circle-right'></i> &nbsp;  Application Assignment
                                                    </div>
                                                    : "" : ""}
                                            {/* {admin.rolelist.indexOf(ROLE.NBFC_CONFIGURATION) >= 0 ?
                                                <li
                                                    className='mail-list-item'
                                                    onClick={e => navItemClick('NBFCMANAGEMENT')}
                                                >
                                                    <span className='fa fa-bank' />
                                                    &nbsp;&nbsp;NBFC &nbsp;&nbsp;
                                                    <span className='caret' />
                                                    {menuOpenState == 'NBFCMANAGEMENT' ? (
                                                        <ul className='sub-list'>
                                                            <li
                                                                className='sub-list-item'
                                                                onClick={e => pageViewHandler('addNbfcNew')}
                                                            >
                                                                <span className='fa fa-hand-o-right' />
                                                                &nbsp;&nbsp;Add NBFC
                                                            </li>
                                                            <li
                                                                className='sub-list-item'
                                                                onClick={e => pageViewHandler('nbfcConfig')}
                                                            >
                                                                <span className='fa fa-hand-o-right' />
                                                                &nbsp;&nbsp;NBFC Configuration
                                                            </li>
                                                            <li
                                                                className='sub-list-item'
                                                                onClick={e => pageViewHandler('allnbfclead')}
                                                            >
                                                                <span className='fa fa-hand-o-right' />
                                                                &nbsp;&nbsp;All NBFC
                                                            </li>
                                                        </ul>
                                                    ) : (
                                                        ''
                                                    )}
                                                </li>
                                                : ""} */}
                                            {admin.rolelist.indexOf(ROLE.NBFC_CONFIGURATION) >= 0 ?
                                                <>
                                                    <div className='mail-list'>
                                                        <div
                                                            style={{ marginTop: '15px' }}
                                                            // onClick={()=> navItemClick('bulkOps')}
                                                            onClick={() => this.nbfcTabsHandle()}>
                                                            <i className='fa fa-chevron-circle-right'></i> &nbsp; NBFC {this.state.nbfcTabs ? <i className="fa fa-caret-up" aria-hidden="true" style={{ float: 'right', color: '#000' }}></i> : <i className="fa fa-caret-down" aria-hidden="true" style={{ float: 'right', color: '#000' }}></i>}
                                                        </div>
                                                    </div>
                                                    {this.state.nbfcTabs ?
                                                        <>
                                                            {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                                admin.rolelist.indexOf(ROLE.Add_Nbfc) >= 0 ?
                                                                    <li style={{ marginTop: '10px' }}
                                                                        onClick={() => pageViewHandler('addNbfcNew')}
                                                                    >
                                                                        Add NBFC
                                                                    </li>
                                                                    : "" : ""}
                                                                    {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                                admin.rolelist.indexOf(ROLE.Add_Nbfc) >= 0 ?
                                                                    <li style={{ marginTop: '10px' }}
                                                                        onClick={() => pageViewHandler('nbfcConfig')}
                                                                    >
                                                                         NBFC Config
                                                                    </li>
                                                                    : "" : ""}
                                                                    {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                                admin.rolelist.indexOf(ROLE.Add_Nbfc) >= 0 ?
                                                                    <li style={{ marginTop: '10px' }}
                                                                        onClick={() => pageViewHandler('allnbfclead')}
                                                                    >
                                                                        All NBFC
                                                                    </li>
                                                                    : "" : ""}
                                                                  
                                                        </>
                                                        : ""}
                                                </>
                                                : ""}


                                        </div>

                                    </div>
                                </div>
                                : "" : ""}
                            {admin.rolelist != undefined ? (admin.rolelist.indexOf(ROLE.Dashboard) >= 0 || admin.rolelist.indexOf(ROLE.Incoming_Payment_Report) >= 0 || admin.rolelist.indexOf(ROLE.DASHBOARD_REPORT_NEW) >= 0 || admin.rolelist.indexOf(ROLE.Generate_Report_Logs) >= 0 || admin.rolelist.indexOf(ROLE.INCOME_COLLECTED) >= 0 || admin.rolelist.indexOf(ROLE.Check_FlipKart_Balance) >= 0) ?
                                <div className='col-sm-3 col-xs-12'>
                                    <div className='landing-cards'>
                                        <div className='landing-head'><img style={{ width: '24px' }} src={DashboardLogo} />&nbsp;Dashboard & Reports</div>
                                        <div className='landing-inner'>
                                            {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.Dashboard) >= 0 ?
                                                <div style={{ marginTop: '15px', cursor: 'pointer' }}

                                                    onClick={e => pageViewHandler('dashboard')}
                                                >
                                                    <i className='fa fa-chevron-circle-right'></i> &nbsp; Dashboard
                                                </div>
                                                : "" : ""}
                                            {admin.rolelist != undefined ?
                                                admin.rolelist.indexOf(ROLE.Incoming_Payment_Report) >= 0 ?
                                                    <div style={{ marginTop: '15px', cursor: 'pointer' }}
                                                        onClick={e => pageViewHandler('IncomingPaymentReport')}
                                                    >
                                                        <i className='fa fa-chevron-circle-right'></i> &nbsp;  Incoming Payment Report
                                                    </div>
                                                    : "" : ""}
                                            {admin.rolelist != undefined ?
                                                admin.rolelist.indexOf(ROLE.DASHBOARD_REPORT_NEW) >= 0 ?
                                                    this.state.generateReportState == true ?
                                                        <div style={{ marginTop: '15px', cursor: 'pointer' }}
                                                            onClick={e => pageViewHandler('reportGenerateNew')}
                                                        >
                                                            <i className='fa fa-chevron-circle-right'></i> &nbsp;  Generate Report
                                                        </div>
                                                        : ""
                                                    : "" : ""}
                                            {admin.rolelist != undefined ?
                                                admin.rolelist.indexOf(ROLE.Generate_Report_Logs) >= 0 ?

                                                    <div style={{ marginTop: '15px', cursor: 'pointer' }}

                                                        onClick={e => pageViewHandler('GenReportLogs')}
                                                    >
                                                        <i className='fa fa-chevron-circle-right'></i> &nbsp;  Generate Report Logs
                                                    </div>
                                                    : "" : ""}
                                            {/* {admin.rolelist != undefined ?
                                                admin.rolelist.indexOf(ROLE.INCOME_COLLECTED) >= 0 ?
                                                    <div style={{ marginTop: '15px', cursor: 'pointer' }}

                                                        onClick={e => pageViewHandler('incomeCollected')}
                                                    >
                                                        <i className='fa fa-chevron-circle-right'></i> &nbsp;    Income Breakup
                                                    </div>
                                                    : "" : ""} */}
                                            {admin.rolelist != undefined ?
                                                admin.rolelist.indexOf(ROLE.Check_FlipKart_Balance) >= 0 ?
                                                    <div style={{ marginTop: '15px', cursor: 'pointer' }}

                                                        onClick={e => pageViewHandler('flipkartBalance')}
                                                    >
                                                        <i className='fa fa-chevron-circle-right'></i> &nbsp;  Check Flipkart Balance
                                                    </div>
                                                    : "" : ""}
                                            {admin.rolelist != undefined ?
                                                admin.rolelist.indexOf(ROLE.API_TRACKER) >= 0 ?
                                                    <div style={{ marginTop: '15px', cursor: 'pointer' }}

                                                        onClick={e => pageViewHandler('creditleads')}
                                                    >
                                                        <i className='fa fa-chevron-circle-right'></i> &nbsp; Credit Analysis Leads
                                                    </div>
                                                    : "" : ""}

                                            <div style={{ marginTop: '15px', cursor: 'pointer' }}

                                                onClick={e => pageViewHandler('automationLogs')}
                                            >
                                                <i className='fa fa-chevron-circle-right'></i> &nbsp; Automation Logs
                                            </div>
                                            {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                admin.rolelist.indexOf(ROLE.INCOME_COLLECTED) >= 0 ?
                                                    <div style={{ marginTop: '15px', cursor: 'pointer' }}

                                                        onClick={e => pageViewHandler('performanceTracker')}
                                                    >
                                                        <i className='fa fa-chevron-circle-right'></i> &nbsp; Performance Tracker
                                                    </div>
                                                    : "" : ""}

                                        </div>

                                    </div>
                                </div>
                                : "" : ""}
                            {admin.rolelist != undefined ?
                                (admin.rolelist.indexOf(ROLE.Loan_Emi_Calculator) >= 0 || admin.rolelist.indexOf(ROLE.Update_Web_Content) >= 0 || admin.rolelist.indexOf(ROLE.Loan_Emi_Calculator) >= 0 || admin.rolelist.indexOf(ROLE.Add_Priority_Company) >= 0 || admin.rolelist.indexOf(ROLE.Add_Pincode) >= 0 || admin.rolelist.indexOf(ROLE.Update_User_Bank_Details) >= 0 || admin.rolelist.indexOf(ROLE.Update_Cibil_Info) >= 0 || admin.rolelist.indexOf(ROLE.UPLOAD_STAMP_DUTY) >= 0 || admin.rolelist.indexOf(ROLE.BULK_STATUS_CHANGE) >= 0 || admin.rolelist.indexOf(ROLE.ALL_POLICY) >= 0) ?
                                    <div className='col-sm-3 col-xs-12'>
                                        <div className='landing-cards'>
                                            <div className='landing-head'><img style={{ width: '24px' }} src={ToolsLogo} />&nbsp;Tools & Utilities</div>
                                            <div className='landing-inner'>
                                                {admin.rolelist != undefined ?
                                                    admin.rolelist.indexOf(ROLE.Loan_Emi_Calculator) >= 0 ?
                                                        <div style={{ marginTop: '15px', cursor: 'pointer' }}
                                                            onClick={e => pageViewHandler('calcUtility')}
                                                        >
                                                            <i className='fa fa-chevron-circle-right'></i> &nbsp;Calculator
                                                        </div>
                                                        : "" : ""}
                                                {admin.rolelist != undefined ?
                                                    admin.rolelist.indexOf(ROLE.Update_Web_Content) >= 0 ?
                                                        <div style={{ marginTop: '15px', cursor: 'pointer' }}
                                                            onClick={e => pageViewHandler('webContentUtility')}
                                                        >
                                                            <i className='fa fa-chevron-circle-right'></i> &nbsp; Web Content
                                                        </div>
                                                        : "" : ""}
                                                {admin.rolelist != undefined ?
                                                    admin.rolelist.indexOf(ROLE.Loan_Emi_Calculator) >= 0 ?
                                                        <div style={{ marginTop: '15px', cursor: 'pointer' }}
                                                            onClick={e => pageViewHandler('emiCalculator')}
                                                        >
                                                            <i className='fa fa-chevron-circle-right'></i> &nbsp;EMI Calculator
                                                        </div>
                                                        : "" : ""}
                                                {admin.rolelist != undefined ?
                                                    admin.rolelist.indexOf(ROLE.Add_Priority_Company) >= 0 ?
                                                        <div style={{ marginTop: '15px', cursor: 'pointer' }}
                                                            onClick={e => pageViewHandler('priorityCompany')}
                                                        >
                                                            <i className='fa fa-chevron-circle-right'></i> &nbsp; Add Priority Company
                                                        </div>
                                                        : "" : ""}
                                                {admin.rolelist != undefined ?
                                                    admin.rolelist.indexOf(ROLE.Add_Pincode) >= 0 ?
                                                        <div style={{ marginTop: '15px', cursor: 'pointer' }}
                                                            onClick={e => pageViewHandler('addPincode')}
                                                        >
                                                            <i className='fa fa-chevron-circle-right'></i> &nbsp; Add Pincode
                                                        </div>
                                                        : "" : ""}
                                                {admin.rolelist != undefined ?
                                                    admin.rolelist.indexOf(ROLE.Update_User_Bank_Details) >= 0 ?
                                                        <div style={{ marginTop: '15px', cursor: 'pointer' }}
                                                            onClick={e => pageViewHandler('updateAccInfo')}
                                                        >
                                                            <i className='fa fa-chevron-circle-right'></i> &nbsp;Update Account Info
                                                        </div>
                                                        : "" : ""}
                                                {admin.rolelist != undefined ?
                                                    admin.rolelist.indexOf(ROLE.Update_Cibil_Info) >= 0 ?
                                                        <div style={{ marginTop: '15px', cursor: 'pointer' }}
                                                            onClick={e => pageViewHandler('updateCibilInfo')}
                                                        >
                                                            <i className='fa fa-chevron-circle-right'></i> &nbsp; Update Cibil Info
                                                        </div>
                                                        : "" : ""}
                                                {admin.rolelist != undefined ?
                                                    admin.rolelist.indexOf(ROLE.UPLOAD_STAMP_DUTY) >= 0 ?
                                                        <div style={{ marginTop: '15px', cursor: 'pointer' }}
                                                            onClick={e => pageViewHandler('uploadStamDuty')}
                                                        >
                                                            <i className='fa fa-chevron-circle-right'></i> &nbsp; Upload Stamp Duty
                                                        </div>
                                                        : "" : ""}
                                                {admin.rolelist != undefined ?
                                                    admin.rolelist.indexOf(ROLE.BULK_STATUS_CHANGE) >= 0 ?
                                                        <div style={{ marginTop: '15px', cursor: 'pointer' }}
                                                            onClick={e => pageViewHandler('bulkStatusChange')}
                                                        >
                                                            <i className='fa fa-chevron-circle-right'></i> &nbsp; Bulk Status Update
                                                        </div>
                                                        : "" : ""}
                                                {admin.rolelist != undefined ?
                                                    admin.rolelist.indexOf(ROLE.ALL_POLICY) >= 0 ?
                                                        <div style={{ marginTop: '15px', cursor: 'pointer' }}
                                                            onClick={e => pageViewHandler('allPolicy')}
                                                        >
                                                            <i className='fa fa-chevron-circle-right'></i> &nbsp; ALL Policy
                                                        </div>
                                                        : "" : ""}
                                                {/* {admin.emailId === 'SuperUser' ?
                                                    <div style={{ marginTop: '15px', cursor: 'pointer' }}
                                                        onClick={e => pageViewHandler('deleteToken')}
                                                    >
                                                        <i className='fa fa-chevron-circle-right'></i> &nbsp; Delete Token
                                                    </div>
                                                    : ""} */}
                                                {/* {admin.rolelist != undefined ?
                                                    admin.rolelist.indexOf(ROLE.ALL_POLICY) >= 0 ? */}
                                                {admin.rolelist != undefined ?
                                                    admin.rolelist.indexOf(ROLE.ALL_POLICY) >= 0 ?
                                                        <div style={{ marginTop: '15px', cursor: 'pointer' }}
                                                            onClick={e => pageViewHandler('scoringConfig')}
                                                        >
                                                            <i className='fa fa-chevron-circle-right'></i> &nbsp; Scoring Config
                                                        </div>
                                                        : "" : ""}
                                                {admin.rolelist != undefined ?
                                                    admin.rolelist.indexOf(ROLE.Update_User_Bank_Details) >= 0 ?
                                                        <div style={{ marginTop: '15px', cursor: 'pointer' }}
                                                            onClick={e => pageViewHandler('addLoanId')}
                                                        >
                                                            <i className='fa fa-chevron-circle-right'></i> &nbsp;Add Loan ID's
                                                        </div>
                                                        : "" : ""}
                                                {admin.emailId && admin.emailId === 'SuperUser' ?
                                                    <div style={{ marginTop: '15px', cursor: 'pointer' }}
                                                        onClick={e => pageViewHandler('kycHandle')}
                                                    >
                                                        <i className='fa fa-chevron-circle-right'></i> &nbsp;Kyc Start Stop
                                                    </div>
                                                    : ""}
                                                {/* // : "" : ""} */}
                                            </div>

                                        </div>
                                    </div>
                                    : "" : ""
                            }
                            {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                (admin.rolelist.indexOf(ROLE.COLLECTION_SECTION) >= 0) ?
                                    <div className='col-sm-3 col-xs-12'>
                                        <div className='landing-cards'>
                                            <div className='landing-head'><img style={{ width: '24px' }} src={FinanceLogo} />&nbsp;Collection</div>
                                            <div className='landing-inner'>
                                                {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                    admin.rolelist.indexOf(ROLE.COLLECTION_SECTION) >= 0 ?
                                                        <div
                                                            style={{ marginTop: '15px' }}
                                                            onClick={() => pageViewHandler('recoveryLogs')}
                                                        >
                                                            <i className='fa fa-chevron-circle-right'></i> &nbsp; Recovery Logs
                                                        </div>
                                                        : "" : ""}



                                            </div>
                                        </div>

                                    </div>
                                    : "" : ""}
                        </div>
                        {/* <div className='row'>
                <div className='col-sm-3 col-xs-12'>
                    <div className='landing-cards' style={{ marginBottom: '20px' }}>
                        <div className='landing-head'><img style={{ width: '24px' }} src={NbfcLogo} />&nbsp;NBFC Management</div>

                    </div>
                </div>

            </div> */}


                    </div>
                    : ""}
            </>
        )
    }
}
const mapStateToProps = ({ admin }) => {
    return {

        // admin: admin,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({

    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Landingpage);