import React, { Component } from 'react'
import UserDocs from '../../../presentationals/Admin/CustomerPages/UserDocs.component'
import { docUploadSaveMail } from '../AdminActionCreator.component'
import Popup from '../../../presentationals/Popup/Popup.component'
import ConfirmationPopup from '../../../presentationals/Popup/markcompletePopup'
import { APIS } from '../../../../utils/api-factory'
import Loader from '../../../presentationals/Loader/DotLoader.component'

class DocsUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      popupState: false,
      popupStatus: '',
      confirmationPopupState: false,
      confirmationText: '',
      documentsData: '',
      loaderState: false,
      docViewData: '',
      urlName: '',
      typeofDocument: '',
      adminDocument: false,
      automationDocument: false,
      completeType: '',
      invalidMsg: ''

    }
  }
  sendToFinb() {
    const { allDetail } = this.props
    this.setState({loaderState:true})
    fetch(APIS.UPLOAD_STATEMENT_TO_FINBIT + allDetail.userId)
      .then(res => res.text())
      .then(res => {
        if (res === 'success') {
          this.setState({
            popupState: true,
            popupStatus: 'Passed Successfully',
            loaderState:false
          })
          this.removePopup()
        } else {
          this.setState({
            popupState: true,
            popupStatus: 'Please try again later!',
            loaderState:false
          })
          this.removePopup()
        }

      })
  }
  sendToDigitap() {
    const { allDetail } = this.props
    this.setState({loaderState:true})
    fetch(APIS.UPLOAD_STATEMENT_FOR_PARSING_DIGITAP + allDetail.userId)
      .then(res => res.text())
      .then(res => {
        if (res === 'success') {
          this.setState({
            popupState: true,
            popupStatus: 'Passed Successfully',
            loaderState:false
          })
          this.removePopup()
        } else {
          this.setState({
            popupState: true,
            popupStatus: 'Please try again later!',
            loaderState:false
          })
          this.removePopup()
        }

      })
  }
  componentDidMount() {
    const { allDetail } = this.props
    this.setState({ loaderState: true })
    //////Api after gift card//////////////
    fetch(APIS.GET_USER_DOCUMENTS_BY_USER_ID + allDetail.userId)
      .then(res => res.json())
      .then(res => {
        this.setState({ documentsData: res, loaderState: false })
      })
  }
  bucketView(e) {
    if (e.target.value == 'KYC') {
      this.setState({ typeofDocument: 'KYC' })
    }
    if (e.target.value == 'BANKSTATEMENT') {
      this.setState({ typeofDocument: 'BANKSTATEMENT' })
    }
    if (e.target.value == 'SALARY') {
      this.setState({ typeofDocument: 'SALARY' })
    }
    if (e.target.value == 'NOC') {
      this.setState({ typeofDocument: 'NOC' })
    }

  }
  openAdminDocuments() {
    this.setState({ adminDocument: true })
  }
  closeAdminDoc() {
    this.setState({ adminDocument: false })
  }
  openautomationDocuments() {
    this.setState({ automationDocument: true })
  }
  closeautomationDoc() {
    this.setState({ automationDocument: false })
  }

  ViewDocument(e) {
    if (e.target.value == 'photographUrl') {
      this.setState({ docViewData: this.state.documentsData.documentList.photographUrl, urlName: 'Photo' })
    }
    if (e.target.value == 'salaryProofUrl') {
      this.setState({ docViewData: this.state.documentsData.documentList.salaryProofUrl, urlName: 'Salary Proof' })
    }
    if (e.target.value == 'panCardUrl') {

    }
    if (e.target.value == 'bankStatementUrl') {

    }

  }
  render() {
    const { allDetail, admin, markCompleteBtn,userDetail } = this.props
    return (
      <div>
        {this.state.loaderState ? <Loader /> : ""}
        {this.state.popupState == true ? (
          <Popup
            closePopup={this.closePopup.bind(this)}
            popupStatus={this.state.popupStatus}
          />
        ) : null}
        <UserDocs
        sendToDigitap={this.sendToDigitap.bind(this)}
        userDetail={userDetail}
          sendToFinb={this.sendToFinb.bind(this)}
          closeautomationDoc={this.closeautomationDoc.bind(this)}
          openautomationDocuments={this.openautomationDocuments.bind(this)}
          automationDocument={this.state.automationDocument}
          closeAdminDoc={this.closeAdminDoc.bind(this)}
          openAdminDocuments={this.openAdminDocuments.bind(this)}
          adminDocument={this.state.adminDocument}
          typeofDocument={this.state.typeofDocument}
          bucketView={this.bucketView.bind(this)}
          urlName={this.state.urlName}
          ViewDocument={this.ViewDocument.bind(this)}
          docViewData={this.state.docViewData}
          loaderState={this.state.loaderState}
          allDetail={this.state.documentsData}
          admin={admin}
          openAllDocs={this.openAllDocs.bind(this)}
          saveDocName={this.saveDocName.bind(this)}
          markCompleteBtn={markCompleteBtn}
          markAsComplete={this.markAsComplete.bind(this)}
        />

        {this.state.confirmationPopupState == true ? (
          <ConfirmationPopup
            completeHandler={this.completeHandler.bind(this)}
            invalidMsg={this.state.invalidMsg}
            cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
            confirmationText={this.state.confirmationText}
          />
        ) : null}
      </div>
    )
  }
  completeHandler(e) {
    this.setState({ completeType: e.target.value })
  }

  markAsComplete() {
    this.setState({
      confirmationPopupState: true,
      confirmationText: 'Are you sure to complete this application?'
    })
  }

  cancelLoanConfirm(buttonType) {
    const { allDetail, admin } = this.props
    if (buttonType == 'yes') {
      if (this.state.completeType !== '') {
        this.setState({ invalidMsg: '' })
        fetch(
          APIS.MARK_APPLICATION_COMPLETE +
          allDetail.userId +
          '&loanId=' +
          allDetail.loanId
          + '&completeType=' + this.state.completeType
          + '&empId=' + admin.emailId
        )
          .then(res => res.text())
          .then(res => {
            if (res == 'success') {
              this.setState({
                popupState: true,
                popupStatus: 'Marked Successfully',
                confirmationPopupState: false
              })
              this.removePopup()
            } else {
              this.setState({
                popupState: true,
                popupStatus: 'Please try again',
                confirmationPopupState: false
              })
              this.removePopup()
            }
          })
      } else {
        this.setState({
          invalidMsg: 'Please Select Complete Type First'
        })
        this.removePopup()
      }
    } else if (buttonType == 'no') {
      this.setState({
        confirmationPopupState: false
      })
    }
  }

  removePopup() {
    setTimeout(
      function () {
        this.setState({ popupState: false })
      }.bind(this),
      5000
    )
  }

  closePopup() {
    this.setState({ popupState: false })
  }

  saveDocName(e, url) {
    const { allDetail } = this.props
    docUploadSaveMail(e.target.value, url, allDetail.userId, callBack => {
      if (callBack == 'success') {
        this.setState({
          popupState: true,
          popupStatus: 'Saved Successfully'
        })
        this.removePopup()
      }
    })
  }

  openAllDocs() {
    const { allDetail } = this.props
    if (this.state.documentsData != null && this.state.documentsData.documentList != null) {
      Object.keys(this.state.documentsData.documentList).map(head => {
        if (
          typeof this.state.documentsData.documentList[head] === 'string' &&
          this.state.documentsData.documentList[head].substring(0, 4) === 'http'
        ) {
          window.open(

            this.state.documentsData.documentList[head].includes('//phocket.co.in') ? this.state.documentsData.documentList[head].replace(
              '//phocket.co.in',
              '//adminsupport.phocket.co.in'
            ) : this.state.documentsData.documentList[head].replace(
              '//admin.phocket.co.in',
              '//adminsupport.phocket.co.in'
            )
          )
        }
      })
    }
  }
}

export default DocsUser
