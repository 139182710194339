import React, { useEffect, useState } from "react";
import ProfileLogo from '../../../../images/profile.png'
import Vector from '../../../../images/reports.png'
import SalaryImage from '../../../../images/money.png'
import CreditImage from '../../../../images/Layer 11.png'
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { getConcludeDataApi, getDashboardVerifyData } from "./analysisactionCreator";
import Loader from '../../../presentationals/Loader/layerLoader.component'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useReducer } from "react";
import apiReducer from "./apireducer";
import { APIS } from "../../../../utils/api-factory";
import axios from "axios";
import GifLoader from '../../../../images/1488.gif'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Scores from '../../../containers/Admin/CustomerPages/ScoringDetailsviewMain'
import TentativeApproval from "../CustomerPages/tentativeApproval";

const COLORS = ['#95C90A'];
const DashboarUi = (props) => {
    const [tentative, settentative] = useState(false)
    const [mobileNumber, setMobileNumber] = useState('')

    const [verifyData, setVerifyData] = useState(props.creditDashBoardDataValue)
    const [data, setData] = useState({})
    const [concludedData, setConcludedData] = useState('')
    const [loaderState, setLoader] = useState(false)
    const initialState = {
        data: [],
        loading: true,
        error: null,
    };

    const [state, dispatch] = useReducer(apiReducer, initialState);



    useEffect(() => {
        if (props.creditDashBoardDataValue.userId === props.userId) {

            let d = [
                { name: 'Group A', value: Number(props.creditDashBoardDataValue.creditScore) },
                { name: 'Group B', value: 900 - props.creditDashBoardDataValue.creditScore },
            ]
            setData(d)
        } else {
            setLoader(true)
            props.getDashboardVerifyData(props.userId, props.loanId, callback => {
                setVerifyData(callback)
                setLoader(false)
                let d = [
                    { name: 'Group A', value: Number(callback.creditScore) },
                    { name: 'Group B', value: 900 - callback.creditScore },
                ]
                setData(d)
            })
        }
        let Data = maskMobileNumber(props.allDetail.mobileNumber)
        setMobileNumber(Data)
    }, [])

    const maskMobileNumber = (mobileNumber) => {
        // Convert the mobile number to a string
        const mobileString = mobileNumber.toString();

        if (/^\d{10}$/.test(mobileString)) {
            const lastThreeDigits = mobileString.slice(-3);
            const maskedMobile = 'xx-xx-xx-x' + lastThreeDigits;
            return maskedMobile;
        } else {
            return mobileString; // Return the input as is if it's not a valid 10-digit number
        }
    }

    const getConcludeData = () => {
        getConcludeDataApi(props.userId, callback => {

            setConcludedData(callback)
        })
    }

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const RADIAN = Math.PI / 180;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={cx} y={cy} fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
                <tspan fontSize="32">{(data[0].value)}</tspan>
            </text>
        );
    };
    const renderCustomizedLabelAssesment = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const RADIAN = Math.PI / 180;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={cx} y={cy} fill="#006FBC" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
                <tspan fontSize="32">{(data[0].value)}</tspan>
            </text>
        );
    };
    return (



        <>

            {loaderState === false && verifyData !== undefined ?
                <div className="container-fluid credit-dashboard">

                    <div className="row">
                        <div className="col-sm-9 col-xs-12">
                            <div className="credit-cards">
                                <h4><strong><img src={ProfileLogo} width='25px' />&nbsp;&nbsp;KYC Analysis</strong></h4>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className={"pan-div"}>
                                            <div className="" style={{ fontWeight: '700', fontSize: '12px' }}>PAN <br />Verification</div>
                                            <div className={'pan-circle'} style={{ float: 'right', marginTop: '-14px' }}>
                                                <i class="fa fa-id-card-o" aria-hidden="true"></i>
                                            </div>

                                            <div style={{ marginTop: '22px' }}> {verifyData && verifyData.panMatched ? <span style={{ color: '#fff', background: '#4CC78C', borderRadius: '4px', padding: '5px', fontSize: '10px' }}>Verified By Karza</span> : <span style={{ color: '#fff', background: '#FF5C5D', borderRadius: '4px', padding: '5px', fontSize: '10px', width: '100px' }}>Unverified By Karza</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className={"pan-div"} style={{ background: '#FBEDE7' }}>

                                            <div className="" style={{ fontWeight: '700', fontSize: '12px' }}>Aadhaar<br /> Verification</div>

                                            <div className={'pan-circle'} style={{ background: '#FF772B', padding: '6px 10px', float: 'right', marginTop: '-14px' }}>
                                                <i class="fa fa-id-badge" aria-hidden="true"></i>
                                            </div>

                                            <div style={{ marginTop: '22px' }}> {verifyData && verifyData.aadhaarMatch ? <span style={{ color: '#fff', background: '#4CC78C', borderRadius: '4px', padding: '5px', fontSize: '10px' }}>Verified By Karza</span> : <span style={{ color: '#fff', background: '#FF5C5D', borderRadius: '4px', padding: '5px', fontSize: '10px', width: '100px' }}>Unverified By Karza</span>}
                                            </div>

                                        </div>

                                    </div>
                                    <div className="col-sm-4">
                                        <div className={"pan-div"} style={{ background: '#EEEAFF' }}>

                                            <div className="" style={{ fontWeight: '700', fontSize: '12px' }}>Location <br />Verification</div>

                                            <div className={'pan-circle'} style={{ background: '#7D6FD9', padding: '6px 11px', float: 'right', marginTop: '-14px' }}>
                                                <i class="fa fa-map-marker" aria-hidden="true"></i>
                                            </div>

                                            <div style={{ marginTop: '22px' }}> {verifyData && verifyData.addressMatch ? <span style={{ color: '#fff', background: '#4CC78C', borderRadius: '4px', padding: '5px', fontSize: '10px' }}>Matches with Aadhaar/ CRIF</span> : <span style={{ color: '#fff', background: '#FF5C5D', borderRadius: '4px', padding: '5px', fontSize: '10px', width: '100px' }}>No Match found</span>}
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="credit-cards">
                                <h4><strong><img src={Vector} width='25px' />&nbsp;&nbsp;Credit Analysis</strong></h4>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <div className={"pan-div"}>
                                            {/* <div className="row"> */}
                                            {/* <div className="col-sm-8 "> */}
                                            <div className="" style={{ fontWeight: '700', fontSize: '12px' }}>SMS<br /> Analysis</div>

                                            {/* </div>
                                                <div className="col-sm-4 "> */}
                                            <div className={'pan-circle'} style={{ background: '#7D6FD9', float: 'right', marginTop: '-25px' }}>
                                                <i class="fa fa-comments" aria-hidden="true"></i>
                                            </div>

                                            <div style={{ marginTop: '10px' }}> {verifyData && verifyData.smsAnalysis ? <span style={{ color: '#fff', background: '#4CC78C', borderRadius: '4px', padding: '5px 3px', fontSize: '9px' }}>Overdue/Legal SMS Not Found</span> : <span style={{ color: '#fff', background: '#FF5C5D', borderRadius: '4px', padding: '4px', fontSize: '10px', width: '100px' }}>Overdue/Legal SMS Found </span>}
                                            </div>
                                        </div>


                                    </div>
                                    <div className="col-sm-3 ">
                                        <div className={"pan-div"} style={{ background: '#FBEDE7' }}>
                                            {/* <div className="row">
                                                <div className="col-sm-8 "> */}
                                            <div className="" style={{ fontWeight: '700', fontSize: '12px' }}>Internal<br /> Dedupe</div>

                                            {/* </div>
                                                <div className="col-sm-4 "> */}
                                            <div className={'pan-circle'} style={{ background: '#FF772B', float: 'right', marginTop: '-25px' }}>
                                                <i class="fa fa-clone" aria-hidden="true"></i>
                                            </div>

                                            <div style={{ marginTop: '10px' }}> {verifyData && verifyData.similarProfile ? <span style={{ color: '#fff', background: '#4CC78C', borderRadius: '4px', padding: '5px', marginRight: '5px', fontSize: '10px' }}>Not Found</span> : <span style={{ color: '#fff', background: '#FF5C5D', borderRadius: '4px', padding: '4px', fontSize: '10px', width: '100px' }}>Found </span>}
                                            </div>
                                        </div>


                                    </div>
                                    <div className="col-sm-3 ">
                                        <div className={"pan-div"} style={{ background: '#EEEAFF' }}>
                                            <div className="" style={{ fontWeight: '700', fontSize: '12px' }}>Bank<br /> Statement</div>

                                            <div className={'pan-circle'} style={{ background: '#7D6FD9', float: 'right', marginTop: '-25px' }}>
                                                <i class="fa fa-file-text-o" aria-hidden="true"></i>
                                            </div>

                                            <div style={{ marginTop: '10px' }}>  {verifyData && verifyData.bankStatement ? <span style={{ color: '#fff', background: '#4CC78C', borderRadius: '4px', padding: '5px 1px', fontSize: '8px' }}>Suspicious/Obligation Not Found</span> : <span style={{ color: '#fff', background: '#FF5C5D', borderRadius: '4px', padding: '5px 2px', fontSize: '9px', width: '100px' }}>Suspicious/ Obligation Found</span>}
                                            </div>

                                        </div>

                                    </div>
                                    <div className="col-sm-3">
                                        <div className={"pan-div"} style={{ background: '#FFF8E1' }}>

                                            <div className="" style={{ fontWeight: '700', fontSize: '12px' }}>CRIF<br /> Analysis</div>

                                            <div className={'pan-circle'} style={{ background: '#FFC107', float: 'right', marginTop: '-25px' }}>
                                                <i class="fa fa-tachometer" aria-hidden="true"></i>
                                            </div>

                                            <div style={{ marginTop: '10px' }}> {verifyData && verifyData.crifAnalysis ? <span style={{ color: '#fff', background: '#4CC78C', borderRadius: '4px', padding: '5px', marginRight: '5px', fontSize: '10px' }}>Approved</span> : <span style={{ color: '#fff', background: '#FF5C5D', borderRadius: '4px', padding: '5px', fontSize: '10px', width: '100px' }}>Rejected</span>}
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 col-xs-12">
                            <div className="credit-cards " style={{ overflow: 'auto', minHeight: '330px' }} >
                                <h4><strong><img src={SalaryImage} width='20px' />
                                    &nbsp;&nbsp;salary</strong></h4>

                                <div className="salary-div">

                                    <div className="text-right" style={{ fontSize: '12px' }}>From SMS</div>
                                    <div className="blue-dot-small "><i className="fa fa-rupee"></i></div>

                                    <h4 className="text-right" style={{ marginTop: '-3px' }}><strong>  {verifyData ? Math.round(verifyData.msgSalary) : ''}</strong></h4>

                                </div>


                                <div className="salary-div">
                                    <div className="text-right" style={{ fontSize: '12px' }}>From Fibit</div>
                                    <div className="blue-dot-small "><i className="fa fa-rupee"></i></div>

                                    <h4 className="text-right" style={{ marginTop: '-3px' }}><strong>  {verifyData ? Math.round(verifyData.finBitSalary) : ""}</strong></h4>

                                </div>

                                <div className="salary-div">
                                    <div className="text-right" style={{ fontSize: '12px' }}> User Input</div>

                                    <div className="blue-dot-small "><i className="fa fa-rupee"></i></div>
                                    <h4 className="text-right" style={{ marginTop: '-3px' }}><strong>  {verifyData ? verifyData.userSalary : ""}</strong></h4>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="credit-cards" style={{ marginBottom: '20px', minHeight: '100px' }}>
                        <div className="row">
                            <h4 style={{ marginLeft: '20px' }}><b><img src={ProfileLogo} width='25px' />&nbsp;&nbsp;KYC Match Result</b></h4>
                            <div className="col-sm-12 col-xs-12 threshhold-table">
                                <table className="">
                                    <tr>
                                        <th></th>
                                        <th>Name<br /><div style={{ fontSize: '10px' }}> (Threshhold (65%))</div></th>
                                        <th>DOB</th>
                                        <th>Mobile Number<br /><div style={{ fontSize: '10px' }}> (Last 3 Digits)</div></th>
                                        <th>Address</th>
                                    </tr>
                                    <tr>
                                        <td><b>Input</b></td>
                                        <td>{props.allDetail.firstName}</td>
                                        <td>{props.allDetail.dateOfBirth}</td>
                                        <td>{mobileNumber}</td>
                                        <td>{props && props.personalData && props.personalData.userInputAddress.length > 0 && props.personalData.userInputAddress[0] !== undefined ?
                                            <>{props.personalData.userInputAddress[0].address} <br /> {props.personalData.userInputAddress[0].city + ',' + props.personalData.userInputAddress[0].state + ',' + props.personalData.userInputAddress[0].pincode}</>
                                            : ""}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Aadhaar</b></td>
                                        <td>{props && props.personalData && props.personalData.aadharNameMatch !== undefined ? (props.personalData.aadharNameMatch ? <i className="fa fa-check green-fa-icon" style={{ color: 'green' }}></i> : <i className="fa fa-times red-fa-icon"></i>) : ""}&nbsp;{props && props.personalData && props.personalData.aadharNameMatchPerc !== undefined ? (props.personalData.aadharNameMatchPerc).toFixed(2) + '%' : ""}</td>
                                        <td>{props && props.personalData && props.personalData.aadharNameMatch !== undefined ? (props.personalData.aadharDobMatch ? <i className="fa fa-check green-fa-icon" style={{ color: 'green' }}></i> : <i className="fa fa-times red-fa-icon"></i>) : ""}</td>
                                        <td>{props && props.personalData && props.personalData.mobileMatch !== undefined ? (props.personalData.mobileMatch ? <i className="fa fa-check green-fa-icon" style={{ color: 'green' }}></i> : <i className="fa fa-times red-fa-icon"></i>) : ""}</td>
                                        <td>{props && props.personalData && props.personalData.aadharAddressMatch !== undefined ? (props.personalData.aadharAddressMatch).toFixed(2) + '%' : ""}</td>
                                    </tr>
                                    <tr>
                                        <td><b>PAN</b></td>
                                        <td>{props && props.personalData && props.personalData.panNameMatch !== undefined ? (props.personalData.panNameMatch ? <i className="fa fa-check green-fa-icon" style={{ color: 'green' }}></i> : <i className="fa fa-times red-fa-icon"></i>) : ""}&nbsp;{props && props.personalData && props.personalData.panNameMatchPerc !== undefined ? (props.personalData.panNameMatchPerc).toFixed(2) + '%' : ""}</td>
                                        <td>{props && props.personalData && props.personalData.panDobMatch !== undefined ? (props.personalData.panDobMatch ? <i className="fa fa-check green-fa-icon" style={{ color: 'green' }}></i> : <i className="fa fa-times red-fa-icon"></i>) : ""}</td>
                                        <td>N/A</td>
                                        <td>N/A</td>
                                    </tr>


                                </table>
                            </div>
                        </div>

                    </div>

                    <div className="credit-cards" style={{ marginBottom: '20px', minHeight: '100px' }}>
                        <div className="row">
                            <h4 style={{ marginLeft: '20px' }}><b><i className="fa fa-map"></i>&nbsp;&nbsp;Geo Tagging</b></h4>
                            <div className="col-sm-12 col-xs-12 threshhold-table">

                                <table className="">
                                    <tr>
                                        <th>Crif to Current Address Match %</th>
                                        <th>Live Location to Current Address Pincode Match</th>

                                    </tr>
                                    <tr>
                                        <td>{props && props.personalData && props.personalData.crifAddressMatchPerc && props.personalData.crifAddressMatchPerc.toFixed(2) + '%'}</td>
                                        <td>{props && props.personalData && props.personalData.locationPincodeFlag ? <i className="fa fa-check green-fa-icon" style={{ color: 'green' }}></i> : <i className="fa fa-times red-fa-icon"></i>}</td>
                                    </tr>
                                </table>
                            </div>

                        </div>

                    </div>
                    <div className="credit-cards" style={{ marginBottom: '20px', minHeight: '300px' }}>
                        <div className="row">
                            <div className="col-sm-9 col-xs-9">
                                <h4><strong><img src={CreditImage} width='25px' />&nbsp;&nbsp;Credit Score</strong></h4>
                            </div>
                            <div className="col-sm-3 col-xs-3">

                                <div className="text-right"><button className="btn btn-success" onClick={() => settentative(true)}>Tentative Approve</button></div>
                            </div>
                        </div>
                        <Scores admin={props} allDetail={props} />
                    </div>
                </div >
                :
                <div className="container-fluid credit-dashboard">

                    <div className="row">
                        <div className="col-sm-9 col-xs-12">
                            <div className="credit-cards">
                                <h4><strong><img src={ProfileLogo} width='25px' />&nbsp;&nbsp;KYC Analysis</strong></h4>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className={"pan-div"}>
                                            <SkeletonTheme baseColor="transparent" highlightColor="#85a6e1">
                                                <Skeleton height={40} /></SkeletonTheme>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className={"pan-div"} style={{ background: '#FBEDE7' }}>
                                            <SkeletonTheme baseColor="transparent" highlightColor="#dba38b">
                                                <Skeleton height={40} /></SkeletonTheme>
                                        </div>

                                    </div>
                                    <div className="col-sm-4">
                                        <div className={"pan-div"} style={{ background: '#EEEAFF' }}>
                                            <SkeletonTheme baseColor="transparent" highlightColor="#afa2f4">
                                                <Skeleton height={40} /></SkeletonTheme>
                                        </div>

                                    </div>
                                </div>
                            </div>


                            <div className="credit-cards">
                                <h4><strong><img src={Vector} width='25px' />&nbsp;&nbsp;Credit Analysis</strong></h4>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <div className={"pan-div"}>
                                            <SkeletonTheme baseColor="transparent" highlightColor="#85a6e1">
                                                <Skeleton height={40} /></SkeletonTheme>
                                        </div>

                                    </div>
                                    <div className="col-sm-3 ">
                                        <div className={"pan-div"} style={{ background: '#FBEDE7' }}>
                                            <SkeletonTheme baseColor="transparent" highlightColor="#dba38b">
                                                <Skeleton height={40} /></SkeletonTheme>
                                        </div>

                                    </div>
                                    <div className="col-sm-3 ">
                                        <div className={"pan-div"} style={{ background: '#EEEAFF' }}>
                                            <SkeletonTheme baseColor="transparent" highlightColor="#afa2f4">
                                                <Skeleton height={40} /></SkeletonTheme>
                                        </div>

                                    </div>
                                    <div className="col-sm-3">
                                        <div className={"pan-div"} style={{ background: '#FFF8E1' }}>
                                            <SkeletonTheme baseColor="transparent" highlightColor="#f0dfa5">
                                                <Skeleton height={40} /></SkeletonTheme>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 col-xs-12">
                            <div className="credit-cards middle-card-height" style={{ overflow: 'auto', maxHeight: '310px' }} >
                                <h4><strong><img src={SalaryImage} width='20px' />
                                    &nbsp;&nbsp;salary</strong></h4>

                                <div className="salary-div">

                                    <Skeleton height={45} />

                                </div>


                                <div className="salary-div">
                                    <Skeleton height={45} />
                                </div>

                                <div className="salary-div">
                                    <Skeleton height={45} />

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="credit-cards" style={{ marginBottom: '20px', minHeight: '100px' }}>
                        <div className="row">
                            <h4 style={{ marginLeft: '20px' }}><b><img src={ProfileLogo} width='25px' />&nbsp;&nbsp;KYC Match Result</b></h4>
                            <div className="col-sm-12 col-xs-12 threshhold-table">
                                <table className="">
                                    <tr>
                                        <th></th>
                                        <th>Name<br /><div style={{ fontSize: '10px' }}> (Threshhold (65%))</div></th>
                                        <th>DOB</th>
                                        <th>Mobile Number<br /><div style={{ fontSize: '10px' }}> (Last 3 Digits)</div></th>
                                        <th>Address</th>
                                    </tr>
                                    <tr>
                                        <td><b>Input</b></td>
                                        <td>{props.allDetail.firstName}</td>
                                        <td>{props.allDetail.dateOfBirth}</td>
                                        <td>{mobileNumber}</td>
                                        <td>{props && props.personalData && props.personalData.userInputAddress.length > 0 && props.personalData.userInputAddress[0] !== undefined ?
                                            <>{props.personalData.userInputAddress[0].address} <br /> {props.personalData.userInputAddress[0].city + ',' + props.personalData.userInputAddress[0].state + ',' + props.personalData.userInputAddress[0].pincode}</>
                                            : ""}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Aadhaar</b></td>
                                        <td>{props && props.personalData && props.personalData.aadharNameMatch !== undefined ? (props.personalData.aadharNameMatch ? <i className="fa fa-check green-fa-icon" style={{ color: 'green' }}></i> : <i className="fa fa-times red-fa-icon"></i>) : ""}&nbsp;{props && props.personalData && props.personalData.aadharNameMatchPerc !== undefined ? (props.personalData.aadharNameMatchPerc).toFixed(2) + '%' : ""}</td>
                                        <td>{props && props.personalData && props.personalData.aadharNameMatch !== undefined ? (props.personalData.aadharDobMatch ? <i className="fa fa-check green-fa-icon" style={{ color: 'green' }}></i> : <i className="fa fa-times red-fa-icon"></i>) : ""}</td>
                                        <td>{props && props.personalData && props.personalData.mobileMatch !== undefined ? (props.personalData.mobileMatch ? <i className="fa fa-check green-fa-icon" style={{ color: 'green' }}></i> : <i className="fa fa-times red-fa-icon"></i>) : ""}</td>
                                        <td>{props && props.personalData && props.personalData.aadharAddressMatch !== undefined ? (props.personalData.aadharAddressMatch).toFixed(2) + '%' : ""}</td>
                                    </tr>
                                    <tr>
                                        <td><b>PAN</b></td>
                                        <td>{props && props.personalData && props.personalData.panNameMatch !== undefined ? (props.personalData.panNameMatch ? <i className="fa fa-check green-fa-icon" style={{ color: 'green' }}></i> : <i className="fa fa-times red-fa-icon"></i>) : ""}&nbsp;{props && props.personalData && props.personalData.panNameMatchPerc !== undefined ? (props.personalData.panNameMatchPerc).toFixed(2) + '%' : ""}</td>
                                        <td>{props && props.personalData && props.personalData.panDobMatch !== undefined ? (props.personalData.panDobMatch ? <i className="fa fa-check green-fa-icon" style={{ color: 'green' }}></i> : <i className="fa fa-times red-fa-icon"></i>) : ""}</td>
                                        <td>N/A</td>
                                        <td>N/A</td>
                                    </tr>

                                </table>
                            </div>
                        </div>

                    </div>
                    <div className="credit-cards" style={{ marginBottom: '20px', minHeight: '100px' }}>
                        <div className="row">
                            <h4 style={{ marginLeft: '20px' }}><b><i className="fa fa-map"></i>&nbsp;&nbsp;Geo Tagging</b></h4>
                            <div className="col-sm-12 col-xs-12 threshhold-table">

                                <table className="">
                                    <tr>
                                        <th>Crif to Current Address Match %</th>
                                        <th>Live Location to Current Address Pincode Match</th>

                                    </tr>
                                    <tr>
                                        <td>{props && props.personalData && props.personalData.crifAddressMatchPerc && props.personalData.crifAddressMatchPerc.toFixed(2) + '%'}</td>
                                        <td>{props && props.personalData && props.personalData.locationPincodeFlag ? <i className="fa fa-check green-fa-icon" style={{ color: 'green' }}></i> : <i className="fa fa-times red-fa-icon"></i>}</td>
                                    </tr>
                                </table>
                            </div>

                        </div>

                    </div>
                    <div className="credit-cards" style={{ marginBottom: '20px', minHeight: '300px' }}>
                        <div className="row">
                            <div className="col-sm-9 col-xs-9">
                                <h4><strong><img src={CreditImage} width='25px' />&nbsp;&nbsp;Credit Score</strong></h4>
                            </div>
                            <div className="col-sm-3 col-xs-3">

                                <div className="text-right"><button className="btn btn-success" onClick={() => settentative(true)}>Tentative Approve</button></div>
                            </div>
                        </div>
                        <Scores allDetail={props} />
                    </div>
                </div >

            }
            {tentative ?
                <TentativeApproval getData={props.getData} userDetails={props.userDetails} admin={props.admin} allDetail={props.allDetail} closePopup={settentative} />
                : ""}

        </>
    )
}
const mapStateToProps = ({ creditDashBoardDataValue }) => {
    return {
        creditDashBoardDataValue: creditDashBoardDataValue,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getDashboardVerifyData,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboarUi)