import moment from "moment";
export const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("-");
};

export const reverseFormat = (date) => {
  var d = date.split("-");

  return [d[2], d[1], d[0]].join("-");
};

export const todayDate = () => {
  var d = new Date(),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("-");
};
export const monthStartDate = () => {
  var d = new Date(),
    month = "" + (d.getMonth() + 1),
    day = "" + 1,
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("-");
};
export const monthLastDate = () => {
  var today = new Date();
  // var date = `today.getFullYear(), today.getMonth()+1, 0`;
  var d = new Date(today.getFullYear(), today.getMonth() + 1, 0),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("-");
};

export const lastDateOfMonth=(date)=>{
  
  return moment(new Date(date)).endOf('month').format("DD-MM-YYYY");
}
export const firstDateOfMonth = (date) => {
  return  moment(new Date(date)).startOf('month').format('DD-MM-YYYY');
};

export const getStartDateOfPreviousSevenDays = (date) => {
  return moment(date).subtract(6, 'days').startOf('day').format('DD-MM-YYYY');
};
