import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import { ROLE, STRING } from '../../../../utils/Constant'
import CustomMultiSelect from './CustomMultiSelect';
const ReportByChoice = ({
  admin,
  sendMailVerificationDisburse,
  sendMailVerificationCibil,
  sendMailVerification,
  initialList,
  allStatus,
  listByChoice,
  listByChoiceHandler,
  applyDateFromState,
  applyDateToState,
  listByChoiceSelectAll,
  excelFileName,
  disburseReport,
  reprotByChoice,
  cibilReport, repeatDownload, statusArray, handleLoanStatusChange, selectedLoanStatus,
  subStatusArray, selectedSubStatusValues, handleSubStatusChange,
  previousThreeMonthDate
  
}) => (
  <div className='container-fluid Phocket-crm-role-assign' style={{ marginTop: '90px' }}>
    <div className='row form-group'>
      <h3 className='text-center blue-text'>Generate Report New</h3>
    </div>
    <div className='row form-group'>
      <ul className='loan-lead-search'style={{display:'none'}}>
        <li>
          <label className='font-12px margin-0'>
            <span className='fa fa-search' /> Sheet by
          </label>
          <select
            className='form-control'
            onChange={e => listByChoice(e, 'sheetBy')}
          >
            <option value=''>Select Category</option>
            <option value={0}>Apply date</option>
            <option value={1}>Disburse date</option>
            <option value={2}>Paid date</option>
            <option value={3}>Cibil Report</option>
            <option value={4}>Payback Date</option>
          </select>
        </li>
        <li>
          <label className='font-12px'>Date From</label>
          <DatePicker
            value={reprotByChoice.applyDateFromState}
            selected={applyDateFromState}
            onChange={e => listByChoice(e, 'applyDateFrom')}
            className='form-control'
            dateFormat='DD/MM/YYYY'
        
          />
        </li>
        <li>
          <label className='font-12px'>Date To</label>
          <DatePicker
            value={reprotByChoice.applyDateToState}
            selected={applyDateToState}
            onChange={e => listByChoice(e, 'applyDateTo')}
            className='form-control'
            dateFormat='DD/MM/YYYY'
            maxDate={new Date(previousThreeMonthDate)}
          />
        </li>
        <li>
          <label className='font-12px'>Select Status</label>
          {reprotByChoice.downloadSheetBy == 3 ? (
            <select
              className='form-control'
              onChange={e => listByChoice(e, 'loanStatusForCivil')}
            >
              <option value=''>Select Status</option>
              <option value='Disbursed'>Disbursed</option>
              <option value='Paid'>Paid</option>
            </select>
          ) : (
            // <select
            //   multiple
            //   className='form-control'
            //   defaultValue={STRING.SELECT_ALL}
            //   onChange={e => listByChoice(e, 'loanStatus')}
            // >
            //   {allStatus != null && allStatus != '' && allStatus.length > 0
            //     ? allStatus.map(status => {
            //       return (
            //         <option value={status.statusId} key={status.statusId}>
            //           {status.adminStatus}
            //         </option>
            //       )
            //     })
            //     : ''}
            // </select>
            <CustomMultiSelect OptionValues={statusArray} selectedValues={selectedLoanStatus} onChange={handleLoanStatusChange} placeholder={'Select Status'} />
          )}
        </li>
        <li>
          <label className='font-12px'>Select Sub Status</label>
          {/* <select
            style={{ maxHeight: '52px' }}
            multiple
            className='form-control'
            // defaultValue={STRING.SELECT_ALL}
            onChange={e => listByChoice(e, 'subStatus')}
          >
            <option value='202'>RFD</option>
            <option value='201'>PFD</option>
            <option value='203'>Approved By NBFC</option>

          </select> */}
          <CustomMultiSelect OptionValues={subStatusArray} selectedValues={selectedSubStatusValues} onChange={handleSubStatusChange} placeholder={'Select Sub Status'} />
        </li>
        <li>
          <label className='font-12px'>Select Product Type </label>
          <select
            style={{ maxHeight: '52px' }}

            className='form-control'
            // defaultValue={STRING.SELECT_ALL}
            onChange={e => listByChoice(e, 'productType')}
          >
            <option value=''>Select Product</option>
            <option value='0'>Loan</option>
            <option value='1'>Gift Card</option>
          </select>
        </li>
        <li>
          <label className='font-12px'>Excel File Name</label>
          <input
            type='text'
            className='form-control'
            onChange={e => excelFileName(e)}
          />
        </li>
        <li>
          <label className='font-12px'>Select Loan From </label>
          <select
            style={{ maxHeight: '52px' }}

            className='form-control'
            // defaultValue={STRING.SELECT_ALL}
            onChange={e => listByChoice(e, 'loanFrom')}
          >
            <option value=''>Select loan from</option>
            <option value='phocket'>Phocket</option>
            <option value='bazarBussiness'>Badabusiness</option>
            <option value='vreedhi'>Vreedhi</option>
            <option value='teaGarden'>Tea Garden</option>
          </select>
        </li>
        {/* {admin.emailId === 'SuperUser' ?
            <li><button className='btn btn-primary' style={{ marginTop: '20px' }} onClick={() => repeatDownload()}>Download Repeat</button></li>
            : ""} */}
      </ul>
      <div className="col-sm-2 col-xs-12" style={{marginBottom:'5px'}}>
        <div>
          <label className='font-12px'>
            <span className='fa fa-search' /> Sheet by
          </label>
          <select
            className='form-control'
            onChange={e => listByChoice(e, 'sheetBy')}
          >
            <option value=''>Select Category</option>
            <option value={0}>Apply date</option>
            <option value={1}>Disburse date</option>
            <option value={2}>Paid date</option>
            <option value={3}>Cibil Report</option>
            <option value={4}>Payback Date</option>
          </select>
        </div>
      </div>
      <div className="col-sm-2 col-xs-12" style={{marginBottom:'5px'}}>
        <div className='custom-react-datePicker-css'>
          <label className='font-12px'>Date From</label>
          <DatePicker
            value={reprotByChoice.applyDateFromState}
            selected={applyDateFromState}
            onChange={e => listByChoice(e, 'applyDateFrom')}
            className='form-control'
            dateFormat='DD/MM/YYYY'
          />
        </div>
      </div>
      <div className="col-sm-2 col-xs-12" style={{marginBottom:'5px'}}>
        <div className='custom-react-datePicker-css'>
          <label className='font-12px'>Date To</label>
          <DatePicker
            value={reprotByChoice.applyDateToState}
            selected={applyDateToState}
            onChange={e => listByChoice(e, 'applyDateTo')}
            className='form-control'
            dateFormat='DD/MM/YYYY'
          />
        </div>
      </div>
      <div className="col-sm-2 col-xs-12" style={{marginBottom:'5px'}}>
        <div>
          <label className='font-12px'>Excel File Name</label>
          <input
            type='text'
            className='form-control'
            onChange={e => excelFileName(e)}
          />
        </div>
      </div>
      <div className="col-sm-2 col-xs-12" style={{marginBottom:'5px'}}>
        <div>
          <label className='font-12px'>Select Product Type </label>
          <select
            style={{ maxHeight: '52px' }}

            className='form-control'
            // defaultValue={STRING.SELECT_ALL}
            onChange={e => listByChoice(e, 'productType')}
          >
            <option value=''>Select Product</option>
            <option value='0'>Loan</option>
            <option value='1'>Gift Card</option>
          </select>
        </div>
      </div>
      <div className="col-sm-2 col-xs-12" style={{marginBottom:'5px'}}>
        <div>
          <label className='font-12px'>Select Loan From </label>
          <select
            style={{ maxHeight: '52px' }}

            className='form-control'
            // defaultValue={STRING.SELECT_ALL}
            onChange={e => listByChoice(e, 'loanFrom')}
          >
            <option value=''>Select loan from</option>
            <option value='phocket'>Phocket</option>
            <option value='bazarBussiness'>Badabusiness</option>
            <option value='vreedhi'>Vreedhi</option>
            <option value='teaGarden'>Tea Garden</option>
          </select>
        </div>
      </div>
      <div className="col-sm-2 col-xs-12" style={{marginBottom:'5px'}}>
        <div>
          <label className='font-12px'>Select Status</label>
          {reprotByChoice.downloadSheetBy == 3 ? (
            <select
              className='form-control'
              onChange={e => listByChoice(e, 'loanStatusForCivil')}
            >
              <option value=''>Select Status</option>
              <option value='Disbursed'>Disbursed</option>
              <option value='Paid'>Paid</option>
            </select>
          ) : (
            // <select
            //   multiple
            //   className='form-control'
            //   defaultValue={STRING.SELECT_ALL}
            //   onChange={e => listByChoice(e, 'loanStatus')}
            // >
            //   {allStatus != null && allStatus != '' && allStatus.length > 0
            //     ? allStatus.map(status => {
            //       return (
            //         <option value={status.statusId} key={status.statusId}>
            //           {status.adminStatus}
            //         </option>
            //       )
            //     })
            //     : ''}
            // </select>
            <CustomMultiSelect OptionValues={statusArray} selectedValues={selectedLoanStatus} onChange={handleLoanStatusChange} placeholder={'Select Status'} />
          )}
        </div>
      </div>
      <div className="col-sm-2 col-xs-12" style={{marginBottom:'5px'}}>
        <div>
          <label className='font-12px'>Select Sub Status</label>
          {/* <select
            style={{ maxHeight: '52px' }}
            multiple
            className='form-control'
            // defaultValue={STRING.SELECT_ALL}
            onChange={e => listByChoice(e, 'subStatus')}
          >
            <option value='202'>RFD</option>
            <option value='201'>PFD</option>
            <option value='203'>Approved By NBFC</option>

          </select> */}
          <CustomMultiSelect OptionValues={subStatusArray} selectedValues={selectedSubStatusValues} onChange={handleSubStatusChange} placeholder={'Select Sub Status'} />
        </div>
      </div>
      
    </div>
    <div className='row form-group'>
      <div className='col-sm-4 col-xs-12'>
        <input
          className='phocket-checkbox'
          type='checkbox'
          id='selectAllExcelFields'
          onChange={e => listByChoiceSelectAll(e)}
        />
        <label htmlFor='selectAllExcelFields'>
          <span></span>
        </label>
        &nbsp;&nbsp;
        <span>
          <strong>Select All Fields</strong>
        </span>
      </div>
      {admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.GENERATE_REPORT_ROLE) >= 0 ?

        <div className='col-sm-5 col-xs-12'>
          {reprotByChoice.downloadSheetBy == 3 ? (
            <button
              className='profile-save-btn pull-right margin-0'
              onClick={e => cibilReport()}
            >
              Generate Cibil Report
            </button>
          ) : (
            ''
          )}
        </div>
        : <div className='col-sm-5 col-xs-12'>
          {reprotByChoice.downloadSheetBy == 3 ? (
            <button
              className='profile-save-btn pull-right margin-0'
              onClick={e => sendMailVerificationCibil()}
            >
              Generate Cibil Report
            </button>
          ) : (
            ''
          )}
        </div>}
      {/* {admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.GENERATE_REPORT_ROLE) >= 0 ?
          <div className='col-sm-3 col-xs-12'>
            <button
              className='profile-save-btn pull-right margin-0'
              onClick={e => disburseReport()}
            >
              Generate Disburse Report
            </button>
          </div>
          : <div className='col-sm-3 col-xs-12'>
            <button
              className='profile-save-btn pull-right margin-0'
              onClick={e => sendMailVerificationDisburse()}
            >
              Generate Disburse Report
            </button>
          </div>} */}
    </div>
    <div className='row form-group'>
      {initialList != '' && initialList.length > 0
        ? initialList.map((list, i) => {
          return (
            <div key={i} className='col-sm-3 col-xs-12'>
              <input
                className='phocket-checkbox'
                type='checkbox'
                id={'listByChoice' + i}
                value={list}
                onChange={e => listByChoice(e, 'reportFields')}
              />
              <label htmlFor={'listByChoice' + i}>
                <span></span>
              </label>
              &nbsp;&nbsp;
              <span>{list}</span>
            </div>
          )
        })
        : ''}
    </div>

    <div className='row text-center'>
      <div className='col-xs-12'>
        {admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.GENERATE_REPORT_ROLE) >= 0 ?

          <button
            className='profile-save-btn'
            id='submitDetail'
            onClick={e => listByChoiceHandler()}
          >
            <span className='fa fa-download'></span>&nbsp;&nbsp;Generate Report
          </button>
          : <button
            className='profile-save-btn'
            id='submitDetail'
            onClick={e => sendMailVerification()}
          >
            <span className='fa fa-download'></span>&nbsp;&nbsp;Generate Report
          </button>}
      </div>
    </div>
  </div>
)

export default ReportByChoice
