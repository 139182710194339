import React from "react";
import { ROLE } from "../../../../utils/Constant";
import FlipkartLogo from '../../../../images/flipkart.png'
import LoanLogo from '../../../../images/LoanFinal.png'
import AmazonLogo from "../../../../images/amaz.png"
import HistoryImage from '../../../../images/rewampimages/history.png'
import EmiHis from '../../Admin/Emi/NewEmiHistory'

class LoanHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewPage: 'loan'
        }
    }
    pageHandler(data) {
        this.setState({ viewPage: data })
    }
    render() {
        const { loanData, getGiftCardDetail, sendNoc, admin, userDetails } = this.props
        return (
            <div className="conatiner-fluid details-new" style={{ marginTop: '20px', marginBottom: '20px' }}>
                {console.log(loanData,'mjhg')}
                <h4><img src={HistoryImage} width={'27px'} style={{ marginLeft: '10px' }} />&nbsp;History</h4>
                <div className="btn-group btn-grp-own" role="group" aria-label="onboard-btn" style={{ marginTop: '10px', padding: '10px',fontSize:'14px' }}>
                    <button className="btn btn-outline-primary" style={{ background: this.state.viewPage === 'loan' ? '#1D3557' : '#F1F7FF', color: this.state.viewPage === 'loan' ? '#fff' : '#000' }} onClick={() => this.pageHandler('loan')}> Loan History</button>
                    <button className="btn btn-outline-primary" style={{ background: this.state.viewPage === 'emi' ? '#1D3557' : '#F1F7FF', color: this.state.viewPage === 'emi' ? '#fff' : '#000' }} onClick={() => this.pageHandler('emi')} > EMI History</button>

                </div>
                {this.state.viewPage==='emi'?
                <EmiHis userDetail={userDetails} allDetail={userDetails}/>
            :""}
                {this.state.viewPage === 'loan' ?
                    <div className="overflow-x-auto phocket-table-new text-center">
                        <table>
                            <thead>
                                <tr>
                                    <th>Application Id</th>
                                    <th>Applied Date</th>
                                    <th>Reject Reason</th>
                                    <th>Sub Status</th>
                                    <th>Approved Date</th>

                                    <th>Disbursed Date</th>
                                    {/* {admin.emailId == 'SuperUser' ?  */}
                                    {/* <th>Voucher Code</th>  */}
                                    {/* : ''} */}
                                    {admin.role == 'superuser' && userDetails.typeOfProduct != 'Loan' ? <th>Voucher Details</th> : ''}
                                    {/* {admin.emailId == 'SuperUser' ? ( */}
                                    {/* <th>Voucher Expiry Date</th> */}
                                    {/* ) : (
                    ''
                  )} */}
                                    <th>Applied Amount</th>
                                    <th>Approved Amount</th>
                                    <th>Processing Fee</th>
                                    <th>Disburse Amount</th>

                                    <th>Tenure</th>
                                    <th>EMI</th>
                                    <th>Tentative Amount</th>
                                    <th>Tentative Repayment Date</th>

                                    {/* <th>Call Scheduled Date</th> */}
                                    <th>Complete Type</th>
                                    <th>Incomplete Type</th>
                                    <th>IVR Status</th>
                                    <th>Loan Status</th>
                                   
                                    <th>Repayment Amount</th>
                                    <th>Pay on Today</th>
                                    <th>Repayment Date</th>
                                    <th>Payment Date</th>
                                    <th>Date Difference</th>
                                    <th>Type</th>
                                    <th>Loan From</th>
                                    <th>KFS</th>
                                    {/* <th>Convert to EMI</th> */}
                                    {admin.rolelist.indexOf(ROLE.Mark_RFD) >= 0 ? <th>Send NOC</th> : ""}
                                </tr>
                            </thead>
                            <tbody>

                                {loanData.map((loan, i) => {
                                    // if (i !== 0) {ihavechange
                                        return (
                                            <tr key={i}>
                                                <td>{loan.applicationId}</td>
                                                <td>
                                                    {loan.loanApplyDate != null && loan.loanApplyDate != ''
                                                        ? loan.loanApplyDate.split(' ')[0]
                                                        : null}
                                                </td>
                                                <td>{loan.approveOrRejectReason}</td>
                                               
                                                {/* {loan.subStatus != '' && loan.subStatus!=null? */}
                                                <td>{loan.subStatus}</td>
                                                {/* :""} */}
                                                <td>
                                                    {loan.approveDate != null && loan.approveDate != ''
                                                        ? loan.approveDate.split(' ')[0]
                                                        : null}
                                                </td>
                                                <td>{loan.disburseDate}</td>
                                                {/* {admin.emailId == 'SuperUser' ? ( */}
                                                {/* <td>{loan.voucherCode}</td> */}
                                                {/* ) : (
                        ''
                      )} */}
                                                {admin.role == 'superuser' && userDetails.typeOfProduct != 'Loan' ? (
                                                    <td> <i style={{ cursor: 'pointer' }} onClick={() => getGiftCardDetail(loan.giftCardId)} className="fa fa-info-circle" aria-hidden="true"></i></td>
                                                ) : (
                                                    ''
                                                )}
                                                {/* {admin.emailId == 'SuperUser' ? ( */}
                                                {/* <td>{loan.expiryDate}</td> */}
                                                {/* ) : (
                        ''
                      )} */}
                                                <td>{loan.loanAmount}</td>
                                                <td>{loan.approveAmount}</td>
                                                <td>{loan.processingFee}</td>
                                                <td>{loan.disbursmentAmt}</td>
                                                {/* <td>{loan.subStatus}</td> */}

                                                <td>{loan.paybackDuration}</td>
                                                <td>{loan.loanEmi > 0 ? loan.loanEmi : null}</td>
                                                <td>{loan.tentativeApproveAmount}</td>
                                                <td>
                                                    {loan.tentativeDuration != 0
                                                        ? loan.tentativeDuration
                                                        : null}
                                                </td>
                                                {/* <td>{loan.callScheduleDate}</td> */}
                                                <td>{loan.completeType}</td>
                                                <td>{loan.incompleteType}</td>

                                                <td
                                                    style={{
                                                        backgroundColor:
                                                            loan.ivrStatus == 1
                                                                ? '#00800082'
                                                                : loan.ivrStatus == 2
                                                                    ? '#ff000057'
                                                                    : null
                                                    }}
                                                >
                                                    {loan.ivrStatus == 1
                                                        ? 'Confirmed'
                                                        : loan.ivrStatus == 2
                                                            ? 'Need Call'
                                                            : null}
                                                </td>
                                                <td>{loan.adminLoanStatus}</td>
                                                
                                                <td>{loan.totalPaybackAmount}</td>
                                                <td>{loan.todayAmount}</td>
                                                <td>
                                                    {loan.loanPaybackDate != null &&
                                                        loan.loanPaybackDate != ''
                                                        ? loan.loanPaybackDate.split(' ')[0]
                                                        : null}
                                                </td>
                                                <td>
                                                    {loan.paymentDate != null && loan.paymentDate != ''
                                                        ? loan.paymentDate.split(' ')[0]
                                                        : null}
                                                </td>
                                                {loan.paymentDate != null ? (
                                                    <td
                                                        style={{
                                                            backgroundColor:
                                                                loan.delayCount <= 0 ? '#00800082' : '#ff000057'
                                                        }}
                                                    >
                                                        {loan.delayCount}
                                                    </td>
                                                ) : (
                                                    <td></td>
                                                )}
                                                 {loan.giftCardType == 'Amazon' ? (
                                                    <td>
                                                        <img src={AmazonLogo} width='21px' />
                                                    </td>
                                                ) : loan.giftCardType == 'Flipkart' ? (
                                                    <td>
                                                        <img src={FlipkartLogo} width='21px' />
                                                    </td>
                                                ) : loan.productType == 'Loan' ? (
                                                    <td>
                                                        <img src={LoanLogo} width='21px' />
                                                    </td>
                                                ) : (
                                                    <td></td>
                                                )}
                                                <td>{loan.platFormFrom}</td>
                                                <td>{loan.keyFactStatementUrl ? 
    <a target='_blank' href={loan.keyFactStatementUrl.includes('//adminsupport.phocket.in')
    ? loan.keyFactStatementUrl.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in'):loan.keyFactStatementUrl} style={{ padding: '3px' }} className='btn btn-primary'>View</a> 
    : ""}
</td>                      
                                                {/* <td>{loan.keyFactStatementUrl ? <a target='_blank' href={loan.keyFactStatementUrl} style={{ padding: '3px' }} className='btn btn-primary'>View</a> : ""}</td> */}
                                                {/* <td>
                                               {loan.canConvertToEMI ?
                                                    <button className="btn btn-primary" onClick={(e) => convertLoan(loan)}><span className="fa fa-exchange" /></button>
                                                    : null}
                                            </td> */}
                                                {admin.rolelist.indexOf(ROLE.SEND_NOC) >= 0 ? <td>{loan.adminLoanStatus === 'Paid'&&userDetails.loanFrom==='phocket' ? <button onClick={() => sendNoc(loan.loanId)} className='btn btn-primary'>Send NOC</button> : ""}</td> : ""}
                                            </tr>
                                        )
                                    // }
                                })}
                            </tbody>
                        </table>

                    </div >
                    : ""}
            </div>
        )
    }
}
export default LoanHistory