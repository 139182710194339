import React, { Component } from 'react'
import ReportByChoice from '../../../presentationals/Admin/DashboardAndReports/ReportByChoiceNew.component'
import PredefinedReport from '../../../presentationals/Admin/DashboardAndReports/PredefinedReportByChoiceNew.component'
import { APIS } from '../../../../utils/api-factory'
import { _preFormatDate } from '../../../../utils/validation'
import inactivetabimg from './PerformanceTracker/images/report.png'
import activetabimg from './PerformanceTracker/images/report (1).png'
import downloadicon from './PerformanceTracker/images/downloadicon.png'
import { STRING } from '../../../..//utils/Constant'
import {
  excelSheetByChoiceNew,
  excelSheetDisbursed,
  excelSheetCibil,
  saveEmailVerificationGenerateReportApi,
  verifyOtp,
  RepeatDataApi,
  

} from '../AdminActionCreator.component'
import Popup from '../../../presentationals/Popup/Popup.component'
import Loader from '../../../presentationals/Loader/Loader.component'
import OtpPopup from '../../../presentationals/Popup/verifyOtp.component'
import moment from 'moment'

let reportFields = []

class ReportChoice extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lastThreeMonts: '',
      verifyOptpPopupCibil: false,
      verifyOptpPopupDisburse: false,
      verifyOptpPopup: false,
      popupState: false,
      popupStatus: '',
      initialList: '',
      applyDateFromState: '',
      applyDateToState: '',
      excelFileName: '',
      loaderState: false,
      tab: 'General',
      reprotByChoice: {
        applyDateFromState: '',
        applyDateToState: '',
        loanStatusState: [],
        reportFields: [],
        downloadSheetBy: '',
        loanStatusForCivilState: '',
        subStatus: [],
        productType: '',
        loanFrom: ''
      },
      selectedValues: [],
      selectedLoanStatus: [],
      subStatusArray: [
        { option: 'RFD', value: '202' },
        { option: 'PFD', value: '201' },
        { option: 'Approved By NBFC', value: '203' },
      ],
      selectedSubStatusValues: [],
    }
  }
  otpHandler(e) {
    this.setState({ code: e.target.value })
  }
  codeVerify() {
    const { admin } = this.props
    if (this.state.code !== '') {
      verifyOtp(admin.emailId, this.state.code, callBack => {
        if (callBack == 'success') {
          this.listByChoiceHandler()
          this.setState({ code: '' })
        } else {
          this.setState({
            popupState: true,
            popupStatus: 'Please enter correct OTP'
          })
          this.removePopup()
        }
      })
    } else {
      this.setState({
        popupState: true,
        popupStatus: 'Please enter OTP'
      })
      this.removePopup()
    }
  }
  sendMailVerification() {
    const { admin } = this.props
    if (
      this.state.reprotByChoice.reportFields.length > 0 &&
      this.state.excelFileName != ''
    ) {
      if (this.state.reprotByChoice.downloadSheetBy != '') {
        saveEmailVerificationGenerateReportApi(this.state.reprotByChoice, admin, callBack => {
          if (callBack === 'success') {
            this.setState({ verifyOptpPopup: true })
          }
        })
      } else {
        this.setState({
          popupState: true,
          popupStatus: 'Please select Sheet download by'
        })
        this.removePopup()
      }
    } else {
      this.setState({
        popupState: true,
        popupStatus: 'Please select Fields & enter file name'
      })
      this.removePopup()
    }
  }

  codeVerifyDisburse() {
    const { admin } = this.props
    if (this.state.code !== '') {
      verifyOtp(admin.emailId, this.state.code, callBack => {
        if (callBack == 'success') {
          this.disburseReport()
          this.setState({ code: '' })
        } else {
          this.setState({
            popupState: true,
            popupStatus: 'Please enter correct OTP'
          })
          this.removePopup()
        }
      })
    } else {
      this.setState({
        popupState: true,
        popupStatus: 'Please enter OTP'
      })
      this.removePopup()
    }
  }
  sendMailVerificationDisburse() {
    const { admin } = this.props
    if (this.state.reprotByChoice.applyDateFromState !== '') {
      saveEmailVerificationGenerateReportApi(this.state.reprotByChoice, admin, callBack => {

        if (callBack === 'success') {
          this.setState({ verifyOptpPopupDisburse: true })
        }
      })
    } else {
      this.setState({
        popupState: true,
        popupStatus: 'Please select Start Date'
      })
      this.removePopup()
    }

  }

  codeVerifyCibil() {
    const { admin } = this.props
    if (this.state.code !== '') {
      verifyOtp(admin.emailId, this.state.code, callBack => {
        if (callBack == 'success') {
          this.cibilReport()
          this.setState({ code: '' })
        } else {
          this.setState({
            popupState: true,
            popupStatus: 'Please enter correct OTP'
          })
          this.removePopup()
        }
      })
    } else {
      this.setState({
        popupState: true,
        popupStatus: 'Please enter OTP'
      })
      this.removePopup()
    }
  }
  sendMailVerificationCibil() {
    const { admin } = this.props
    if (this.state.reprotByChoice.applyDateFromState !== '' && this.state.reprotByChoice.applyDateToState !== '' && this.state.reprotByChoice.loanStatusForCivilState !== '') {
      saveEmailVerificationGenerateReportApi(this.state.reprotByChoice, admin, callBack => {
        if (callBack === 'success') {
          this.setState({ verifyOptpPopupCibil: true })
        }
      })
    } else {
      this.setState({
        popupState: true,
        popupStatus: 'Please enter dates and status to download'
      })
      this.removePopup()
    }


  }

  componentWillMount() {
    fetch(APIS.LIST_FOR_EXCEL_REPORT)
      .then(res => res.json())
      .then(res => {
        this.setState({ initialList: res })
      })
  }
  repeatDownload() {
    const { admin } = this.props
    this.setState({ loaderState: true })
    RepeatDataApi(admin.emailId, callBack => {
      this.setState({ loaderState: false })

    })
  }
  tabchange(type) {
    this.setState({
      tab: type, selectedLoanStatus: [], selectedSubStatusValues: [], reprotByChoice: {
        applyDateFromState: '',
        applyDateToState: '',
      }
    })
  }
  handleChange(e) {
    this.setState({ selectedValues: e.target.value })
  };

  createStatusArray(allStatus) {
    const statusArray = [];
    if (allStatus != null && allStatus != '' && allStatus.length > 0) {
      allStatus.forEach(status => {
        // statusarray.push(status.adminStatus); 
        statusArray.push({ option: status.adminStatus, value: status.statusId });
      });
    }
    return statusArray;
  };

  handleLoanStatusChange(selectedValues) {
    this.setState({ selectedLoanStatus: selectedValues });
    this.listByChoice(selectedValues, 'loanStatus');

  };
  handleSubStatusChange(selectedValues) {
    this.setState({ selectedSubStatusValues: selectedValues });
    this.listByChoice(selectedValues, 'subStatus');

  };

  render() {
    const { allStatus, admin } = this.props;
    const statusArray = this.createStatusArray(allStatus);

    return (
      <>
        <div className="container-fluid details-new fixed-top" style={{ zIndex: '2' }}>
          <div className="row details-new-tab-div" style={{ display: 'flex', justifyContent: 'end' }}>
            <div className=" col-lg-6 col-xs-12" style={{ fontSize: '10px', display: 'flex', justifyContent: 'end' }}>
              <div className="btn-group-new">
                <button className={(this.state.tab === 'General') ? "active" : ""} onClick={(e) => this.tabchange('General')}><img src={this.state.tab === 'General' ? activetabimg : inactivetabimg} style={{ width: '27px' }} /><br />Generate General <br /> Report </button>

                <button className={(this.state.tab === 'Predefined') ? "active" : ""} onClick={(e) => this.tabchange('Predefined')}><img src={this.state.tab === 'Predefined' ? activetabimg : inactivetabimg} style={{ width: '27px' }} /><br />Generate Predefined <br /> Report</button>
              </div>
            </div>
          </div>
        </div>
        <div className='full-height'>
          {this.state.loaderState == true ? <Loader /> : ''}
          {this.state.popupState == true ? (
            <Popup
              closePopup={this.closePopup.bind(this)}
              popupStatus={this.state.popupStatus}
            />
          ) : (
            ''
          )}
          {
            this.state.tab === 'General' ?
              <ReportByChoice
                handleChange={this.handleChange.bind(this)}
                selectedValues={this.state.selectedValues}
                repeatDownload={this.repeatDownload.bind(this)}
                admin={admin}
                initialList={this.state.initialList}
                allStatus={allStatus}
                reprotByChoice={this.state.reprotByChoice}
                listByChoice={this.listByChoice.bind(this)}
                applyDateFromState={this.state.applyDateFromState}
                applyDateToState={this.state.applyDateToState}
                listByChoiceSelectAll={this.listByChoiceSelectAll.bind(this)}
                disburseReport={this.disburseReport.bind(this)}
                cibilReport={this.cibilReport.bind(this)}
                listByChoiceHandler={this.listByChoiceHandler.bind(this)}
                sendMailVerificationDisburse={this.sendMailVerificationDisburse.bind(this)}
                sendMailVerificationCibil={this.sendMailVerificationCibil.bind(this)}
                sendMailVerification={this.sendMailVerification.bind(this)}
                excelFileName={this.excelFileName.bind(this)}
                statusArray={statusArray}
                handleLoanStatusChange={this.handleLoanStatusChange.bind(this)}
                selectedLoanStatus={this.state.selectedLoanStatus}
                subStatusArray={this.state.subStatusArray}
                selectedSubStatusValues={this.state.selectedSubStatusValues}
                handleSubStatusChange={this.handleSubStatusChange.bind(this)}

              />
              : ''}
          {
            this.state.tab === 'Predefined' ?
              <PredefinedReport
                admin={admin}
                downloadicon={downloadicon} />
              : ''}
          {this.state.verifyOptpPopup ?
            <OtpPopup
              otpHandler={this.otpHandler.bind(this)}
              OtpSubmit={this.codeVerify.bind(this)}
            />
            : ""}
          {this.state.verifyOptpPopupDisburse ?
            <OtpPopup
              otpHandler={this.otpHandler.bind(this)}
              OtpSubmit={this.codeVerifyDisburse.bind(this)}
            />
            : ""}
          {this.state.verifyOptpPopupCibil ?
            <OtpPopup
              otpHandler={this.otpHandler.bind(this)}
              OtpSubmit={this.codeVerifyCibil.bind(this)}
            />
            : ""}
        </div>
      </>
    )
  }

  removePopup() {
    setTimeout(
      function () {
        this.setState({ popupState: false })
      }.bind(this),
      5000
    )
  }

  closePopup() {
    this.setState({ popupState: false })
  }

  disburseReport() {
    const { admin } = this.props
    if (this.state.applyDateFromState != '') {
      excelSheetDisbursed(
        this.state.reprotByChoice,
        this.state.excelFileName,
        admin,
        callBack => {
          this.setState({ loaderState: false, verifyOptpPopupDisburse: false })
        }
      )
    } else {
      this.setState({
        popupState: true,
        popupStatus: 'Please select Start Date'
      })
      this.removePopup()
    }
  }

  cibilReport() {
    if (this.state.reprotByChoice.downloadSheetBy == 3) {
      if (this.state.reprotByChoice.applyDateFromState !== '' && this.state.reprotByChoice.applyDateToState !== '' && this.state.reprotByChoice.loanStatusForCivilState !== '') {
        this.setState({ loaderState: true })
        excelSheetCibil(this.state.reprotByChoice, callBack => {
          this.setState({ loaderState: false, verifyOptpPopupCibil: false })
        })
      } else {
        this.setState({
          popupState: true,
          popupStatus: 'Please enter dates and status to download'
        })
        this.removePopup()
      }
    } else {
      this.setState({
        popupState: true,
        popupStatus: 'Please select Sheet by Cibil Report'
      })
      this.removePopup()
    }
  }

  excelFileName(e) {
    this.setState({ excelFileName: e.target.value })
  }

  listByChoiceSelectAll(e) {
    let reprotByChoice = Object.assign({}, this.state.reprotByChoice)
    reportFields = []
    if (e.target.checked == true) {
      for (let i = 0; i < this.state.initialList.length; i++) {
        if (reportFields.includes(this.state.initialList[i])) {
        } else {
          reportFields.push(this.state.initialList[i])
        }
        document.getElementById('listByChoice' + i).checked = true
      }
    } else {
      reportFields = []
      for (let i = 0; i < this.state.initialList.length; i++) {
        document.getElementById('listByChoice' + i).checked = false
      }
    }
    reprotByChoice.reportFields = reportFields
    this.setState({ reprotByChoice })
  }

  listByChoice(e, listType) {
    let reprotByChoice = Object.assign({}, this.state.reprotByChoice)
    if (listType == 'applyDateFrom') {
      this.setState({ applyDateFromState: e })
      reprotByChoice.applyDateFromState = _preFormatDate(e)
    } else if (listType == 'applyDateTo') {
      this.setState({ applyDateToState: e })
      reprotByChoice.applyDateToState = _preFormatDate(e)
      const today = new Date(e);
      const ninetyDaysAgo = new Date(today);
      ninetyDaysAgo.setDate(today.getDate() - 90);
      const formattedDate = ninetyDaysAgo.toISOString().split('T')[0];
      console.log(_preFormatDate(formattedDate));
      
      this.setState({ lastThreeMonts: formattedDate })
    } else if (listType == 'loanStatus') {
      // var options = e.target.options
      // var value = []
      // for (var i = 0, l = options.length; i < l; i++) {
      //   if (options[i].selected) {
      //     value.push(options[i].value)
      //   }
      // }
      // reprotByChoice.loanStatusState = value
      reprotByChoice.loanStatusState = e
    } else if (listType == 'reportFields') {
      if (e.target.checked == true) {
        if (reportFields.includes(e.target.value)) {
        } else {
          reportFields.push(e.target.value)
        }
      } else {
        let index = reportFields.indexOf(e.target.value)
        if (index >= 0) {
          reportFields.splice(index, 1)
        }
        document.getElementById('selectAllExcelFields').checked = false
      }
      reprotByChoice.reportFields = reportFields
    } else if (listType == 'sheetBy') {
      reprotByChoice.downloadSheetBy = e.target.value
    } else if (listType == 'loanStatusForCivil') {
      reprotByChoice.loanStatusForCivilState = e.target.value
    } else if (listType == 'subStatus') {
      // var options = e.target.options
      // var value = []
      // for (var i = 0, l = options.length; i < l; i++) {
      //   if (options[i].selected) {
      //     value.push(options[i].value)
      //   }
      // }
      reprotByChoice.subStatus = e;
    } else if (listType == 'productType') {
      reprotByChoice.productType = e.target.value
    } else if (listType == 'loanFrom') {
      reprotByChoice.loanFrom = e.target.value
    }
    this.setState({ reprotByChoice })
  }

  listByChoiceHandler() {
    const { admin } = this.props
    if (
      this.state.reprotByChoice.reportFields.length > 0 &&
      this.state.excelFileName != ''
    ) {

      let date1 = this.state.lastThreeMonts
      let date2 = moment(this.state.applyDateFromState).format('YYYY-MM-DD')
      if (date1 <= date2) {
        if (this.state.reprotByChoice.downloadSheetBy != '') {
          this.setState({ loaderState: true })
          excelSheetByChoiceNew(
            this.state.reprotByChoice,
            this.state.excelFileName,
            admin,
            this.state.reprotByChoice.loanFrom,
            // this.state.selectedValues,
            callBack => {
              this.setState({ loaderState: false, verifyOptpPopup: false })
            }
          )
        }
        else {
          this.setState({
            popupState: true,
            popupStatus: 'Please select Sheet download by'
          })
          this.removePopup()
        }
      } else {
        this.setState({
          popupState: true,
          popupStatus: 'Date should be between 3 months'
        })
        this.removePopup()
      }
    } else {
      this.setState({
        popupState: true,
        popupStatus: 'Please select Fields & enter file name'
      })
      this.removePopup()
    }
  }
}
export default ReportChoice
