import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { statusWiseCommunicationMarketSms } from "../../actioncreator";
const Email = ({userIdList,admin,payload, communicateViaFilter, status}) => {
    const [data, setData] = useState({
        template: "",
        body: "",
        subject: "",
    });
    const [typeList, setTypeList] = useState(["mail"])
    const [mailBody, setmailBody] = useState('')
    const [imageUrl, setimageUrl] = useState('')
    const [subject, setsubject] = useState('')
    const getBranchCommunicateForBreakDown = () => {
        statusWiseCommunicationMarketSms(typeList, mailBody, subject, admin.emailId, imageUrl, userIdList, '', '',payload, communicateViaFilter, status, callback => {

        })
    };
    const sendHandlerMsg = (type) => {
        getBranchCommunicateForBreakDown()
    }
    const mailHandler = (e, type) => {
        if (type === 'subject') {
            setsubject(e.target.value)
        }
        if (type === 'body') {
            setmailBody(e)
        }
    }
    return (
        <div className=' marketing-main-css '>
            <div className='row'>
                <div className='col-sm-9'>
                    <div className='cards-shadow ' style={{ padding: '15px', marginTop: '20px' }}>
                        <label className="form-label">Select Email Template </label>
                        <select className="form-select"  >
                            <option value="" >Select From Below Options</option>
                        </select>
                        <label style={{ color: 'rgba(0, 0, 0, 0.45)', marginTop: '10px' }}> Mail Box</label>
                        <div style={{ background: 'rgba(219, 233, 255, 0.70)', marginTop: "10px", padding: '14px', borderRadius: '5px' }}>

                            <label style={{ color: 'rgba(0, 0, 0, 0.45' }}>Subject</label>
                            <input onChange={(value) => mailHandler(value,'subject')} className="" style={{ backgroundColor: '#fff', width: '100%', borderRadius: '5px', padding: '10px', }} placeholder="Enter Subject" />
                            <label style={{ color: 'rgba(0, 0, 0, 0.45', marginTop: '10px' }}>Mail Body</label>
                            <ReactQuill
                                style={{
                                    backgroundColor: "#FFFFFF",
                                    marginTop: "0rem",
                                }}
                                name="body"
                                theme="snow"
                                value={mailBody}
                                onChange={(value) => mailHandler(value,'body')}
                            />
                        </div>
                        <button
                        onClick={()=>sendHandlerMsg()}
                            title="SEND"
                            type="button"
                            className="bulkCommSendBtn"
                            style={{ width: '100px', marginTop: '20px' }}
                        >
                            Send 
                        </button>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default Email