import React, { Component } from 'react'
import BulkCommCSV from '../../../presentationals/Admin/BulkActions/CommunicateByCsv.component'
import { notificationImageUploadApi, bulkCommunicateByCsvApi } from '../AdminActionCreator.component'
import Popup from '../../../presentationals/Popup/Popup.component';
import ConfirmationPopup from '../../../presentationals/Popup/ConfirmationPopup.component'
import { _formatDateInDash, _formatDateInYMD } from '../../../../utils/validation'
import MainLoader from '../../../presentationals/Loader/Loader.component'
import { HeaderType, TemplateId } from '../../../presentationals/Admin/BulkActions/MessageTemplates.component'

let File = '';

class CommunicationByCSV extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listOfUsersToCommunicate: [],
            popupState: false,
            popupStatus: '',
            msgBoxState: true,
            mailBoxSate: false,
            url: '',
            confirmationPopup: false,
            confirmationText: '',
            notificationImage: '',
            uploadedCSV: '',
            btnDisabled: false,
            communicateByCsv: {
                title: '',
                body: '',
                type: ["Message"],
            },
            csvFile: '',
            requestFrom: 0,
            MainLoaderState: false,
            typeOfFile: '',
            templateIdTosend: '',
            headerType: '',
            messageType:''
        }
    }

    componentWillMount() {

    }

    mailTempleteClick(e, type) {
        let communicateByCsv = Object.assign({}, this.state.communicateByCsv)
        if (type == 'REVIEW') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.REVIEW_ON_PLAYSTORE,messageType:HeaderType.REVIEW_ON_PLAYSTORE })
        }
        if (type == 'MONTHEND') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.MONTH_END_SAL_ADVANCE ,messageType:HeaderType.MONTH_END_SAL_ADVANCE})
        }
        if (type == 'BILLPAY') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.BILL_PAY_SAL_ADVANCE ,messageType:HeaderType.BILL_PAY_SAL_ADVANCE})
        }
        if (type == 'TRYPHOCKET') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.PHOCKET_APP_TRY_KARO ,messageType:HeaderType.PHOCKET_APP_TRY_KARO})
        }
        if (type == 'JHATPATLOAN') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.JHATPAT_LOAN ,messageType:HeaderType.JHATPAT_LOAN})
        }
        if (type == 'ADVANCESALARY') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.ELIGIBLE_FOR_ADVANCE_SAL_2_LAC ,messageType:HeaderType.ELIGIBLE_FOR_ADVANCE_SAL_2_LAC})
        }
        if (type == 'FESTIVESHOPING') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.FESTIVE_SHOPPING_LOAN ,messageType:HeaderType.FESTIVE_SHOPPING_LOAN})
        }
        if (type == 'FEWMINUTELOAN') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.LOAN_WITHIN_FEW_MINUTES ,messageType:HeaderType.LOAN_WITHIN_FEW_MINUTES})
        }
        if (type == 'LASTCHANCELOAN') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.LAST_CHANCE_LOAN ,messageType:HeaderType.LAST_CHANCE_LOAN})
        }
        if (type == 'UPTO100000') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.UPTO_200000_LOAN ,messageType:HeaderType.UPTO_200000_LOAN})
        }
        if (type == 'LOANWAITING') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.LOAN_WAITING ,messageType:HeaderType.LOAN_WAITING})
        }
        if (type == 'LOWINTERST') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.LOAN_AT_LOW_INTEREST ,messageType:HeaderType.LOAN_AT_LOW_INTEREST})
        }
        if (type == 'SPEEDOFROCKET') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.MONEY_AT_SPEED_OF_ROCKET ,messageType:HeaderType.MONEY_AT_SPEED_OF_ROCKET})
        }
        if (type == 'BANKBAZAR') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.ADVANCE_SALARY_VIA_BANKBAZAAR ,messageType:HeaderType.ADVANCE_SALARY_VIA_BANKBAZAAR})
        }
        if (type == 'URGENTCASH') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.URGENT_CASH ,messageType:HeaderType.URGENT_CASH})
        }
        if (type == 'FIVEMINUTELOAN') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.LOAN_WITHIN_5_MINUTES ,messageType:HeaderType.LOAN_WITHIN_5_MINUTES})
        } if (type == 'BANKBAZAAR') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.BANKBAZAAR ,messageType:HeaderType.BANKBAZAAR})
        } if (type == 'SALARY_12_MONTH') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.SALARY_12_MONTH ,messageType:HeaderType.SALARY_12_MONTH})
        }
        if (type == 'GET_A_PHOCKET_PL') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.GET_A_PHOCKET_PL ,messageType:HeaderType.GET_A_PHOCKET_PL})
        }
        if (type == 'ADVANCE_SAL_BY_BUDDYLOAN') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.ADVANCE_SAL_BY_BUDDYLOAN ,messageType:HeaderType.ADVANCE_SAL_BY_BUDDYLOAN})
        }
        if (type == 'PRE_QUALIFIED_BUDDY_LOAN_PHOCKET') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.PRE_QUALIFIED_BUDDY_LOAN_PHOCKET ,messageType:HeaderType.PRE_QUALIFIED_BUDDY_LOAN_PHOCKET})
        }
        if (type == 'PHOCKET_BANKBAZAAR') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.PHOCKET_BANKBAZAAR ,messageType:HeaderType.PHOCKET_BANKBAZAAR})
        }
        if (type == 'LOAN_FOR_15000_SALARY') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.LOAN_FOR_15000_SALARY ,messageType:HeaderType.LOAN_FOR_15000_SALARY})
        }
        if (type == 'Phocket_App_partnered_with_Anjraj_Talent') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.Phocket_App_partnered_with_Anjraj_Talent ,messageType:HeaderType.Phocket_App_partnered_with_Anjraj_Talent})
        }
        if (type == 'Dear_Customer_Refer_your_friends') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.Dear_Customer_Refer_your_friends ,messageType:HeaderType.Dear_Customer_Refer_your_friends})
        }
        if (type == 'Phocket_App_partnered_with_Genesis') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.Phocket_App_partnered_with_Genesis ,messageType:HeaderType.Phocket_App_partnered_with_Genesis})
        }
        if (type == 'Phocket_App_partnered_with_Hyrexpert') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.Phocket_App_partnered_with_Hyrexpert ,messageType:HeaderType.Phocket_App_partnered_with_Hyrexpert})
        }
        if (type == 'Phocket_App_partnered_with_Karyarth') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.Phocket_App_partnered_with_Karyarth ,messageType:HeaderType.Phocket_App_partnered_with_Karyarth})
        }
        if (type == 'Phocket_App_partnered_with_SAM_Career') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.Phocket_App_partnered_with_SAM_Career ,messageType:HeaderType.Phocket_App_partnered_with_SAM_Career})
        }
        if (type == 'PHONEPARLOAN') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.PHONEPARLOAN ,messageType:HeaderType.PHONEPARLOAN})
        }
        if (type == 'REPEAT_CAMPAIGN') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.REPEAT_CAMPAIGN ,messageType:HeaderType.REPEAT_CAMPAIGN})
        }
        if (type == 'TWICE_APPROVED_AMOUNT') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.TWICE_APPROVED_AMOUNT ,messageType:HeaderType.TWICE_APPROVED_AMOUNT})
        }
        if (type == 'MAXIMIZE_APPROVED_AMOUNT') {
            communicateByCsv.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.MAXIMIZE_APPROVED_AMOUNT ,messageType:HeaderType.MAXIMIZE_APPROVED_AMOUNT})
        }
        this.setState({ communicateByCsv })
    }
    headerHandler(type) {
        this.setState({ headerType: type.target.value })
    }

    render() {
        return (
            <div className="">
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <BulkCommCSV
                    headerHandler={this.headerHandler.bind(this)}
                    mailTempleteClick={this.mailTempleteClick.bind(this)}
                    csvCommunicate={this.csvCommunicate.bind(this)}
                    communicateByCsvHandler={this.communicateByCsvHandler.bind(this)}
                    communicateTypeHandler={this.communicateTypeHandler.bind(this)}
                    msgBoxState={this.state.msgBoxState}
                    mailBoxSate={this.state.mailBoxSate}
                    communicateByCsv={this.state.communicateByCsv}
                    createLink={this.createLink.bind(this)}
                    uploadNotificationImage={this.uploadNotificationImage.bind(this)}
                    notificationImage={this.state.notificationImage}
                    btnDisabled={this.state.btnDisabled}
                    uploadedCSV={this.state.uploadedCSV}
                    uploadcsvHandler={this.uploadcsvHandler.bind(this)}
                />
                {this.state.confirmationPopup == true ?
                    <ConfirmationPopup
                        cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
                        confirmationText={this.state.confirmationText} />
                    : ""}
                {this.state.MainLoaderState ?
                    <MainLoader />
                    : null}
            </div>
        )
    }

    uploadcsvHandler(e) {
        let val = e.target.value.split(/[\\\/]/)[2];
        let docType = val.split(".");
        let fileType = docType[docType.length - 1];
        if (fileType == 'csv') {
            this.setState({
                uploadedCSV: val,
                csvFile: e.target.files[0]
            })
        }
        let File = e.target.files[0];
    }

    uploadNotificationImage(e) {
        this.setState({ typeOfFile: e.target.files[0].type })

        let val = e.target.value.split(/[\\\/]/)[2];
        this.setState({ notificationImage: val })
        let File = e.target.files[0];
        let docType = val.split(".");
        let fileType = docType[docType.length - 1];
        if (fileType == "jpeg" || fileType == "jpg" || fileType == "png") {
            notificationImageUploadApi(File, val, this.state.typeOfFile,
                (callBack) => {
                    this.setState({
                        notificationImage: callBack
                    })
                });
        }
    }

    communicateTypeHandler(boxType) {
        let communicateByCsv = Object.assign({}, this.state.communicateByCsv);
        if (boxType == 'mailNotification') {
            communicateByCsv.type = []
            communicateByCsv.body = ''
            this.setState({
                mailBoxSate: true,
                msgBoxState: false,
            })
        } else if (boxType == 'msg') {
            communicateByCsv.type = ["Message"]
            this.setState({
                msgBoxState: true,
                mailBoxSate: false,
            })
        }
        this.setState({ communicateByCsv })
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    createLink() {
        var selection = document.getSelection();
        document.execCommand('createLink', true, this.state.url);
        selection.anchorNode.parentElement.target = '_blank';
    }

    csvCommunicate(e, comType) {
        let communicateByCsv = Object.assign({}, this.state.communicateByCsv);
        if (comType == 'title') {
            communicateByCsv.title = e.target.value;
        } else if (comType == 'body') {
            communicateByCsv.body = e.target.value;
        } else if (comType === "url") {
            this.setState({ url: e.target.value });
        } else if (comType == 'communicationType') {
            let comunicationType = []
            let notification = document.getElementById("bycsvSendNotification")
            let mail = document.getElementById("bycsvSendMail")
            if (notification != null) {
                if (notification.checked == true) {
                    if (comunicationType.includes(notification.value)) {

                    } else {
                        comunicationType.push(notification.value)
                    }
                } else {
                    let notificationIndex = comunicationType.indexOf(notification.value)
                    if (notificationIndex >= 0) {
                        comunicationType.splice(notificationIndex, 1);
                    }
                }
            }
            if (mail != null) {
                if (mail.checked == true) {
                    if (comunicationType.includes(mail.value)) {

                    } else {
                        comunicationType.push(mail.value)
                    }
                } else {
                    let mailIndex = comunicationType.indexOf(mail.value)
                    if (mailIndex >= 0) {
                        comunicationType.splice(mailIndex, 1);
                    }
                }
            }
            communicateByCsv.type = comunicationType
        }
        this.setState({ communicateByCsv })
    }

    communicateByCsvHandler(type) {
        if (this.state.csvFile != '') {
            if (type == 'phocket') {
                this.setState({
                    requestFrom: 0
                })
            } else {
                this.setState({
                    requestFrom: 1
                })
            }
            this.setState({
                confirmationPopup: true,
                confirmationText: 'Are you sure you want to sent to All.'
            })
        } else {
            this.setState({
                popupState: true,
                popupStatus: 'Please select CSV file',
            })
            this.removePopup();
        }
    }

    cancelLoanConfirm(conType) {
        const { admin } = this.props
      
        if (conType == 'yes') {
            if (this.state.mailBoxSate == true) {
                if (this.state.communicateByCsv.type.length > 0) {
                    // console.log(this.state.csvFile)
                    // console.log(this.state.communicateByCsv)
                    // console.log(this.state.requestFrom)
                    // console.log(this.state.notificationImage)
                    // console.log(document.getElementById("richText").innerHTML)
                    // if (this.state.headerType !== '') {
                        this.setState({
                            MainLoaderState: true
                        })
                        bulkCommunicateByCsvApi(
                            this.state.csvFile,
                            this.state.communicateByCsv,
                            this.state.requestFrom,
                            this.state.notificationImage,
                            document.getElementById("richText").innerHTML,
                            admin,
                            this.state.templateIdTosend,
                            this.state.messageType==='Promotional'?'Promotional':'Phocket',
                            callBack => {
                                if (callBack == 'success') {
                                    this.setState({
                                        popupState: true,
                                        popupStatus: 'Communicated successfully',
                                        csvFile: '',
                                        uploadedCSV: '',
                                        communicateByCsv: {
                                            title: '',
                                            body: '',
                                            type: ["Message"],
                                        },
                                        msgBoxState: false,
                                        mailBoxSate: false,
                                        confirmationPopup: false,
                                        MainLoaderState: false
                                    })
                                    this.removePopup();
                                } else {
                                    this.setState({
                                        popupState: true,
                                        popupStatus: 'Please try again',
                                        confirmationPopup: false,
                                        MainLoaderState: false
                                    })
                                    this.removePopup();
                                }
                            })
                    // }else{
                    //     this.setState({
                    //         popupState: true,
                    //         popupStatus: 'Select Header Type'},()=>this.removePopup())
                    // }
                    if (document.getElementById("bycsvSendNotification") != null) {
                        document.getElementById("bycsvSendNotification").checked = false
                    }
                    if (document.getElementById("bycsvSendMail") != null) {
                        document.getElementById("bycsvSendMail").checked = false
                    }
                } else {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Please select type',
                    })
                    this.removePopup();
                }
            }
            if (this.state.msgBoxState == true) {
                // if (this.state.headerType !== '') {
                    this.setState({
                        MainLoaderState: true
                    })
                bulkCommunicateByCsvApi(
                    this.state.csvFile,
                    this.state.communicateByCsv,
                    this.state.requestFrom,
                    this.state.notificationImage,
                    this.state.communicateByCsv.body,
                    admin,
                    this.state.templateIdTosend,
                    this.state.messageType==='Promotional'?'Promotional':'Phocket',
                    callBack => {
                        if (callBack == 'success') {
                            this.setState({
                                popupState: true,
                                popupStatus: 'Message send successfully',
                                csvFile: '',
                                uploadedCSV: '',
                                communicateByCsv: {
                                    title: '',
                                    body: '',
                                    type: ["Message"],
                                },
                                msgBoxState: false,
                                mailBoxSate: false,
                                confirmationPopup: false,
                                MainLoaderState: false
                            })
                            this.removePopup();
                        } else {
                            this.setState({
                                popupState: true,
                                popupStatus: 'Please try again',
                                confirmationPopup: false,
                                MainLoaderState: false
                            })
                            this.removePopup();
                        }
                    })
                // }else{
                //     this.setState({
                //         popupState: true,
                //         popupStatus: 'Select Header Type'},()=>this.removePopup())
                // }
            }
        } else if (conType == 'no') {
            this.setState({ confirmationPopup: false })
        }
    }
}

export default CommunicationByCSV;