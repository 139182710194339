import React, { useCallback, useState } from 'react'
import { HeaderType, MSG_STRING, TemplateId } from '../../../../../presentationals/Admin/BulkActions/MessageTemplates.component'
import { statusWiseCommunicationMarketSms } from '../../actioncreator'

const Smspage = ({ admin,userIdList,payload, communicateViaFilter, status }) => {
    const [msgBody, setMsgBody] = useState('')
    const [templateId, settemplateIdTosend] = useState('')
    const [msgTypeHeader, setmessageType] = useState('')
    const [typeList, setTypeList] = useState(["Message"])
    const [mailBody, setmailBody] = useState('')
    const [imageUrl, setimageUrl] = useState('')
    const [subject, setsubject] = useState('')
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    // const [userIdList, setuserIdList] = useState([])

    const getBranchCommunicateForBreakDown = () => {
        // statusWiseCommunicationMarketSms(typeList, msgBody, subject, admin.emailId, imageUrl, userIdList, templateId, msgTypeHeader,payload, communicateViaFilter, status, callback => {

        // })
        

    };
    
    const sendHandlerMsg = (type) => {
        getBranchCommunicateForBreakDown()
    }
    const smsTemplateHandle = (e) => {
        console.log(e)
        if (e.target.value === '') {
            setMsgBody('')
            settemplateIdTosend('')
            setmessageType('')
        }
        if (e.target.value == 'REVIEW_ON_PLAYSTORE') {
            setMsgBody(MSG_STRING.REVIEW_ON_PLAYSTORE)
            settemplateIdTosend(TemplateId.REVIEW_ON_PLAYSTORE)
            setmessageType(HeaderType.REVIEW_ON_PLAYSTORE)
        }

        if (e.target.value == 'MONTH_END_SAL_ADVANCE') {
            setMsgBody(MSG_STRING.MONTH_END_SAL_ADVANCE)
            settemplateIdTosend(TemplateId.MONTH_END_SAL_ADVANCE)
            setmessageType(HeaderType.MONTH_END_SAL_ADVANCE)
        }
        if (e.target.value == 'BILL_PAY_SAL_ADVANCE') {
            setMsgBody(MSG_STRING.BILL_PAY_SAL_ADVANCE)
            settemplateIdTosend(TemplateId.BILL_PAY_SAL_ADVANCE)
            setmessageType(HeaderType.BILL_PAY_SAL_ADVANCE)
        }
        if (e.target.value == 'PHOCKET_APP_TRY_KARO') {
            setMsgBody(MSG_STRING.PHOCKET_APP_TRY_KARO)
            settemplateIdTosend(TemplateId.PHOCKET_APP_TRY_KARO)
            setmessageType(HeaderType.PHOCKET_APP_TRY_KARO)
        }
        if (e.target.value == 'JHATPAT_LOAN') {
            setMsgBody(MSG_STRING.JHATPAT_LOAN)
            settemplateIdTosend(TemplateId.JHATPAT_LOAN)
            setmessageType(HeaderType.JHATPAT_LOAN)
        }
        if (e.target.value == 'FESTIVE_SHOPPING_LOAN') {
            setMsgBody(MSG_STRING.FESTIVE_SHOPPING_LOAN)
            settemplateIdTosend(TemplateId.ELIGIBLE_FOR_ADVANCE_SAL_2_LAC)
            setmessageType(HeaderType.FESTIVE_SHOPPING_LOAN)
        }
        if (e.target.value == 'FESTIVE_SHOPPING_LOAN') {
            setMsgBody(MSG_STRING.FESTIVE_SHOPPING_LOAN)
            settemplateIdTosend(TemplateId.FESTIVE_SHOPPING_LOAN)
            setmessageType(HeaderType.FESTIVE_SHOPPING_LOAN)
        }
        if (e.target.value == 'LOAN_WITHIN_FEW_MINUTES') {
            setMsgBody(MSG_STRING.LOAN_WITHIN_FEW_MINUTES)
            settemplateIdTosend(TemplateId.LOAN_WITHIN_FEW_MINUTES)
            setmessageType(HeaderType.LOAN_WITHIN_FEW_MINUTES)
        }
        if (e.target.value == 'LAST_CHANCE_LOAN') {
            setMsgBody(MSG_STRING.LAST_CHANCE_LOAN)
            settemplateIdTosend(TemplateId.LAST_CHANCE_LOAN)
            setmessageType(HeaderType.LAST_CHANCE_LOAN)
        }
        if (e.target.value == 'UPTO_200000_LOAN') {
            setMsgBody(MSG_STRING.UPTO_200000_LOAN)
            settemplateIdTosend(TemplateId.UPTO_200000_LOAN)
            setmessageType(HeaderType.UPTO_200000_LOAN)
        }
        if (e.target.value == 'LOAN_WAITING') {
            setMsgBody(MSG_STRING.LOAN_WAITING)
            settemplateIdTosend(TemplateId.LOAN_WAITING)
            setmessageType(HeaderType.LOAN_WAITING)
        }
        if (e.target.value == 'LOAN_AT_LOW_INTEREST') {
            setMsgBody(MSG_STRING.LOAN_AT_LOW_INTEREST)
            settemplateIdTosend(TemplateId.LOAN_AT_LOW_INTEREST)
            setmessageType(HeaderType.LOAN_AT_LOW_INTEREST)

        }
        if (e.target.value == 'MONEY_AT_SPEED_OF_ROCKET') {
            setMsgBody(MSG_STRING.MONEY_AT_SPEED_OF_ROCKET)
            settemplateIdTosend(TemplateId.MONEY_AT_SPEED_OF_ROCKET)
            setmessageType(HeaderType.MONEY_AT_SPEED_OF_ROCKET)
        }
        if (e.target.value == 'ADVANCE_SALARY_VIA_BANKBAZAAR') {
            setMsgBody(MSG_STRING.ADVANCE_SALARY_VIA_BANKBAZAAR)
            settemplateIdTosend(TemplateId.ADVANCE_SALARY_VIA_BANKBAZAAR)
            setmessageType(HeaderType.ADVANCE_SALARY_VIA_BANKBAZAAR)
        }
        if (e.target.value == 'URGENT_CASH') {
            setMsgBody(MSG_STRING.URGENT_CASH)
            settemplateIdTosend(TemplateId.URGENT_CASH)
            setmessageType(HeaderType.URGENT_CASH)
        }
        if (e.target.value == 'LOAN_WITHIN_5_MINUTES') {
            setMsgBody(MSG_STRING.LOAN_WITHIN_5_MINUTES)
            settemplateIdTosend(TemplateId.LOAN_WITHIN_5_MINUTES)
            setmessageType(HeaderType.LOAN_WITHIN_5_MINUTES)
        }
        if (e.target.value == 'BANKBAZAAR') {
            setMsgBody(MSG_STRING.BANKBAZAAR)
            settemplateIdTosend(TemplateId.BANKBAZAAR)
            setmessageType(HeaderType.BANKBAZAAR)
        } if (e.target.value == 'SALARY_12_MONTH') {
            setMsgBody(MSG_STRING.SALARY_12_MONTH)
            settemplateIdTosend(TemplateId.SALARY_12_MONTH)
            setmessageType(HeaderType.SALARY_12_MONTH)
        }
        if (e.target.value == 'GET_A_PHOCKET_PL') {
            setMsgBody(MSG_STRING.GET_A_PHOCKET_PL)
            settemplateIdTosend(TemplateId.GET_A_PHOCKET_PL)
            setmessageType(HeaderType.GET_A_PHOCKET_PL)
        }
        if (e.target.value == 'ADVANCE_SAL_BY_BUDDYLOAN') {
            setMsgBody(MSG_STRING.ADVANCE_SAL_BY_BUDDYLOAN)
            settemplateIdTosend(TemplateId.ADVANCE_SAL_BY_BUDDYLOAN)
            setmessageType(HeaderType.ADVANCE_SAL_BY_BUDDYLOAN)
        }
        if (e.target.value == 'PRE_QUALIFIED_BUDDY_LOAN_PHOCKET') {
            setMsgBody(MSG_STRING.PRE_QUALIFIED_BUDDY_LOAN_PHOCKET)
            settemplateIdTosend(TemplateId.PRE_QUALIFIED_BUDDY_LOAN_PHOCKET)
            setmessageType(HeaderType.PRE_QUALIFIED_BUDDY_LOAN_PHOCKET)
        }
        if (e.target.value == 'PHOCKET_BANKBAZAAR') {
            setMsgBody(MSG_STRING.PHOCKET_BANKBAZAAR)
            settemplateIdTosend(TemplateId.PHOCKET_BANKBAZAAR)
            setmessageType(HeaderType.PHOCKET_BANKBAZAAR)
        }
        if (e.target.value == 'LOAN_FOR_15000_SALARY') {
            setMsgBody(MSG_STRING.LOAN_FOR_15000_SALARY)
            settemplateIdTosend(TemplateId.LOAN_FOR_15000_SALARY)
            setmessageType(HeaderType.LOAN_FOR_15000_SALARY)
        }
        if (e.target.value == 'Phocket_App_partnered_with_Anjraj_Talent') {
            setMsgBody(MSG_STRING.Phocket_App_partnered_with_Anjraj_Talent)
            settemplateIdTosend(TemplateId.Phocket_App_partnered_with_Anjraj_Talent)
            setmessageType(HeaderType.Phocket_App_partnered_with_Anjraj_Talent)
        }
        if (e.target.value == 'Dear_Customer_Refer_your_friends') {
            setMsgBody(MSG_STRING.Dear_Customer_Refer_your_friends)
            settemplateIdTosend(TemplateId.Dear_Customer_Refer_your_friends)
            setmessageType(HeaderType.Dear_Customer_Refer_your_friends)
        }
        if (e.target.value == 'Phocket_App_partnered_with_Genesis') {
            setMsgBody(MSG_STRING.Phocket_App_partnered_with_Genesis)
            settemplateIdTosend(TemplateId.Phocket_App_partnered_with_Genesis)
            setmessageType(HeaderType.Phocket_App_partnered_with_Genesis)
        }
        if (e.target.value == 'Phocket_App_partnered_with_Hyrexpert') {
            setMsgBody(MSG_STRING.Phocket_App_partnered_with_Hyrexpert)
            settemplateIdTosend(TemplateId.Phocket_App_partnered_with_Hyrexpert)
            setmessageType(HeaderType.Phocket_App_partnered_with_Hyrexpert)
        }
        if (e.target.value == 'Phocket_App_partnered_with_Karyarth') {
            setMsgBody(MSG_STRING.Phocket_App_partnered_with_Karyarth)
            settemplateIdTosend(TemplateId.Phocket_App_partnered_with_Karyarth)
            setmessageType(HeaderType.Phocket_App_partnered_with_Karyarth)
        }
        if (e.target.value == 'Phocket_App_partnered_with_SAM_Career') {
            setMsgBody(MSG_STRING.Phocket_App_partnered_with_SAM_Career)
            settemplateIdTosend(TemplateId.Phocket_App_partnered_with_SAM_Career)
            setmessageType(HeaderType.Phocket_App_partnered_with_SAM_Career)
        }
        if (e.target.value == 'PHONEPARLOAN') {
            setMsgBody(MSG_STRING.PHONEPARLOAN)
            settemplateIdTosend(TemplateId.PHONEPARLOAN)
            setmessageType(HeaderType.PHONEPARLOAN)
        }
        if (e.target.value == 'REPEAT_CAMPAIGN') {
            setMsgBody(MSG_STRING.REPEAT_CAMPAIGN)
            settemplateIdTosend(TemplateId.REPEAT_CAMPAIGN)
            setmessageType(HeaderType.REPEAT_CAMPAIGN)
            forceUpdate()
        }
        if (e.target.value == 'TWICE_APPROVED_AMOUNT') {
            setMsgBody(MSG_STRING.TWICE_APPROVED_AMOUNT)
            settemplateIdTosend(TemplateId.TWICE_APPROVED_AMOUNT)
            setmessageType(HeaderType.TWICE_APPROVED_AMOUNT)
            forceUpdate()
        }
        if (e.target.value == 'MAXIMIZE_APPROVED_AMOUNT') {
            setMsgBody(MSG_STRING.MAXIMIZE_APPROVED_AMOUNT)
            settemplateIdTosend(TemplateId.MAXIMIZE_APPROVED_AMOUNT)
            setmessageType(HeaderType.MAXIMIZE_APPROVED_AMOUNT)
            forceUpdate()
        }
     
    }
    return (
        <div className=' marketing-main-css '>
            <div className='row'>
                <div className='col-sm-9'>
                    <div className='cards-shadow ' style={{ padding: '15px', marginTop: '20px' }}>
                        <label className="form-label">Select SMS Template </label>
                        <select className="form-select" onChange={(e) => smsTemplateHandle(e)} >
                            <option value="" >Select From Below Options</option>
                            {Object.entries(TemplateId).map((data, i) => {
                               
                                return (
                                    <option value={data[0]}>{data[0]}</option>
                                )
                            })}
                        </select>
                        <div>
                            {msgBody ?
                                <textarea
                                    value={msgBody}
                                    rows="6"
                                    readOnly={true}
                                    style={{
                                        width: "100%",
                                        backgroundColor: "rgba(219, 233, 255, 0.70)",
                                        borderRadius: "6px",
                                        border: "none",
                                        marginTop: '20px',
                                        padding: '10px'

                                    }}
                                ></textarea>
                                : ""}
                            {msgBody ?
                                <button
                                    title="SEND"
                                    onClick={() => sendHandlerMsg()}
                                    type="button"
                                    className="bulkCommSendBtn"
                                    style={{ width: '140px', marginTop: '20px' }}
                                >
                                    Send Message
                                </button>
                                : ""}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default Smspage