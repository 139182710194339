import { APIS } from "../../../../../utils/api-factory"
import { POST_HEADER } from "../../../../../utils/apiHeaders"

export const getPersonalAnalysisReport = (
  userId,
  callBack
) => {
  fetch(APIS.GET_PERSONAL_ANALYSIS_REPORT + userId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
// export const getDashboardVerifyData = (
//   userId,
//   callBack
// ) => {
//   fetch(APIS.GET_ANALYSIS_DASHBOARD_MATCH_DATA + userId)
//     .then(res => res.json())
//     .then(json => {
//       callBack(json)
//     })
//     .catch((e) => {
//       callBack('')
//     })
// }
export const getDashboardVerifyData = (userId, loanId, callBack) => {
  return dispatch => {
    fetch(APIS.GET_ANALYSIS_DASHBOARD_MATCH_DATA + userId + '&loanId=' + loanId)
      .then(res => res.json())
      .then(json => {
        json.userId = userId
        dispatch({ type: 'GET_CREDIT_DASHBOARD_DATA', creditDashBoardDataValue: json })
        callBack(json)
      })
  }
}
export const getConcludeDataApi = (
  userId,
  callBack
) => {
  fetch(APIS.GET_CONCLUDED_DATA + userId)
    .then(res => res.json())
    .then(json => {
      json.userId = userId
      dispatch({ type: 'GET_CREDIT_CONCLUDED_DATA', creditConcludedData: json })
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const getSmsAnalysisStatus = (
  userId,
  callBack
) => {
  fetch(APIS.GET_SMS_ANALYSIS_STATUS + userId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const getCrifAnalysisStatus = (
  userId,
  callBack
) => {
  fetch(APIS.GET_CRIF_ANALYSIS_STATUS + userId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const getFinbitAnalysis = (
  userId,
  callBack
) => {
  fetch(APIS.GET_FINBIT_ANALYSIS_DATA + userId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const similarProfileData = (
  loanId,
  callBack
) => {
  fetch(APIS.GET_SIMILAR_ANALYSIS_DATA + loanId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const saveCommentForCreditAssesment = (loanId, userId, notesText, emailId, notesType, callBack) => {
  var data = {
    loanId: loanId,
    userId: userId,
    notes: notesText,
    empId: emailId,
    notesType: notesType

  }
  fetch(APIS.SAVE_CREDIT_ASSESMENT_NOTES, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}
export const getNotes = (
  loanId,
  userId,
  notesType,
  callBack
) => {
  var data = {


  }
  // fetch(APIS.GET_CREDIT_ASSESSMENT_COMMENT + loanId + ' &userId=' + userId + '&notesType=' + notesType)
  //   .then(res => res.json())
  //   .then(json => {
  //     callBack(json)
  //   })
  //   .catch((e) => {
  //     callBack('')
  //   })
  fetch(APIS.GET_CREDIT_ASSESSMENT_COMMENT + loanId + '&userId=' + userId + '&notesType=' + notesType, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(text => {
      callBack(text)
    })
}
export const getCreditAnalysisData = (
  userId,
  loanId,
  policyId,
  callBack
) => {
  fetch(APIS.GET_CREDIT_ANALYSIS_DATA + userId + '&loanId=' + loanId + '&policyId=' + policyId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const getAllPolicyApi = (

  callBack
) => {
  fetch(APIS.GET_ALL_POLICY_LIST)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}

export const updatePolicyDetails = (data, callBack) => {

  fetch(APIS.SAVE_OR_UPDATE_CREDIT_POLICY_LIST, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}