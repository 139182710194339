import { APIS } from "../../../utils/api-factory"
import { POST_HEADER } from "../../../utils/apiHeaders"

export const saveAgreegatorDataApi = (agreegatorData, callBack) => {
  fetch(APIS.SAVE_AGREEGATOR_DATA, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(agreegatorData)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}

export const getUrlApi = (file, name, fileType, callBack) => {
  return new Promise((resolve, reject) => {
    let imageFormData = new FormData()
    imageFormData.append('uploadfile', file)
    imageFormData.append('type', fileType)
    imageFormData.append('fileName', name)
    var xhr = new XMLHttpRequest()

    xhr.open('post', APIS.GENERIC_FILE_UPLOAD_AND_GET_URL, true)
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
      }
    }
    xhr.send(imageFormData)
  })
}


export const getMarketListing = (pageNumber, noOfEntry, callBack) => {

  fetch(
    APIS.GET_MARKETING_LIST + pageNumber + '&count=' + noOfEntry)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })

}
export const getAgreegatorListApi = (callBack) => {

  fetch(
    APIS.GET_AGREEGATOR_LIST)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })

}
export const saveBugetPlan = (budegtPlanData, callBack) => {
  fetch(APIS.SAVE_BUDGET_PLAN, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(budegtPlanData)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}
export const getEventDataByPartnerId = (partnerId, callBack) => {

  fetch(
    APIS.GET_EVENT_DATA_BY_PARTNER_ID + partnerId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })

}
export const projectionData = (months,startDate, endDate, callBack) => {

  fetch(
    APIS.PROJECTION_DATA_MONTHS + months + '&startDate=' + startDate + '&endDate=' + endDate)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })

}
export const projectionDataDaywise = (months, startDate, endDate, callBack) => {

  fetch(
    APIS.PROJECTION_DAY_WISE + months + '&startDate=' + startDate + '&endDate=' + endDate)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })

}