import React from 'react'
import LoanDueWithoutNach from '../../MailTemplets/LoanDueWithoutNach'
import HalfSalaryMail from '../../MailTemplets/HalfSalaryMail'
import ExecutiveContactMail from '../../MailTemplets/ExecutiveContactMail'
import DocumentMail from '../../MailTemplets/DocumentMail'
import AutoDebitWithNach from '../../MailTemplets/AutoDebitWithNach'
import SecurityChequeClarification from '../../MailTemplets/SecurityChequeClarification'
import NachRegistrationClarification from '../../MailTemplets/NachRegistrationClarification'
import RepeatApplication from '../../MailTemplets/RepeateApplicaton'
import EmiLoanDueWithoutNach from '../../MailTemplets/EmiLoanDueWithoutNach'
import EmiAutoDebitWithNach from '../../MailTemplets/EmiAutoDebitWithNach'
import RazorpayMail from '../../MailTemplets/RazorpayM'
import SecurityCheque from '../../MailTemplets/SecurityCheque'
import Covid from '../../MailTemplets/CovidMail'
import ReferenceMail from '../../MailTemplets/userReferenceMail'
import LastStep from '../../MailTemplets/lastStepMail'


const DocumentMailPage = ({ sendHandler, messageHandler, DocsCheckHandler, title,
    allDetail, mailTempleteClick, mailViewState, adharTextState, panTextState,
    bankTextState, salarySlipTextState, halfSalaryState, officeIdTextState, CurrentAddressProofState, approveAmount, link }) => (
    <div className="container-fluid">
        <div className="row">
            <div className="col-sm-5 col-xs-12">
                <h4 className=" text-center blue-text"><strong>Templates</strong></h4>
                <ol>
                {/* {approveAmount !== null && approveAmount !== '' ? */}
                                <li>
                                    <input type="radio" value={allDetail != null && allDetail.applicationId != null ? "Application Id: " + allDetail.applicationId + " | Regarding your Phocket Loan Application" : ""} name="communicationMail" onClick={e => mailTempleteClick(e, 'reference')} /> &nbsp;&nbsp;
                                    <span><strong>Three Persons Reference</strong></span>
                                </li>
                                {/* : ""} */}
                    {/* <li>
                            <input type="radio" value="Reminder: Phocket Loan Due." name="communicationMail" onClick={e => mailTempleteClick(e, 'loanDueWithoutNach')} /> &nbsp;&nbsp;
                        <span><strong>Reminder: Phocket Loan Due (without NACH).</strong></span>
                        </li>
                        <li>
                            <input type="radio" value="Reminder: Auto debit of your Phocket Loan Repayment amount." name="communicationMail" onClick={e => mailTempleteClick(e, 'autoDebitWithNach')} /> &nbsp;&nbsp;
                        <span><strong>Reminder: Auto debit of your Phocket Loan Repayment amount (with NACH).</strong></span>
                        </li>
                        <li>
                            <input type="radio" value="Reminder: Phocket Loan EMI Due." name="communicationMail" onClick={e => mailTempleteClick(e, 'emiLoanDueWithoutNach')} /> &nbsp;&nbsp;
                        <span><strong>Reminder: Phocket Loan EMI Due (without NACH).</strong></span>
                        </li>
                        <li>
                            <input type="radio" value="Reminder: Auto debit of your Phocket Loan Repayment amount." name="communicationMail" onClick={e => mailTempleteClick(e, 'emiAutoDebitWithNach')} /> &nbsp;&nbsp;
                        <span><strong>Reminder: Auto debit of your Phocket Loan EMI (with NACH).</strong></span>
                        </li> */}
                    <li>
                        <input type="radio" value="Phocket : Security Cheque Clarification" name="communicationMail" onClick={e => mailTempleteClick(e, 'securityChequeClarification')} /> &nbsp;&nbsp;
                        <span><strong>Phocket : Security Cheque Clarification</strong></span>
                    </li>
                    <li>
                        <input type="radio" value="NACH Registration Clarification" name="communicationMail" onClick={e => mailTempleteClick(e, 'nachRegistrationClarification')} /> &nbsp;&nbsp;
                        <span><strong>NACH Registration Clarification</strong></span>
                    </li>
                    <li>
                        <input type="radio" value={allDetail != null && allDetail.applicationId != null ? "Application Id: " + allDetail.applicationId + " | Regarding your Phocket Loan Application" : ""} name="communicationMail" onClick={e => mailTempleteClick(e, 'halfSalaryMail')} /> &nbsp;&nbsp;
                        <span><strong>100% Salary Mail</strong></span>
                    </li>
                    <li>
                        <input type="radio" value={allDetail != null && allDetail.applicationId != null ? "Application Id: " + allDetail.applicationId + " | Regarding your Phocket Loan Application" : ""} name="communicationMail" onClick={e => mailTempleteClick(e, 'executiveContactMail')} /> &nbsp;&nbsp;
                        <span><strong>Executive Contact Mail</strong></span>
                    </li>
                    <li>
                        <input type="radio" value={allDetail != null && allDetail.applicationId != null ? "Application Id: " + allDetail.applicationId + " | Regarding your Phocket Loan Application" : ""} name="communicationMail" onClick={e => mailTempleteClick(e, 'repeatApplication')} /> &nbsp;&nbsp;
                        <span><strong>Repeat Application</strong></span>
                    </li>
                    <li>
                        <input type="radio" value={allDetail != null && allDetail.applicationId != null ? "Application Id: " + allDetail.applicationId + " | Regarding your Phocket Loan Application" : ""} name="communicationMail" onClick={e => mailTempleteClick(e, 'raizorpaymail')} /> &nbsp;&nbsp;
                        <span><strong>Bank not live with Razorpay's e-mandate</strong></span>
                    </li>

                    <li>
                        <input type="radio" value={allDetail != null && allDetail.applicationId != null ? "Application Id: " + allDetail.applicationId + " | Regarding your Phocket Loan Application" : ""} name="communicationMail" onClick={e => mailTempleteClick(e, 'securityCheque')} /> &nbsp;&nbsp;
                        <span><strong>Phocket : Security Cheque and Nach</strong></span>
                    </li>
                    <li>
                        <input type="radio" value={allDetail != null && allDetail.applicationId != null ? "Application Id: " + allDetail.applicationId + " | Final step to get Loan Amount in your account" : ""} name="communicationMail" onClick={e => mailTempleteClick(e, 'lastStep')} /> &nbsp;&nbsp;
                        <span><strong>Final step to get Loan Amount in your account</strong></span>
                    </li>
                    {/* <li>
                            <input type="radio" value={allDetail != null && allDetail.applicationId != null ? "Application Id: " + allDetail.applicationId + " | Regarding your Phocket Loan Application" : ""} name="communicationMail" onClick={e => mailTempleteClick(e, 'covid19')} /> &nbsp;&nbsp;
                        <span><strong>Phocket : Covid19</strong></span>
                        </li> */}
                    <li>
                  
                        <input type="radio" value={allDetail != null && allDetail.applicationId != null ? "Application Id: " + allDetail.applicationId + " | Regarding your Phocket Loan Application" : ""} name="communicationMail" onClick={e => mailTempleteClick(e, 'DocumentMail')} /> &nbsp;&nbsp;
                        <span><strong>Document Mail</strong></span>
                        <ul>
                            <li id="AadhaarMail">
                                <input className="phocket-checkbox" type="checkbox" id="docAdharCHeck" onChange={(e) => DocsCheckHandler(e, 'adhar')} />
                                <label htmlFor="docAdharCHeck"><span></span></label> &nbsp;&nbsp;
                                <span>Aadhaar Card</span>
                            </li>
                            <li id="PANMail">
                                <input className="phocket-checkbox" type="checkbox" id="docPanCHeck" onChange={(e) => DocsCheckHandler(e, 'pan')} />
                                <label htmlFor="docPanCHeck"><span></span></label> &nbsp;&nbsp;
                                <span>PAN Card</span>
                            </li>
                            <li id="BankMail">
                                <input className="phocket-checkbox" type="checkbox" id="docBankCHeck" onChange={(e) => DocsCheckHandler(e, 'bank')} />
                                <label htmlFor="docBankCHeck"><span></span></label> &nbsp;&nbsp;
                                <span>Bank Statement</span>
                            </li>
                            <li id="SalaryMail">
                                <input className="phocket-checkbox" type="checkbox" id="docSalaryCHeck" onChange={(e) => DocsCheckHandler(e, 'salary')} />
                                <label htmlFor="docSalaryCHeck"><span></span></label> &nbsp;&nbsp;
                                <span>Salary Slip</span>
                            </li>
                            {/* <li id="OfficeIdMail">
                                <input className="phocket-checkbox" type="checkbox" id="docOfficeIdCHeck" onChange={(e) => DocsCheckHandler(e, 'officeId')} />
                                <label htmlFor="docOfficeIdCHeck"><span></span></label> &nbsp;&nbsp;
                                <span>Official Id</span>
                            </li> */}
                            <li id="CurrentAddressProof">
                                <input className="phocket-checkbox" type="checkbox" id="CurrentAddressProofId" onChange={(e) => DocsCheckHandler(e, 'CurrentAddressProof')} />
                                <label htmlFor="CurrentAddressProofId"><span></span></label> &nbsp;&nbsp;
                                <span>Current Address Proof</span>
                            </li>
                            <li id="HalfSalaryMail">
                                <input className="phocket-checkbox" type="checkbox" id="docHalfSalaryCHeck" onChange={(e) => DocsCheckHandler(e, 'halfSalary')} />
                                <label htmlFor="docHalfSalaryCHeck"><span></span></label> &nbsp;&nbsp;
                                <span>100% Salary</span>
                            </li>
                         
                        </ul>
                    </li>
                </ol>
            </div>
            <div className="col-sm-7 col-xs-12 no-padding doc-mail-admin">
                <h4 className=" text-center blue-text"><strong>Mail</strong></h4>
                <div className="col-xs-12 form-group">
                    <input type="text" className="form-control white-bg-transparent" value={title} placeholder="Enter Title" onChange={e => messageHandler(e, "title")} />
                </div>
                <div className="col-xs-12">
                    <div className="richText height-250px" contentEditable={true} id="communicationMail" suppressContentEditableWarning={true}>
                        {mailViewState == 'loanDueWithoutNach' ?
                            <LoanDueWithoutNach
                                allDetail={allDetail}
                            />
                            : mailViewState == 'autoDebitWithNach' ?
                                <AutoDebitWithNach
                                    allDetail={allDetail}
                                />
                                : mailViewState == 'emiAutoDebitWithNach' ?
                                    <EmiAutoDebitWithNach
                                        allDetail={allDetail}
                                    />
                                    : mailViewState == 'emiLoanDueWithoutNach' ?
                                        <EmiLoanDueWithoutNach
                                            allDetail={allDetail}
                                        />
                                        : mailViewState == 'securityChequeClarification' ?
                                            <SecurityChequeClarification
                                                allDetail={allDetail}
                                            />
                                            : mailViewState == 'raizorpaymail' ?
                                                <RazorpayMail
                                                    allDetail={allDetail}
                                                />
                                                : mailViewState == 'covid19' ?
                                                    <Covid
                                                        allDetail={allDetail}
                                                    />
                                                    : mailViewState == 'securityCheque' ?
                                                        <SecurityCheque
                                                            allDetail={allDetail}
                                                        />
                                                        : mailViewState == 'nachRegistrationClarification' ?
                                                            <NachRegistrationClarification
                                                                allDetail={allDetail}
                                                            />
                                                            : mailViewState == 'halfSalaryMail' ?
                                                                <HalfSalaryMail
                                                                    allDetail={allDetail}
                                                                />
                                                                : mailViewState == 'reference' ?
                                                                    <ReferenceMail
                                                                        link={link}
                                                                        approveAmount={approveAmount}
                                                                        allDetail={allDetail}
                                                                    />
                                                                    : mailViewState == 'executiveContactMail' ?
                                                                        <ExecutiveContactMail
                                                                            allDetail={allDetail}
                                                                        />
                                                                        : mailViewState == 'repeatApplication' ?
                                                                            <RepeatApplication
                                                                                allDetail={allDetail}
                                                                            />
                                                                            : mailViewState == 'lastStep' ?
                                                                            <LastStep
                                                                                allDetail={allDetail}
                                                                            />


                                                                            : mailViewState == 'DocumentMail' ?
                                                                                <DocumentMail
                                                                                    allDetail={allDetail}
                                                                                    adharTextState={adharTextState}
                                                                                    panTextState={panTextState}
                                                                                    bankTextState={bankTextState}
                                                                                    salarySlipTextState={salarySlipTextState}
                                                                                    halfSalaryState={halfSalaryState}
                                                                                    officeIdTextState={officeIdTextState}
                                                                                    CurrentAddressProofState={CurrentAddressProofState}
                                                                                />
                                                                                : ""}
                    </div>
                </div>
                <div className="col-xs-12">
                    <button className="profile-save-btn center-block" id="submitDetail" onClick={e => sendHandler('mail')}>
                        <span className="fa fa-send-o"></span>&nbsp;&nbsp;Send
                    </button>
                </div>
            </div>
        </div>
    </div>
)

export default DocumentMailPage;