import React from 'react'
import { MAIL_STRING } from '../../../utils/Constant'
import moment from 'moment'

// subject: Phocket: Cheque Deposit Alert

const ChequeDepositeAlert = ({ allDetail }) => (
    <div>
        <div>Hello {allDetail != null && allDetail.userName != null ? allDetail.userName : ""},</div>
        <br />
        <div>We tried contacting you but there was no response from your side. </div>
        <br />
        <div>Your loan amount is overdue.</div>
        <br />
        <div>Get in touch with us asap, if no revert from your side, we will deposit the cheque on {moment(new Date()).add(1,'days').format('DD-MM-YYYY')}.</div>
        <br />
        <div>Maintain sufficient balance so that cheque does not bounce.</div>
        <br />
        <div>Cheque bounce law have been made very stringent by Government of India and it now attracts hefty penalties and prosecution upto 2 years.</div>
        <br />
        <div>Best Regards,</div>
        <div>Team Phocket</div>
        <div>{MAIL_STRING.MOBILE_NO}</div>
    </div>
)

export default ChequeDepositeAlert