import React from 'react'
import DatePicker from 'react-datepicker';

const ApproveDataPopup = ({ emiAmountChange, emiAmountHandler, closeEmiPopup, PaidData }) => (
        <div className="">
            <div className="background-blur" onClick={e => closeEmiPopup()}></div>
            <div className="popup-main small-popup container-fluid dbl-border">
                <h3 className="text-center blue-text">Enter Details</h3>
                <div className="row form-group">
                    <div className="col-xs-12 col-sm-6">
                        <label className="font-12px">Enter Paid EMI Date</label>
                        <DatePicker
                            value={PaidData.emiPaidDateToSend}
                            selected={PaidData.emiPaidDate}
                            onChange={(e) => emiAmountChange(e, 'emiPaidDate')}
                            className="form-control"
                            placeholderText="select Date"
                            dateFormat="DD/MM/YYYY"
                        />
                    </div>
                    <div className="col-xs-12 col-sm-6">
                        <label className="font-12px">Enter Paid EMI Amount</label>
                        <input type="number" className="form-control" onChange={(e) => emiAmountChange(e, 'emiAmount')} value={PaidData.emiPaidAmountState} />
                    </div>
                </div>
                <div className="row form-group">
                    <div className="col-xs-12 col-sm-6">
                        <label className="font-12px">Intrest Amount</label>
                        <input type="number" className="form-control" onChange={(e) => emiAmountChange(e, 'intrestAmount')} value={PaidData.interestAmount} />
                    </div>
                    <div className="col-xs-12 col-sm-6">
                        <label className="font-12px">Processing Fees</label>
                        <input type="number" className="form-control" onChange={(e) => emiAmountChange(e, 'processingFees')} value={PaidData.processingFees} />
                    </div>
                </div>
                <div className="row form-group">
                    <div className="col-xs-12 col-sm-6">
                        <label className="font-12px">Cheque Bounce Amount</label>
                        <input type="number" className="form-control" onChange={(e) => emiAmountChange(e, 'chequeBounceAmount')} value={PaidData.chequeBounceAmount} />
                    </div>
                    <div className="col-xs-12 col-sm-6">
                        <label className="font-12px">Nach Bounce Amount</label>
                        <input type="number" className="form-control" onChange={(e) => emiAmountChange(e, 'nachBounceAmount')} value={PaidData.nachBounceAmount} />
                    </div>
                </div>
                <div className="row form-group">
                    <div className="col-xs-12 col-sm-6">
                        <label className="font-12px">penalty Amount</label>
                        <input type="number" className="form-control" onChange={(e) => emiAmountChange(e, 'penaltyAmount')} value={PaidData.penaltyAmount} />
                    </div>
                </div>
                <div className="row from-group text-center">
                    <div className="col-xs-12">
                        <button className="profile-save-btn" onClick={e => emiAmountHandler(e,PaidData)}>SAVE</button>
                    </div>
                </div>
            </div>
        </div>
    )

export default ApproveDataPopup;