import React, { useState } from 'react'
import { newActivityLogsApi } from '../AdminActionCreator.component';
// import clock from '../../images/clock.png'
// import colorclock from '../../images/colorclock.png'
class ActivityLogs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activityData: ''
        }
    }
    getData() {
        const { allDetail } = this.props
        newActivityLogsApi(allDetail.loanId, callback => {
            console.log(callback)
            this.setState({ activityData: callback })
        })
    }
    componentDidMount() {
        this.getData()
    }
    render() {
        return (
            <>
                <div className="activity-logs container-fluid animated fadeIn ">
                    <div className='row'>

                        <div className='col-sm-2 col-xs-12'>
                            {this.state.activityData !== '' && this.state.activityData !== null && this.state.activityData !== undefined && this.state.activityData.listByStatus !== undefined ? this.state.activityData.listByStatus.map((data, i) => {
                                const isLastEntry = i === this.state.activityData.listByStatus.length - 1;

                                return (
                                    <>
                                        <div className='activity-circle-activetick'>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                                        </div>
                                        <h6 className='h6stepper'>{data.status}</h6>
                                        <h6 className='clocktextstepper'><span className='fa fa-clock-o'></span>&nbsp;&nbsp;{data.time}</h6>

                                        {isLastEntry ? (
                                            <>
                                                <div className='stepperlineinActive'></div>
                                                <div className='activity-circle-inactive'></div>
                                            </>
                                        ) : (

                                            <div className='stepperlineActive'></div>
                                        )}
                                    </>
                                );
                            }) : ""}


                        </div>
                        <div className='col-sm-10 col-xs-12' >

                            {this.state.activityData !== '' && this.state.activityData !== null && this.state.activityData !== undefined && this.state.activityData.listByStatus !== undefined && this.state.activityData.listByStatus ? this.state.activityData.listByStatus.map((data, i) => {
                                return (
                                    <>
                                        <h4 style={{marginTop:i===0?'40px':''}}><b>{data.status}</b></h4>
                                        <div className="tableactivity" style={{height:'265px',overflow:'auto'}}>
                                            <div className="Activites-table table-other" style={{ overflow: 'auto' }}>
                                                <table className="table table-hover align-middle text-center">
                                                    <thead>
                                                        <tr className="align-middle">
                                                            <th scope="col" colSpan={1}>Activity</th>
                                                            <th scope="col" colSpan={1}>Performed By</th>
                                                            <th scope="col" colSpan={1}>Date/Time</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data.activity.map((j, i) => {
                                                            // console.l
                                                            return (
                                                                <tr>
                                                                    <td>{j.comment}</td>
                                                                    <td>{j.empId}</td>
                                                                    <td>{j.date}</td>
                                                                </tr>
                                                            )


                                                        })}
                                                        {/* <tr>
                                                            <td>{data.comment}</td>
                                                            <td>{data.empId}</td>
                                                            <td>{data.date}</td>
                                                        </tr> */}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {/* <h6 className='tabletext2'><span className='fa fa-clock-o'></span>&nbsp;&nbsp;{data.diffHours}:{data.diffMinutes}</h6> */}
                                    </>
                                )
                            }) : ""}
                        </div>
                    </div>
                </div>
            </>
        )

    }
}
export default ActivityLogs


