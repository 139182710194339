import React from 'react'
import VerifyImage from '../../../images/verified.png'
import NotVerified from '../../../images/cross.png'
const SavePopup = ({ closePopup, popupStatus, karzaData,userDetail }) => (
    <div className='background-blur income'>
        <div className='popup-main small-popup container-fluid dbl-border'>
        <button className='close' onClick={e => closePopup()}>
            &times;
          </button>
        <div className='col-xs-12 col-sm-12' style={{marginBottom:'20px'}}>
            <table className='income-table'>
              <thead>
                <tr>
                <th>Name As On PAN</th>
                  <td>{karzaData.namePanAuthentication}</td>
                  </tr>
                  <tr>
                <th>PAN Number</th>
                  <td>{userDetail.panNumber}</td>
                  </tr>
                <tr>
                  
                  <th>Status:</th>
                  <td>{karzaData.status}</td>
                </tr>
                <tr>
        
                  <th>Duplicate:</th>
                  <td>{karzaData && karzaData.duplicate === true ? <img src={VerifyImage} width="22px" /> : <img src={NotVerified} width="22px" />}</td>
                </tr>
                <tr>
                  <th>DOB Matched:</th>
                  <td>{karzaData && karzaData.dobMatch === true ? <img src={VerifyImage} width="22px" /> : <img src={NotVerified} width="22px" />}</td>
                </tr>
                <tr>
                  <th>AADHAR Linked:</th>
                  <td>{karzaData && karzaData.aadhaarLinked === true ? <img src={VerifyImage} width="22px" /> : <img src={NotVerified} width="22px" />}</td>
                </tr>
                <tr>

                  <th>Name Matched:</th>
                  <td>{karzaData && karzaData.nameMatch === true ? <img src={VerifyImage} width="22px" /> : <img src={NotVerified} width="22px" />}</td>
                </tr>
               
              </thead>
            </table>

          </div>
          </div>
     

    </div>
)

export default SavePopup;