import React from 'react'
import { MAIL_STRING } from '../../../utils/Constant'

// subject: Phocket: NACH Bounced

const LastStep = ({ allDetail }) => (
    <div>
        <div>Hello {allDetail != null && allDetail.firstName != null ? allDetail.firstName : ""},</div>
        <br />
        <div>Kindly check the notification bell in the app for further process, first you need to update your 3 reference numbers there, after adding ref you will get two links accordingly (E-Nach & E-sign), Which you have to verify to get disbursement. If you are facing any issue with links, Kindly let us know.</div>
        <br />
        <div>NOTE- We will call on your references for verification as in KYC verification (for verifying your current company name or current address). Kindly provide the valid contacts to avoid delay in your amount disbursement.</div>
        <br />
        <div>Best Regards,</div>
        <div>Team Phocket</div>
        <div>{MAIL_STRING.MOBILE_NO}</div>
    </div>
)

export default LastStep