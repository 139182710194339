import React, { Component } from 'react'
import BulkCommStatus from '../../../presentationals/Admin/BulkActions/CommunicateByStatus.component'
import {
    statusWiseCommunication, notificationImageUploadApi,
    communicationCountByFilterApi
} from '../AdminActionCreator.component'
import Popup from '../../../presentationals/Popup/Popup.component';
import { STRING } from '../../../../utils/Constant'
import ConfirmationPopup from '../../../presentationals/Popup/ConfirmationPopup.component'
import { _formatDateInDash, _formatDateInYMD } from '../../../../utils/validation'
import { APIS } from '../../../../utils/api-factory'
import { TemplateId } from '../../../presentationals/Admin/BulkActions/MessageTemplates.component'

let File = '';

class CommunicationByStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            statusCount: '0',
            listOfUsersToCommunicate: [],
            popupState: false,
            popupStatus: '',
            msgBoxState: false,
            mailBoxSate: false,
            url: '',
            confirmationPopup: false,
            confirmationText: '',
            notificationImage: '',
            btnDisabled: false,
            allStates: [],
            CommunicateByStatus: {
                title: '',
                body: '',
                type: [],
            },
            filterForCommunicationDetail: {
                status: '',
                applyDateFromToShow: '',
                applyDateToToShow: '',
                HowToKnowState: '',
                applyDateFrom: _formatDateInYMD(new Date()),
                applyDateTo: _formatDateInYMD(new Date()),
                stateFilter: '',
                salaryFrom: '',
                salaryTo: '',
                prefferedCompanies: false,
                
                templateIdTosend: ''
            },
            typeOfFile: '',
        }
    }

    componentWillMount() {
        fetch(APIS.GET_ALL_STATE)
            .then(res => res.json())
            .then(res => {
                this.setState({
                    allStates: res
                })
            })
    }
    mailTempleteClick(e, type) {
        let CommunicateByStatus = Object.assign({}, this.state.CommunicateByStatus)
        if (type == 'REVIEW') {
            CommunicateByStatus.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.REVIEW_ON_PLAYSTORE })
        }
        if (type == 'MONTHEND') {
            CommunicateByStatus.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.MONTH_END_SAL_ADVANCE })
        }
        if (type == 'BILLPAY') {
            CommunicateByStatus.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.BILL_PAY_SAL_ADVANCE })
        }
        if (type == 'TRYPHOCKET') {
            CommunicateByStatus.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.PHOCKET_APP_TRY_KARO })
        }
        if (type == 'JHATPATLOAN') {
            CommunicateByStatus.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.JHATPAT_LOAN })
        }
        if (type == 'ADVANCESALARY') {
            CommunicateByStatus.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.ELIGIBLE_FOR_ADVANCE_SAL_2_LAC })
        }
        if (type == 'FESTIVESHOPING') {
            CommunicateByStatus.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.FESTIVE_SHOPPING_LOAN })
        }
        if (type == 'FEWMINUTELOAN') {
            CommunicateByStatus.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.LOAN_WITHIN_FEW_MINUTES })
        }
        if (type == 'LASTCHANCELOAN') {
            CommunicateByStatus.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.LAST_CHANCE_LOAN })
        }
        if (type == 'UPTO100000') {
            CommunicateByStatus.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.UPTO_200000_LOAN })
        }
        if (type == 'LOANWAITING') {
            CommunicateByStatus.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.LOAN_WAITING })
        }
        if (type == 'LOWINTERST') {
            CommunicateByStatus.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.LOAN_AT_LOW_INTEREST })
        }
        if (type == 'SPEEDOFROCKET') {
            CommunicateByStatus.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.MONEY_AT_SPEED_OF_ROCKET })
        }
        if (type == 'BANKBAZAR') {
            CommunicateByStatus.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.ADVANCE_SALARY_VIA_BANKBAZAAR })
        }
        if (type == 'URGENTCASH') {
            CommunicateByStatus.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.URGENT_CASH })
        }
        if (type == 'FIVEMINUTELOAN') {
            CommunicateByStatus.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.LOAN_WITHIN_5_MINUTES })
        } if (type == 'BANKBAZAAR') {
            CommunicateByStatus.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.BANKBAZAAR })
        } if (type == 'SALARY_12_MONTH') {
            CommunicateByStatus.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.SALARY_12_MONTH })
        }
        if (type == 'GET_A_PHOCKET_PL') {
            CommunicateByStatus.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.GET_A_PHOCKET_PL })
        }
        if (type == 'ADVANCE_SAL_BY_BUDDYLOAN') {
            CommunicateByStatus.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.ADVANCE_SAL_BY_BUDDYLOAN })
        }
        if (type == 'PRE_QUALIFIED_BUDDY_LOAN_PHOCKET') {
            CommunicateByStatus.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.PRE_QUALIFIED_BUDDY_LOAN_PHOCKET })
        }
        if (type == 'PHOCKET_BANKBAZAAR') {
            CommunicateByStatus.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.PHOCKET_BANKBAZAAR })
        }
        if (type == 'LOAN_FOR_15000_SALARY') {
            CommunicateByStatus.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.LOAN_FOR_15000_SALARY })
        }
        if (type == 'Phocket_App_partnered_with_Anjraj_Talent') {
            CommunicateByStatus.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.Phocket_App_partnered_with_Anjraj_Talent })
        }
        if (type == 'Dear_Customer_Refer_your_friends') {
            CommunicateByStatus.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.Dear_Customer_Refer_your_friends })
        }
        if (type == 'Phocket_App_partnered_with_Genesis') {
            CommunicateByStatus.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.Phocket_App_partnered_with_Genesis })
        }
        if (type == 'Phocket_App_partnered_with_Hyrexpert') {
            CommunicateByStatus.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.Phocket_App_partnered_with_Hyrexpert })
        }
        if (type == 'Phocket_App_partnered_with_Karyarth') {
            CommunicateByStatus.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.Phocket_App_partnered_with_Karyarth })
        }
        if (type == 'Phocket_App_partnered_with_SAM_Career') {
            CommunicateByStatus.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.Phocket_App_partnered_with_SAM_Career })
        }
        if (type == 'PHONEPARLOAN') {
            CommunicateByStatus.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.PHONEPARLOAN })
        }
        if (type == 'REPEAT_CAMPAIGN') {
            CommunicateByStatus.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.REPEAT_CAMPAIGN })
        }
        if (type == 'TWICE_APPROVED_AMOUNT') {
            CommunicateByStatus.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.TWICE_APPROVED_AMOUNT })
        }
        if (type == 'MAXIMIZE_APPROVED_AMOUNT') {
            CommunicateByStatus.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.MAXIMIZE_APPROVED_AMOUNT })
        }
        this.setState({ CommunicateByStatus })
    }


    render() {
        const { allStatus } = this.props
        return (
            <div className="">
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <BulkCommStatus
                    mailTempleteClick={this.mailTempleteClick.bind(this)}

                    allStatus={allStatus}
                    statusCommunicate={this.statusCommunicate.bind(this)}
                    statusCount={this.state.statusCount}
                    communicateByStatusHandler={this.communicateByStatusHandler.bind(this)}
                    communicateTypeHandler={this.communicateTypeHandler.bind(this)}
                    msgBoxState={this.state.msgBoxState}
                    mailBoxSate={this.state.mailBoxSate}
                    CommunicateByStatus={this.state.CommunicateByStatus}
                    createLink={this.createLink.bind(this)}
                    uploadNotificationImage={this.uploadNotificationImage.bind(this)}
                    notificationImage={this.state.notificationImage}
                    btnDisabled={this.state.btnDisabled}
                    filterForCommunication={this.filterForCommunication.bind(this)}
                    filterForCommunicationDetail={this.state.filterForCommunicationDetail}
                    getFilterCount={this.getFilterCount.bind(this)}
                    allStates={this.state.allStates}
                />
                {this.state.confirmationPopup == true ?
                    <ConfirmationPopup
                        cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
                        confirmationText={this.state.confirmationText} />
                    : ""}
            </div>
        )
    }

    uploadNotificationImage(e) {
        this.setState({ typeOfFile: e.target.files[0].type })

        let val = e.target.value.split(/[\\\/]/)[2];
        this.setState({ notificationImage: val })
        File = e.target.files[0];
        let docType = val.split(".");
        let fileType = docType[docType.length - 1];
        if (fileType == "jpeg" || fileType == "jpg" || fileType == "png") {
            notificationImageUploadApi(File, val, this.state.typeOfFile,
                (callBack) => {
                    this.setState({
                        notificationImage: callBack
                    })
                });
        }
    }

    cancelLoanConfirm(conType) {
        const { admin } = this.props
        if (conType == 'yes') {
            if (this.state.mailBoxSate == true) {
                statusWiseCommunication(
                    this.state.CommunicateByStatus,
                    document.getElementById("richText").innerHTML,
                    'mail',
                    this.state.notificationImage,
                    admin,
                    this.state.filterForCommunicationDetail,
                    this.state.listOfUsersToCommunicate,
                    this.state.templateIdTosend,
                    (callBack) => {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Communicated Successfully',
                            statusCount: "0",
                            notificationImage: '',
                            CommunicateByStatus: {
                                status: '',
                                title: '',
                                body: '',
                                type: []
                            },
                            btnDisabled: false
                        })
                        this.removePopup();
                        if (document.getElementById("richText") != null)
                            document.getElementById("richText").innerHTML = ''
                    })
                if (document.getElementById("byStatusSendNotification") != null) {
                    document.getElementById("byStatusSendNotification").checked = false
                }
                if (document.getElementById("byStatusSendMail") != null) {
                    document.getElementById("byStatusSendMail").checked = false
                }
            }
            if (this.state.msgBoxState == true) {
                statusWiseCommunication(
                    this.state.CommunicateByStatus,
                    '',
                    'msg',
                    this.state.notificationImage,
                    admin,
                    this.state.filterForCommunicationDetail,
                    this.state.listOfUsersToCommunicate,
                    this.state.templateIdTosend,

                    (callBack) => {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Msg Sent Successfully',
                            statusCount: "0",
                            notificationImage: '',
                            CommunicateByStatus: {
                                status: '',
                                title: '',
                                body: '',
                                type: []
                            },
                            btnDisabled: false
                        })
                        this.removePopup();
                    })
            }
            this.setState({
                msgBoxState: false,
                mailBoxSate: false,
                confirmationPopup: false
            })
        } else if (conType == 'no') {
            this.setState({ confirmationPopup: false })
        }
    }

    communicateTypeHandler(boxType) {
        let CommunicateByStatus = Object.assign({}, this.state.CommunicateByStatus);
        if (boxType == 'mailNotification') {
            CommunicateByStatus.type = []
            this.setState({
                mailBoxSate: true,
                msgBoxState: false,
            })
            document.getElementById("mailBtn").classList.add("btn-active")
            document.getElementById("msgBtn").classList.remove("btn-active")
        } else if (boxType == 'msg') {
            CommunicateByStatus.type = ["Message"]
            this.setState({
                msgBoxState: true,
                mailBoxSate: false,
            })
            document.getElementById("mailBtn").classList.remove("btn-active")
            document.getElementById("msgBtn").classList.add("btn-active")
        }
        this.setState({ CommunicateByStatus })
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    createLink() {
        var selection = document.getSelection();
        document.execCommand('createLink', true, this.state.url);
        selection.anchorNode.parentElement.target = '_blank';
    }

    filterForCommunication(e, comType) {
        let filterForCommunicationDetail = Object.assign({}, this.state.filterForCommunicationDetail);
        if (comType == 'status') {
            filterForCommunicationDetail.status = e.target.value;
        } else if (comType == 'applyDateFrom') {
            filterForCommunicationDetail.applyDateFromToShow = e;
            filterForCommunicationDetail.applyDateFrom = _formatDateInYMD(e);
        } else if (comType == 'applyDateTo') {
            filterForCommunicationDetail.applyDateToToShow = e;
            filterForCommunicationDetail.applyDateTo = _formatDateInYMD(e);
        } else if (comType == 'HowToKnowState') {
            filterForCommunicationDetail.HowToKnowState = e.target.value;
        } else if (comType == 'stateFilter') {
            filterForCommunicationDetail.stateFilter = e.target.value;
        } else if (comType == 'salaryFrom') {
            filterForCommunicationDetail.salaryFrom = e.target.value;
        } else if (comType == 'salaryTo') {
            filterForCommunicationDetail.salaryTo = e.target.value;
        } else if (comType == 'prefferedCompanies') {
            if (e.target.checked) {
                filterForCommunicationDetail.prefferedCompanies = 'yes';
            } else {
                filterForCommunicationDetail.prefferedCompanies = '';
            }
        }
        this.setState({ filterForCommunicationDetail })
    }

    getFilterCount() {
        communicationCountByFilterApi(this.state.filterForCommunicationDetail,
            callBack => {
                this.setState({
                    statusCount: callBack.length,
                    listOfUsersToCommunicate: callBack
                })
            })
    }

    statusCommunicate(e, comType) {
        let CommunicateByStatus = Object.assign({}, this.state.CommunicateByStatus);
        if (comType == 'title') {
            CommunicateByStatus.title = e.target.value;
        } else if (comType == 'body') {
            CommunicateByStatus.body = e.target.value;
        } else if (comType === "url") {
            this.setState({ url: e.target.value });
        } else if (comType == 'communicationType') {
            let comunicationType = []
            let notification = document.getElementById("byStatusSendNotification")
            let mail = document.getElementById("byStatusSendMail")
            if (notification != null) {
                if (notification.checked == true) {
                    if (comunicationType.includes(notification.value)) {

                    } else {
                        comunicationType.push(notification.value)
                    }
                } else {
                    let notificationIndex = comunicationType.indexOf(notification.value)
                    if (notificationIndex >= 0) {
                        comunicationType.splice(notificationIndex, 1);
                    }
                }
            }
            if (mail != null) {
                if (mail.checked == true) {
                    if (comunicationType.includes(mail.value)) {

                    } else {
                        comunicationType.push(mail.value)
                    }
                } else {
                    let mailIndex = comunicationType.indexOf(mail.value)
                    if (mailIndex >= 0) {
                        comunicationType.splice(mailIndex, 1);
                    }
                }
            }
            CommunicateByStatus.type = comunicationType
        }
        this.setState({ CommunicateByStatus })
    }

    communicateByStatusHandler() {

        const { admin } = this.props
        if (this.state.CommunicateByStatus.type.length <= 0) {
            this.setState({
                popupState: true,
                popupStatus: 'Please select Communication type',
            })
            this.removePopup();
        } else {
            this.setState({ btnDisabled: true })
            if (this.state.CommunicateByStatus.status == STRING.SELECT_ALL) {
                this.setState({
                    confirmationPopup: true,
                    confirmationText: 'Are you sure you want to sent to All. (This contains applicants with Reject, Permanent Reject, Expired, OverDue, etc status)'
                })
                document.getElementById("mailBtn").classList.remove("btn-active")
                document.getElementById("msgBtn").classList.remove("btn-active")
            } else {
                if (this.state.mailBoxSate == true) {
                    statusWiseCommunication(
                        this.state.CommunicateByStatus,
                        document.getElementById("richText").innerHTML,
                        'mail',
                        this.state.notificationImage,
                        admin,
                        this.state.filterForCommunicationDetail,
                        this.state.listOfUsersToCommunicate,
                        this.state.templateIdTosend,

                        (callBack) => {
                            this.setState({
                                popupState: true,
                                popupStatus: 'Communicated Successfully',
                                statusCount: "0",
                                notificationImage: '',
                                CommunicateByStatus: {
                                    status: '',
                                    title: '',
                                    body: '',
                                    type: []
                                },
                                btnDisabled: false
                            })
                            this.removePopup();
                            if (document.getElementById("richText") != null)
                                document.getElementById("richText").innerHTML = ''
                        })
                    if (document.getElementById("byStatusSendNotification") != null) {
                        document.getElementById("byStatusSendNotification").checked = false
                    }
                    if (document.getElementById("byStatusSendMail") != null) {
                        document.getElementById("byStatusSendMail").checked = false
                    }
                    document.getElementById("mailBtn").classList.remove("btn-active")
                    document.getElementById("msgBtn").classList.remove("btn-active")
                }
                if (this.state.msgBoxState == true) {
                    statusWiseCommunication(
                        this.state.CommunicateByStatus,
                        '',
                        'msg',
                        this.state.notificationImage,
                        admin,
                        this.state.filterForCommunicationDetail,
                        this.state.listOfUsersToCommunicate,
                        this.state.templateIdTosend,

                        (callBack) => {
                            this.setState({
                                popupState: true,
                                popupStatus: 'Msg Sent Successfully',
                                statusCount: "0",
                                notificationImage: '',
                                CommunicateByStatus: {
                                    status: '',
                                    title: '',
                                    body: '',
                                    type: []
                                },
                                btnDisabled: false
                            })
                            this.removePopup();
                            document.getElementById("mailBtn").classList.remove("btn-active")
                            document.getElementById("msgBtn").classList.remove("btn-active")
                        })
                }
                this.setState({
                    msgBoxState: false,
                    mailBoxSate: false,
                })
            }
        }
    }
}

export default CommunicationByStatus;