import React, { useState } from "react";
import './credit.scss';
import CreditDashboard from "./dashbaordboxchanges";
///KYCCHANGES///
import PolicyDetails from "../Analysis/policyDetailsNew";
import WorkingHistory from "../Analysis/workingHistory";
import { APIS } from "../../../../utils/api-factory";
import { useEffect } from "react";
import { ROLE } from "../../../../utils/Constant";
import Repeat from "./Analysis/repeatAutomation";
import CategorHistory from "../Analysis/savedCategoryuser";
import { getSourceOfStatementParse } from "../AdminActionCreator.component";
const MainTab = ({ allDetail, admin }) => {
    const [viewPage, setViewPage] = useState('dashboard');
    const [internalViewState, setinternalViewState] = useState(false);
    const [repeatUserData, setRepeatUserData] = useState(false)
    const [statementSource, setgetStatementSource] = useState('')


    const pageHandler = (data) => {
        setViewPage(data);
    };
    const checkRepeatUser = () => {
        fetch(APIS.REPEAT_USER_CHECK + allDetail.userId)
            .then(res => res.json())
            .then(json => {
                setRepeatUserData(json.repeatUser)
            })
    }
    useEffect(() => {
        checkRepeatUser()
        getSource()
    }, [])
    const getSource = () => {
        getSourceOfStatementParse(allDetail.userId, callback => {
            console.log(callback)
            setgetStatementSource(callback)
        })
    }
    return (
        <div className="container-fluid credit-module  full-height">
            <div className="row fixed-top" style={{ marginTop: '80px', zIndex: '1', background: '#fff', borderTop: '0.5px solid grey' }}>
                <div className="btn-group btn-grp-credit" role="group" aria-label="onboard-btn" style={{ fontSize: '14px', maxWidth: 'max-content' }}>
                    <button className="btn btn-outline-primary" style={{ width: '200px', background: viewPage === 'dashboard' ? '#DBE9FF' : '#fff', color: viewPage === 'dashboard' ? '#267DFF' : '#00000073', borderBottom: viewPage === 'dashboard' ? '2px solid #267DFF' : '2px solid #DBE9FF' }} onClick={() => pageHandler('dashboard')}> Dashboard</button>
                    <button className="btn btn-outline-primary" style={{ width: '200px', background: viewPage === 'history' ? '#DBE9FF' : '#fff', color: viewPage === 'history' ? '#267DFF' : '#00000073', borderBottom: viewPage === 'history' ? '2px solid #267DFF' : '2px solid #DBE9FF' }} onClick={() => pageHandler('history')}> Working History</button>
                    <button className="btn btn-outline-primary" style={{ width: '200px', background: viewPage === 'scorehistory' ? '#DBE9FF' : '#fff', color: viewPage === 'scorehistory' ? '#267DFF' : '#00000073', borderBottom: viewPage === 'scorehistory' ? '2px solid #267DFF' : '2px solid #DBE9FF' }} onClick={() => pageHandler('scorehistory')}> Score Analysis</button>

                    <button className="btn btn-outline-primary" style={{ width: '200px', background: viewPage === 'policy' ? '#DBE9FF' : '#fff', color: viewPage === 'policy' ? '#267DFF' : '#00000073', borderBottom: viewPage === 'policy' ? '2px solid #267DFF' : '2px solid #DBE9FF' }} onClick={() => pageHandler('policy')}> Policy Check</button>

                    {repeatUserData === true && admin != null && admin.rolelist.indexOf(ROLE.CRIF_DATA) >= 0 ?

                        <button className="btn btn-outline-primary" style={{ width: '200px', background: viewPage === 'repeatAutomation' ? '#DBE9FF' : '#fff', color: viewPage === 'repeatAutomation' ? '#267DFF' : '#00000073', borderBottom: viewPage === 'repeatAutomation' ? '2px solid #267DFF' : '2px solid #DBE9FF' }} onClick={() => pageHandler('repeatAutomation')}> Repeat Automation Decision</button>
                        : ""}
                </div>
                {/* <button className='btn btn-primary' style={{ marginTop: '10px', float: 'right',marginRight:'100px' }} >Save Selected Points</button> */}
            </div>
            <div style={{ marginTop: '120px' }}>
                {viewPage === 'dashboard' && statementSource !== '' ?
                    <CreditDashboard statementSource={statementSource} setinternalViewState={setinternalViewState} internalViewState={internalViewState} admin={admin} allDetail={allDetail} />
                    : ""}
            </div>
            <div style={{ marginTop: '170px' }}>

                {viewPage === 'policy' ?
                    <PolicyDetails allDetail={allDetail} />
                    : ""}
            </div>
            <div style={{ marginTop: '120px' }}>

                {viewPage === 'history' ?
                    <WorkingHistory allDetail={allDetail} />
                    : ""}
            </div>
            <div className="">
                {viewPage === 'scorehistory' ?
                    <CategorHistory admin={admin} allDetail={allDetail} />
                    : ""}
            </div>
            <div style={{ marginTop: '120px' }}>

                {viewPage === 'repeatAutomation' ?
                    <Repeat allDetail={allDetail} />
                    : ""}
            </div>
        </div>
    );
};

export default MainTab;
